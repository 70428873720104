import { Node } from 'reactflow';
import { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { emptyNode } from 'components/Utils/emptyModelGraph';
import { abbrIsValid, createAbbrFromName } from 'components/Utils/Abbreviations';
import { Workflow } from 'components/Utils/WorkflowContext';
import { modelManager } from 'components/ModelTabs/ModelManager';
import { Node as CausalNode } from 'components/openapi';
import { useTranslation } from 'react-i18next';

const CLOSE_MODAL_ADD_ID = 'CLOSE_MODAL_ADD';
const CLOSE_MODAL_EDIT_ID = 'CLOSE_MODAL_EDIT';

let onAddNodeClicked: (node: Node) => void;
export const setOnAddNodeClicked = (onAddNodeClickedInput: (node: Node) => void) => {
  onAddNodeClicked = onAddNodeClickedInput;
};

let onEditNodeClicked: (node: Node) => void;
export const setOnEditNodeClicked = (onEditNodeClickedInput: (node: Node) => void) => {
  onEditNodeClicked = onEditNodeClickedInput;
};

const nodeHasNoParents = (node: Node | undefined, workflow: Workflow): boolean => {
  if (node === undefined || node.data.kind === 'exogene') return true;
  const result =
    Array.from(modelManager.getActiveGraph(workflow.getPage())?.state().links ?? []).findIndex(
      (link) => link.to === Number(node.id),
    ) === -1;
  return result;
};

let selectedNode: Node | undefined;
export const setSelectedNode = (node: Node) => {
  selectedNode = node;
};

type RadioProps = {
  onClick: () => void;
  enabled: boolean;
  label: string;
  disabled?: boolean;
};
export const Radio = ({ onClick, enabled, label, disabled }: RadioProps) => {
  return (
    <button
      className={`addNodeDialog__radio-wrapper addNodeDialog__label${disabled ? ' text-muted' : ''}`}
      type='button'
      onClick={onClick}
      disabled={disabled}
    >
      <span className={`addNodeDialog__custom-radio${enabled ? ' enabled' : ''}`} />
      {label}
    </button>
  );
};

type AddNodeDialogProps = {
  id: string;
  isEditDialog: boolean;
  workflow: Workflow;
};

export const AddNodeDialog = ({ id, isEditDialog, workflow }: AddNodeDialogProps) => {
  const { t } = useTranslation();

  const emptyFormData = {
    name: '',
    abbr: '',
    kind: 'endogene',
    type: 'withData',
    description: '',
    variableType: 'continuous',
    rangeOfValuesFrom: '',
    rangeOfValuesTo: '',
    unit: '',
  };
  const [formData, setFormData] = useState(emptyFormData);
  const [errorMessages, setErrorMessages] = useState<{ name?: string; abbr?: string; range?: string }>({});
  const [nodeCanBeExogene, setNodeCanBeExogene] = useState(true);

  const clearForm = () => setFormData(emptyFormData);

  const onShow = () => {
    setErrorMessages({});
    if (selectedNode && isEditDialog) {
      setNodeCanBeExogene(nodeHasNoParents(selectedNode, workflow));
      setFormData({
        name: selectedNode.data.name,
        abbr: selectedNode.data.abbr ?? '',
        kind: selectedNode.data.kind,
        type: selectedNode.data.data ? 'withData' : 'withoutData',
        description: selectedNode.data.description ?? '',
        variableType: selectedNode.data.value_type ?? 'continuous',
        rangeOfValuesFrom: selectedNode.data.value_range?.min ?? '',
        rangeOfValuesTo: selectedNode.data.value_range?.max ?? '',
        unit: selectedNode.data.unit ?? '',
      });
    } else {
      clearForm();
    }
  };

  useEffect(() => {
    const addNodeDialog = document.getElementById(id);
    addNodeDialog?.addEventListener('show.bs.modal', onShow);
    return () => {
      addNodeDialog?.removeEventListener('show.bs.modal', onShow);
    };
  }, []);

  const handleChange = (event: FormEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const createNodeFromForm = () => {
    const newModelData = { ...emptyNode };

    if (formData.rangeOfValuesFrom && formData.rangeOfValuesTo) {
      newModelData['value_range'] = {
        min: Number(formData.rangeOfValuesFrom),
        max: Number(formData.rangeOfValuesTo),
      };
    }
    // No abbr for exogenes
    const abbr = formData.kind === 'endogene' ? formData.abbr : '';
    const name = formData.name.trim();

    const newNode: Node = {
      id: '',
      className: formData.type === 'withData' ? '' : 'nodata',
      type: 'MultiPortNode',
      data: {
        ...newModelData,
        name,
        abbr,
        label: name + (abbr !== '' ? ` (${abbr})` : ''),
        kind: formData.kind,
        data: formData.type === 'withData',
        unit: formData.unit,
        isHiddenConfounder: false,
        isParentOfSelected: false,
        value_type: formData.variableType,
        description: formData.description,
      },
      position: { x: 0, y: 0 },
      width: 0,
      height: 0,
      selectable: false,
      hidden: false,
    };
    return newNode;
  };

  const updateNodeFromForm = () => {
    const editNode: Node | undefined = selectedNode;
    if (editNode) {
      const name = formData.name.trim();
      const abbr = formData.kind === 'endogene' ? formData.abbr : '';
      editNode.className = formData.type === 'withData' ? '' : 'nodata';
      editNode.data.name = name;
      editNode.data.abbr = abbr;
      editNode.data.label = name + (abbr !== '' ? ` (${abbr})` : '');
      editNode.data.kind = formData.kind;
      editNode.data.description = formData.description;
      editNode.data.data = formData.type === 'withData';
      editNode.data.value_type = formData.variableType;
      editNode.data.unit = formData.unit;

      if (formData.rangeOfValuesFrom !== '' || formData.rangeOfValuesTo !== '') {
        editNode.data['value_range'] = {
          min: Number(formData.rangeOfValuesFrom),
          max: Number(formData.rangeOfValuesTo),
        };
      } else {
        editNode.data['value_range'] = undefined;
      }
      return editNode;
    }
    return createNodeFromForm();
  };

  const validateInput = (): boolean => {
    setErrorMessages({});

    const selectedNodeId = isEditDialog ? selectedNode?.id : -1;
    const currentGraphNodes = Array.from(modelManager.getActiveGraph(workflow.getPage())?.state().nodes ?? []).filter(
      (node) => node.id !== Number(selectedNodeId),
    );

    const usedNodeNames = currentGraphNodes.map((node: CausalNode) => node.name.toLowerCase());
    const usedPythonSymbols = currentGraphNodes.map((node: CausalNode) => {
      if (node.abbr !== undefined && node.abbr !== '') return node.abbr.toLowerCase();
      return node.name.toLowerCase();
    });

    let inputOk = true;
    let abbrErrorMessage = '';

    let abbrErrorField = 'abbr';
    let usedAbbr = formData.abbr;

    if (usedAbbr === '' || formData.kind === 'exogene') {
      usedAbbr = formData.name;
      abbrErrorField = 'name';
    }
    ({ valid: inputOk, error: abbrErrorMessage } = abbrIsValid(usedAbbr));
    if (abbrErrorMessage !== '') {
      abbrErrorMessage = t(abbrErrorMessage);
    }

    if (usedNodeNames.includes(formData.name.toLowerCase())) {
      // Name uniqueness
      inputOk = false;
      setErrorMessages((oldErrors) => ({ ...oldErrors, name: t('addNode.errorNameNotUnique') }));
    }
    if (usedPythonSymbols.includes(usedAbbr.toLowerCase())) {
      // Symbol uniqueness (abbr / name)
      inputOk = false;
      abbrErrorMessage = t('addNode.errorAbbrNotUnique');
    }

    if (formData.rangeOfValuesFrom !== '' || formData.rangeOfValuesTo !== '') {
      // Range has valid numbers (including (-)Infinity)
      if (Number.isNaN(Number(formData.rangeOfValuesFrom)) || Number.isNaN(Number(formData.rangeOfValuesTo))) {
        inputOk = false;
        setErrorMessages((oldErrors) => ({ ...oldErrors, range: t('addNode.errorInvalidRange') }));
      }
    }

    if (!inputOk && abbrErrorMessage !== '') {
      if (abbrErrorField === 'name') abbrErrorMessage = t('addNode.errorUsingNameAsAbbr') + abbrErrorMessage;
      setErrorMessages((oldErrors) => {
        const newErrors = { ...oldErrors };
        newErrors[abbrErrorField] = abbrErrorMessage;
        return newErrors;
      });
    }

    if (inputOk) {
      setErrorMessages({});
    }
    return inputOk;
  };

  const handleSubmit = (): void => {
    if (!validateInput()) return;

    if (isEditDialog) {
      onEditNodeClicked(updateNodeFromForm());
      document.getElementById(CLOSE_MODAL_EDIT_ID)?.click();
    } else {
      onAddNodeClicked(createNodeFromForm());
      document.getElementById(CLOSE_MODAL_ADD_ID)?.click();
    }
  };

  return (
    <div
      className='modal fade'
      data-bs-backdrop='static'
      data-bs-keyboard='false'
      id={id}
      aria-labelledby='addNodeModalLabel'
      aria-hidden='true'
    >
      <div className='modal-dialog'>
        <div className='modal-content'>
          <div className='addNodeDialog__modal-header'>
            <h5 className='addNodeDialog__modal-title'>
              {isEditDialog ? t('addNode.editNodeTitle') : t('addNode.title')}
            </h5>
            <button
              type='button'
              className='addNodeDialog__btn-close'
              data-bs-dismiss='modal'
              aria-label='Close'
              onClick={clearForm}
            />
          </div>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              handleSubmit();
            }}
          >
            <div className='modal-body'>
              <div className='addNodeDialog__attribute-header'>{t('nodeInfo.name')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <input
                  className='addNodeDialog__edit-text'
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={(event) => {
                    handleChange(event);
                    setFormData((oldData) => {
                      return { ...oldData, abbr: createAbbrFromName(event.target.value) };
                    });
                  }}
                />
              </div>
              <p className={`add-node-dialog-error-message ${errorMessages.name ?? '' ? 'd-block' : 'd-none'}`}>
                {errorMessages.name}
              </p>
              {formData.kind === 'endogene' && (
                <>
                  <div className='addNodeDialog__attribute-header'>{t('nodeInfo.abbr')}</div>
                  <div className='addNodeDialog__attribute-wrapper'>
                    <input
                      className='addNodeDialog__edit-text'
                      type='text'
                      name='abbr'
                      value={formData.abbr ?? ''}
                      onChange={(event) =>
                        setFormData((oldData) => {
                          return { ...oldData, abbr: createAbbrFromName(event.target.value) };
                        })
                      }
                    />
                  </div>
                  <p className={`add-node-dialog-error-message ${errorMessages.abbr ?? '' ? 'd-block' : 'd-none'}`}>
                    {errorMessages.abbr}
                  </p>
                </>
              )}
              <div className='addNodeDialog__attribute-header'>{t('nodeInfo.type')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <Radio
                  onClick={() => {
                    setFormData({
                      ...formData,
                      kind: 'endogene',
                      type: 'withData',
                    });
                  }}
                  enabled={formData.kind === 'endogene'}
                  label={t('nodeInfo.endogenous')}
                />
                <Radio
                  onClick={() => {
                    setFormData({
                      ...formData,
                      kind: 'exogene',
                      type: 'withoutData',
                    });
                  }}
                  enabled={formData.kind === 'exogene'}
                  label={t('nodeInfo.exogenous')}
                  disabled={isEditDialog && selectedNode && !nodeCanBeExogene}
                />
              </div>
              <div className='addNodeDialog__attribute-header'>{t('addNode.description')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <textarea
                  className='addNodeDialog__edit-text'
                  name='description'
                  value={formData.description}
                  onChange={handleChange}
                />
              </div>
              <div className='addNodeDialog__attribute-header'>{t('nodeInfo.typOfVariable')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <Radio
                  onClick={() => {
                    setFormData({
                      ...formData,
                      variableType: 'continuous',
                    });
                  }}
                  enabled={formData.variableType === 'continuous'}
                  label={t('nodeInfo.continousVariable')}
                />
                <Radio
                  onClick={() => {
                    setFormData({
                      ...formData,
                      variableType: 'discrete',
                    });
                  }}
                  enabled={formData.variableType === 'discrete'}
                  label={t('nodeInfo.discreteVariable')}
                />
              </div>
              <div className='addNodeDialog__attribute-header'>{t('nodeInfo.rangeOfValues')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <div className='addNodeDialog__label'>{t('addNode.from')}</div>
                <input
                  className='addNodeDialog__edit-text'
                  type='text'
                  name='rangeOfValuesFrom'
                  value={formData.rangeOfValuesFrom}
                  onChange={handleChange}
                />
                <div className='addNodeDialog__label'>{t('addNode.to')}</div>
                <input
                  className='addNodeDialog__edit-text'
                  type='text'
                  name='rangeOfValuesTo'
                  value={formData.rangeOfValuesTo}
                  onChange={handleChange}
                />
              </div>
              <p className={`add-node-dialog-error-message ${errorMessages.range ?? '' ? 'd-block' : 'd-none'}`}>
                {errorMessages.range}
              </p>
              <div className='addNodeDialog__attribute-header'>{t('nodeInfo.unit')}</div>
              <div className='addNodeDialog__attribute-wrapper'>
                <input
                  className='addNodeDialog__edit-text'
                  type='text'
                  name='unit'
                  value={formData.unit}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className='addNodeDialog__modal-footer'>
              <button type='button' className='addNodeDialog__abort-button' data-bs-dismiss='modal' onClick={clearForm}>
                {t('addNode.abort')}
              </button>
              <button type='submit' className='addNodeDialog__add-button' disabled={formData.name === ''}>
                {isEditDialog ? t('addNode.editNodeAdd') : t('addNode.add')}
              </button>
            </div>
          </form>
          <input
            type='button'
            id={isEditDialog ? CLOSE_MODAL_EDIT_ID : CLOSE_MODAL_ADD_ID}
            data-bs-dismiss='modal'
            className='d-none'
          />
        </div>
      </div>
    </div>
  );
};
