import { NavLink } from 'react-router-dom';
import { ReactComponent as LogoCollapsed } from 'resources/assets/illustrations/Whyond_Bildmarke_weiss.svg';
import { ReactComponent as LogoExpanded } from 'resources/assets/illustrations/Whyond_Full_Logo_weiss.svg';

export const Logo = ({ collapsed, navLinkTo }: { collapsed: boolean; navLinkTo: string }): JSX.Element => {
  if (collapsed) {
    return (
      <NavLink to={navLinkTo} className='logoIconWrapper' type='button' aria-label='navlink'>
        <LogoCollapsed />
      </NavLink>
    );
  }
  return (
    <NavLink to={navLinkTo} className='logoIconWrapper expanded' type='button'>
      <LogoExpanded />
    </NavLink>
  );
};
