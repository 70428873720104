import { ModelWorkspace } from 'components/ModelTabs/ModelWorkspace';
import { ModelLoaderPage, openModelCreatorDialog, openModelLoaderDialog } from 'components/ModelTabs/ModelLoader';
import { openUploadModelDialog } from 'components/ModelTabs/ModelUploader';
import { TabId, TabMenuPosition, TabView } from 'components/Utils/TabView';
import { modelManager } from 'components/ModelTabs/ModelManager';
import { useRerender } from 'components/Utils/funcs';
import { DropdownItem } from 'components/Navbar/NavbarMenu';
import { useContext } from 'react';
import { WorkflowContext, Workflow } from 'components/Utils/WorkflowContext';
import { useTranslation } from 'react-i18next';

export const ModelTabs = (): JSX.Element => {
  const { t } = useTranslation();
  const rerender = useRerender();
  const { currentWorkflow, setCurrentWorkflow } = useContext(WorkflowContext);

  modelManager.setListener('ModelTabs', undefined, () => {
    rerender();
    modelManager.getActiveModel(currentWorkflow.getPage())?.setModelChangedListener('ModelTabs', rerender);
    modelManager.getActiveGraph(currentWorkflow.getPage())?.setGraphChangedListener('ModelTabs', rerender);
  });

  const dropdownItems: DropdownItem[] = [
    {
      name: t('model.openButton'),
      shortcut: '',
      startsNewSection: false,
      onClick: openModelLoaderDialog,
    },
    {
      name: t('model.uploadButton'),
      shortcut: '',
      startsNewSection: true,
      onClick: openUploadModelDialog,
    },
  ];
  if (currentWorkflow.getPage() === 'CREATION') {
    dropdownItems.push({
      name: t('model.createButton'),
      shortcut: '',
      startsNewSection: true,
      onClick: openModelCreatorDialog,
    });
  }

  const verticalTabWorkflows: Array<[TabId, Workflow[]]> = [
    [TabId.INFORMATIONS, [Workflow.GRAPH_CREATION]],
    [TabId.CORNER_CASES, [Workflow.CAUSAL_INFERENCE]],
    [TabId.DATA, [Workflow.GRAPH_CREATION]],
    [TabId.DISTRIBUTIONS, [Workflow.MECHANISM_CREATION]],
    [TabId.IDENTIFIABILITY, [Workflow.CAUSAL_INFERENCE]],
    [TabId.MECHANISMS, [Workflow.MECHANISM_CREATION]],
    [TabId.PLOTS, [Workflow.GRAPH_CREATION]],
    [TabId.QUESTIONS, [Workflow.CAUSAL_INFERENCE]],
  ];

  return (
    <div className='model-tabs--container'>
      <TabView
        tabs={modelManager.models
          .filter((model) => model.isVisibleInWorkflow(currentWorkflow.getPage()))
          .map((model) => {
            const data = model.state();
            const id = data.id ?? data.name;
            const graph = modelManager.getGraphCorrespondingToModel(model, currentWorkflow.getPage());
            return {
              title: data.name,
              id,
              closeTooltip: 'model.closeModelTooltip',
              content: <ModelWorkspace model={model} graph={graph} key={id} />,
            };
          })}
        initialActiveTabId={modelManager.getActiveModel(currentWorkflow.getPage())?.state().id}
        defaultContent={
          <div className='d-flex flex-fill h-100'>
            <ModelLoaderPage />
            <div className='px-2' style={{ width: '0.3125rem' }} />
            <TabView
              menuPosition={TabMenuPosition.RIGHT}
              initialActiveTabId=''
              tabs={verticalTabWorkflows
                .filter((entry) => entry[1].includes(currentWorkflow))
                .map((entry) => ({
                  ...entry[0],
                  muted: true,
                }))}
            />
          </div>
        }
        callbacks={{
          addTabDropdown: dropdownItems,
          removeTab: (modelId: string) => {
            const model = modelManager.getModelById(modelId);
            if (model)
              modelManager.removeGraph(
                currentWorkflow.getPage(),
                modelManager.getGraphCorrespondingToModel(model, currentWorkflow.getPage())?.state().id,
              );
            modelManager.removeModel(currentWorkflow.getPage(), modelId);
            if (currentWorkflow.getPage() === 'CREATION') {
              setCurrentWorkflow(Workflow.GRAPH_CREATION);
            }
          },
          onActiveTabChanged: (modelId: string) => {
            const model = modelManager.getModelById(modelId);
            if (model)
              modelManager.setGraphAsActive(
                currentWorkflow.getPage(),
                modelManager.getGraphCorrespondingToModel(model, currentWorkflow.getPage())?.state().id,
              );
            modelManager.setModelAsActive(currentWorkflow.getPage(), modelId);
            if (currentWorkflow.getPage() === 'CREATION') {
              setCurrentWorkflow(Workflow.GRAPH_CREATION);
            }
          },
        }}
      />
    </div>
  );
};
