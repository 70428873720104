import { Panel } from 'reactflow';
import { memo, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

type CheckBoxProps = {
  label: string;
  value: boolean;
  onChange: () => void;
  enable: boolean;
};
export const CheckBox = memo(function CheckBox({ label, value, onChange, enable }: CheckBoxProps) {
  return (
    <button className='graphView-actions-check' type='button' onClick={onChange} disabled={!enable}>
      <div className={`graphView-actions-check-input${value ? ' checked' : ''}${enable ? '' : ' disabled'}`} />
      <div className={`graphView-actions-check-label${enable ? '' : ' disabled'}`}>{label}</div>
    </button>
  );
});

export type Actions = {
  showBackgroundNodes: boolean;
  highlightReviewedAreas: boolean;
  highlightNodesWithData: boolean;
  highlightEdgesWithMechanisms: boolean;
};
type ActionsPanelProps = {
  onActionsChanged: (actions: Actions) => void;
};
export const ActionsPanel = memo(function ActionsPanel(props: ActionsPanelProps) {
  const { t } = useTranslation();

  const [actions, setActions] = useState({
    showBackgroundNodes: false,
    highlightReviewedAreas: false,
    highlightNodesWithData: false,
    highlightEdgesWithMechanisms: false,
  });

  const onCheckBoxClick = (
    name: 'showBackgroundNodes' | 'highlightReviewedAreas' | 'highlightNodesWithData' | 'highlightEdgesWithMechanisms',
  ) => {
    const newActions = actions;
    newActions[name] = !actions[name];
    if (name === 'showBackgroundNodes') {
      // newActions.showHiddenConfounders = newActions.showBackgroundNodes;
    }
    setActions(newActions);
    props.onActionsChanged(newActions);
  };

  useEffect(() => {
    const newActions = actions;
    newActions['showBackgroundNodes'] = true;
    setActions(newActions);
    props.onActionsChanged(newActions);
  }, []);

  return (
    <Panel className='graphView-actions-panel' position='bottom-left'>
      <div>
        <div className='graphView-actions-header'>{t('graphView.actionsHeader')}</div>
        <CheckBox
          label={t('graphView.actionsShowBackground')}
          value={actions.showBackgroundNodes}
          onChange={() => onCheckBoxClick('showBackgroundNodes')}
          enable
        />
        <CheckBox
          label={t('graphView.actionsHighlightReviewedAreas')}
          value={actions.highlightReviewedAreas}
          onChange={() => onCheckBoxClick('highlightReviewedAreas')}
          enable={false}
        />
        <CheckBox
          label={t('graphView.actionsHighlightNodesWithData')}
          value={actions.highlightNodesWithData}
          onChange={() => onCheckBoxClick('highlightNodesWithData')}
          enable={false}
        />
        <CheckBox
          label={t('graphView.actionsHighlightEdgesWithMechanisms')}
          value={actions.highlightEdgesWithMechanisms}
          onChange={() => onCheckBoxClick('highlightEdgesWithMechanisms')}
          enable={false}
        />
      </div>
    </Panel>
  );
});
