import { CausalGraph, CausalModel, Node } from 'components/openapi';
import { useState } from 'react';

export function collect<A, B>(array: A[], partialMap: (elem: A) => B | null): B[] {
  const mappedArray: B[] = [];
  array.forEach((elem) => {
    const mappedElem = partialMap(elem);
    if (mappedElem) {
      mappedArray.push(mappedElem);
    }
  });
  return mappedArray;
}

export function equals<T>(a: T, b: T): boolean {
  const aIsNull = a === null || a === undefined;
  const bIsNull = b === null || b === undefined;
  if (aIsNull && bIsNull) return true;
  if (aIsNull || bIsNull) return false;

  if (typeof a === 'object' && typeof b === 'object') {
    return ([...new Set([...Object.keys(a), ...Object.keys(b)])] as (keyof T)[])
      .map((key) => equals(a[key], b[key]))
      .reduce((aggregator, wasEqual) => aggregator && wasEqual, true);
  }
  return a === b;
}

export function useRerender() {
  const [, setValue] = useState(0);
  return () => setValue((state) => (state + 1) % 666);
}

export const openAlertDialog = (text: string) => {
  alert(text); // eslint-disable-line no-alert
};

export const logToConsole = (text: string) => {
  console.log(text); // eslint-disable-line no-console
};

export const getCurrentTime = (): string => {
  const date = new Date();
  date.setTime(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
  return date.toISOString();
};

export const sortISOStrings = (a: string | undefined, b: string | undefined, ascending = false) => {
  if (!b) return -1;
  if (!a) return 1;
  return ascending ? a.localeCompare(b) : -a.localeCompare(b);
};

export const formatISOTime = (iso_time: string | undefined): string => {
  if (!iso_time) return '';
  return iso_time.split('.')[0].replace('T', ' ');
};

export const updatePatchVersion = (oldVersion: string): string => {
  const patchVersion = Number(oldVersion.substring(oldVersion.lastIndexOf('.') + 1));
  return oldVersion.substring(0, oldVersion.lastIndexOf('.') + 1) + (patchVersion + 1).toString();
};

export const updateDateAndVersion = <T extends CausalGraph | CausalModel>(state: T): T => {
  const newState = state;
  newState.version = updatePatchVersion(state.version);

  const frontendInfo = state.info ?? { frontend: { last_modified: '' } };
  frontendInfo.frontend = { ...frontendInfo.frontend, last_modified: getCurrentTime() };
  newState.info = frontendInfo;
  return newState;
};

export const getNodeNameFromId = (causalgraph: CausalGraph, id: number, withUnit = false): string => {
  // If node is not found, the function returns its id
  const nodesArray = Array.from(causalgraph.nodes);
  const foundNode = nodesArray.find((node) => node.id === id);
  if (foundNode) {
    if (withUnit && foundNode.unit !== undefined && foundNode.unit.length !== 0) {
      return `${foundNode.name} (${foundNode.unit})`;
    }
    return foundNode.name;
  }
  return id.toString();
};

export const getNodeParents = (node: Node, causal_graph: CausalGraph): Node[] => {
  if (node.kind === 'exogene') return [];
  return Array.from(causal_graph.nodes).filter(
    (otherNode) =>
      Array.from(causal_graph.links).filter((link) => link.from === otherNode.id && link.to === node.id).length >= 1,
  );
};

export const getNodesElligibleForMechanism = (causalGraph: CausalGraph): Node[] => {
  return Array.from(causalGraph.nodes).filter((node) => node.kind === 'endogene');
};
export const getNodesElligibleForDistribution = (causalGraph: CausalGraph): Node[] => {
  return Array.from(causalGraph.nodes).filter((node) => node.kind === 'exogene');
};

export const formatLatex = (str: string, displayMode = false): string => {
  let stringToReturn = str;
  if (displayMode) stringToReturn = `$${str}$`;
  return `$${stringToReturn}$`;
};
