import { useTranslation } from 'react-i18next';
import { ReactComponent as SidebarUserIcon } from 'resources/assets/icons/icons8-user.svg';

export const User = ({
  sidebarCollapsed,
  navbarCollapsed,
}: {
  sidebarCollapsed: boolean;
  navbarCollapsed: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div
      className={`userWrapper ${sidebarCollapsed ? 'align-items-start' : 'align-items-center'} ${
        !sidebarCollapsed && navbarCollapsed ? 'expanded' : ''
      }`}
    >
      {!navbarCollapsed && (
        <div className='userIconWrapper'>
          <SidebarUserIcon className='icon' />
        </div>
      )}
      {!sidebarCollapsed && <p className='userText'>{t('sidebar.user')}</p>}
    </div>
  );
};
