import { Alert, Snackbar } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const SAVE_ALERT_DIALOG_ID = 'SAVE_ALERT';
let alertTitle = '';
export const openSaveAlertDialog = (exportDialog = false, openTimeoutMilliseconds = 300): void => {
  setTimeout(() => {
    if (exportDialog) {
      alertTitle = 'model.saveAlertModelExported';
    } else {
      alertTitle = 'model.saveAlertModelSaved';
    }
    document.getElementById(SAVE_ALERT_DIALOG_ID)?.click();
  }, openTimeoutMilliseconds);
};

export const SaveAlertDialog = (): JSX.Element => {
  const { t } = useTranslation();
  const [alertOpen, setAlertOpen] = useState(false);

  return (
    <>
      {/* eslint-disable-next-line */}
    <button
        type='button'
        id={SAVE_ALERT_DIALOG_ID}
        onClick={() => {
          setAlertOpen(true);
        }}
        style={{ display: 'none' }}
      />
      <Snackbar
        open={alertOpen}
        autoHideDuration={3000}
        onClose={() => setAlertOpen(false)}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={() => setAlertOpen(false)} severity='success' sx={{ width: '100%', backgroundColor: 'white' }}>
          {t(alertTitle)}
        </Alert>
      </Snackbar>
    </>
  );
};
