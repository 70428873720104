import { memo, useContext } from 'react';
import { Node, Panel } from 'reactflow';
import { ReactComponent as ZoomInIcon } from 'resources/assets/icons/layout_zoom_in.svg';
import { ReactComponent as ZoomOutIcon } from 'resources/assets/icons/layout_zoom_out.svg';
import { ReactComponent as MenuIcon } from 'resources/assets/icons/layout_menu.svg';
import { ReactComponent as FitViewIcon } from 'resources/assets/icons/layout_fit_screen.svg';
// import { ReactComponent as RestoreLayoutIcon } from 'resources/assets/icons/icons8-user.svg';
import { ReactComponent as AutoLayout1Icon } from 'resources/assets/icons/layout_auto1.svg';
import { ReactComponent as AutoLayout2Icon } from 'resources/assets/icons/layout_auto2.svg';
import { ReactComponent as RasterIcon } from 'resources/assets/icons/layout_grid.svg';
import { ReactComponent as TrashIcon } from 'resources/assets/icons/icons8-trash.svg';
import { ReactComponent as UndoIcon } from 'resources/assets/icons/icons8-undo.svg';
import { ReactComponent as RedoIcon } from 'resources/assets/icons/icons8-redo.svg';
import { ReactComponent as AddNodeIcon } from 'resources/assets/icons/add-node.svg';
import { ReactComponent as SaveIcon } from 'resources/assets/icons/icons8-save.svg';
import { ReactComponent as ExportIcon } from 'resources/assets/icons/icons8-download.svg';
import { ReactComponent as EditIcon } from 'resources/assets/icons/icons8-edit.svg';
import { setOnAddNodeClicked, setOnEditNodeClicked } from 'components/GraphView/AddNodeDialog';
import { modelManager } from 'components/ModelTabs/ModelManager';
import { WorkflowContext } from 'components/Utils/WorkflowContext';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

type ControlsPanelProps = {
  modelId?: string;
  rasterize: boolean;
  toggleRasterization: () => void;
  // restoreUserLayout: () => void;
  autoLayoutELK: () => void;
  autoLayoutDagre: () => void;
  fitView: () => void;
  deleteSelected?: () => void;
  zoomIn: () => void;
  zoomOut: () => void;
  onAddNode: (nodeToAdd: Node) => void;
  onAddNodeDialogOpened: () => void;
  onEditNode?: (nodeToEdit: Node) => void;
  undo?: () => void;
  redo?: () => void;
  onSaveModel: () => void;
  onExportModel: () => void;
};
export const ControlsPanel = memo(function ControlsPanel(props: ControlsPanelProps): JSX.Element {
  const { t } = useTranslation();

  const { currentWorkflow } = useContext(WorkflowContext);
  const updateCallbacks = () => {
    if (modelManager.getActiveModel(currentWorkflow.getPage())?.state().id === props.modelId) {
      setOnAddNodeClicked(props.onAddNode);
      if (props.onEditNode) setOnEditNodeClicked(props.onEditNode);
    }
  };

  updateCallbacks();
  modelManager.setListener(`ControlsPanel.${props.modelId}`, 'onActiveModelChanged', updateCallbacks);

  return (
    <Panel className='graphView-control-panel' position='top-right'>
      <div>
        <Tooltip title={t('controlsPanel.saveModelHover')}>
          <button
            className='graphView-panel-button'
            type='button'
            onClick={props.onSaveModel}
            aria-label={t('controlsPanel.saveModelHover')}
          >
            <SaveIcon />
          </button>
        </Tooltip>
        <Tooltip title={t('controlsPanel.exportModelHover')}>
          <button
            className='graphView-panel-button'
            type='button'
            onClick={props.onExportModel}
            aria-label={t('controlsPanel.exportModelHover')}
          >
            <ExportIcon />
          </button>
        </Tooltip>
        <span className='graphView-panel-dropdown'>
          <Tooltip title={t('controlsPanel.layoutHover')}>
            <button
              className='graphView-panel-button'
              type='button'
              data-bs-toggle='dropdown'
              aria-expanded='false'
              aria-label={t('controlsPanel.layoutHover')}
            >
              <MenuIcon />
            </button>
          </Tooltip>
          <ul className='dropdown-menu'>
            <div className='graphView-panel-dropdown-header'> {t('controlsPanel.layoutHover')} </div>

            <Tooltip title={t('controlsPanel.rasterizeHover')}>
              <button
                className={`graphView-panel-button ${props.rasterize ? 'pressed' : ''}`}
                type='button'
                onClick={props.toggleRasterization}
                aria-label={t('controlsPanel.rasterizeHover')}
              >
                <RasterIcon />
              </button>
            </Tooltip>
            {/*
            <button className='graphView-panel-button' type='button' onClick={props.restoreUserLayout}>
              <RestoreLayoutIcon />
            </button>
            */}
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.autoLayoutELK}
              aria-label={t('controlsPanel.autoLayoutElkAria')}
            >
              <AutoLayout1Icon />
            </button>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.autoLayoutDagre}
              aria-label={t('controlsPanel.autoLayoutDagreAria')}
            >
              <AutoLayout2Icon />
            </button>
          </ul>
        </span>
        <Tooltip title={t('controlsPanel.addNodeHover')}>
          <button
            className='graphView-panel-button'
            type='button'
            data-bs-toggle='modal'
            data-bs-target='#addNodeDialog'
            onClick={props.onAddNodeDialogOpened}
            aria-label={t('controlsPanel.addNodeHover')}
          >
            <AddNodeIcon />
          </button>
        </Tooltip>
        <Tooltip title={t('controlsPanel.editHover')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              data-bs-toggle='modal'
              data-bs-target='#editNodeDialog'
              disabled={!props.onEditNode}
              aria-label={t('controlsPanel.editHover')}
            >
              <EditIcon />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={t('controlsPanel.deleteHover')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.deleteSelected}
              disabled={!props.deleteSelected}
              aria-label={t('controlsPanel.deleteHover')}
            >
              <TrashIcon />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={t('controlsPanel.fitViewHover')}>
          <button
            className='graphView-panel-button'
            type='button'
            onClick={props.fitView}
            aria-label={t('controlsPanel.fitViewHover')}
          >
            <FitViewIcon />
          </button>
        </Tooltip>
        <Tooltip title={t('controlsPanel.undo')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.undo}
              disabled={!props.undo}
              aria-label={t('controlsPanel.undo')}
            >
              <UndoIcon />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={t('controlsPanel.redo')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.redo}
              disabled={!props.redo}
              aria-label={t('controlsPanel.redo')}
            >
              <RedoIcon />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={t('controlsPanel.zoomInHover')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.zoomIn}
              aria-label={t('controlsPanel.zoomInHover')}
            >
              <ZoomInIcon />
            </button>
          </span>
        </Tooltip>
        <Tooltip title={t('controlsPanel.zoomOutHover')}>
          <span>
            <button
              className='graphView-panel-button'
              type='button'
              onClick={props.zoomOut}
              aria-label={t('controlsPanel.zoomOutHover')}
            >
              <ZoomOutIcon />
            </button>
          </span>
        </Tooltip>
      </div>
    </Panel>
  );
});
