/* tslint:disable */
/* eslint-disable */
/**
 * RestAPI and schema definition for Whyond
 * A RestAPI to - manage causal graphs - manage causal objects - pose causal queries to causal graphs or causal objects 
 *
 * The version of the OpenAPI document: 0.6.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * Answer to a `BaseQuery`. May need time to get calculated. It contains either   - A `body` containing the correct answer *or*   - An `error_message` explaining why no answer could be calculated  as ensured by `minProperties == 3` and `maxProperties == 3` 
 * @export
 * @interface BaseAnswer
 */
export interface BaseAnswer {
    /**
     * Universally unique ID of the answer
     * @type {string}
     * @memberof BaseAnswer
     */
    'id': string;
    /**
     * Universally unique ID of the answer\'s corresponding `BaseQuery` 
     * @type {string}
     * @memberof BaseAnswer
     */
    'query': string;
    /**
     * 
     * @type {BaseAnswerBody}
     * @memberof BaseAnswer
     */
    'body'?: BaseAnswerBody;
    /**
     * The reason why the query could not be calculated
     * @type {string}
     * @memberof BaseAnswer
     */
    'error_message'?: string;
}
/**
 * Values of a successfully calculated query
 * @export
 * @interface BaseAnswerBody
 */
export interface BaseAnswerBody {
    /**
     * Probability of an event, a value between zero and one 
     * @type {number}
     * @memberof BaseAnswerBody
     */
    'probability'?: number;
    /**
     * 
     * @type {BaseAnswerBodyHistogram}
     * @memberof BaseAnswerBody
     */
    'histogram'?: BaseAnswerBodyHistogram;
    /**
     * 
     * @type {BaseAnswerBodyScatter}
     * @memberof BaseAnswerBody
     */
    'scatter'?: BaseAnswerBodyScatter;
    /**
     * 
     * @type {string}
     * @memberof BaseAnswerBody
     */
    'svg'?: string;
}
/**
 * Histogram of dataset like numpy.hist() result. For an explanation, see the [numpy documentation](https://numpy.org/doc/stable/reference/generated/numpy.histogram.html) 
 * @export
 * @interface BaseAnswerBodyHistogram
 */
export interface BaseAnswerBodyHistogram {
    /**
     * The values of the histogram
     * @type {Array<number>}
     * @memberof BaseAnswerBodyHistogram
     */
    'hist': Array<number>;
    /**
     * The histograms bin edges (length(hist)+1)
     * @type {Array<number>}
     * @memberof BaseAnswerBodyHistogram
     */
    'bin_edges': Array<number>;
    /**
     * String for x-axis labeling
     * @type {string}
     * @memberof BaseAnswerBodyHistogram
     */
    'xlabel': string;
    /**
     * String for y-axis labeling
     * @type {string}
     * @memberof BaseAnswerBodyHistogram
     */
    'ylabel': string;
}
/**
 * Data for plotting, for example like in a matplotlib scatter plot. 
 * @export
 * @interface BaseAnswerBodyScatter
 */
export interface BaseAnswerBodyScatter {
    /**
     * The x-values of the scatter data
     * @type {Array<number>}
     * @memberof BaseAnswerBodyScatter
     */
    'x': Array<number>;
    /**
     * The y-values of the scatter data
     * @type {Array<number>}
     * @memberof BaseAnswerBodyScatter
     */
    'y': Array<number>;
    /**
     * String for x-axis labeling
     * @type {string}
     * @memberof BaseAnswerBodyScatter
     */
    'xlabel': string;
    /**
     * String for y-axis labeling
     * @type {string}
     * @memberof BaseAnswerBodyScatter
     */
    'ylabel': string;
}
/**
 * A `BaseQuery` contains of two things: 1. The `object` the `BaseQuery` is asked on which can be either:   - `CausalModel`   - `CausalGraph` 2. The `body` of the query which is “the question asked”  Therefere, both fields are required 
 * @export
 * @interface BaseQuery
 */
export interface BaseQuery {
    /**
     * Universally unique ID for a `BaseQuery`. Will normally be set by the server. 
     * @type {string}
     * @memberof BaseQuery
     */
    'id'?: string;
    /**
     * 
     * @type {BaseQueryObject}
     * @memberof BaseQuery
     */
    'object': BaseQueryObject;
    /**
     * The mathematical representation of the query the user wants to pose 
     * @type {string}
     * @memberof BaseQuery
     */
    'body': string;
    /**
     * Universally unique ID of the queries `BaseAnswer`
     * @type {string}
     * @memberof BaseQuery
     */
    'answer'?: string;
    /**
     * 
     * @type {QueryStatus}
     * @memberof BaseQuery
     */
    'status'?: QueryStatus;
    /**
     * Calculation progress in percent.
     * @type {number}
     * @memberof BaseQuery
     */
    'progress'?: number;
}


/**
 * The causal object the query is asked on. May be a model or a graph 
 * @export
 * @interface BaseQueryObject
 */
export interface BaseQueryObject {
    /**
     * Universally unique ID for the causal object
     * @type {string}
     * @memberof BaseQueryObject
     */
    'id': string;
    /**
     * Type for the causal object. `model` corresponds to `CausalModel` and `graph` to `CausalGraph` 
     * @type {string}
     * @memberof BaseQueryObject
     */
    'type': BaseQueryObjectTypeEnum;
}

export const BaseQueryObjectTypeEnum = {
    Model: 'model',
    Graph: 'graph'
} as const;

export type BaseQueryObjectTypeEnum = typeof BaseQueryObjectTypeEnum[keyof typeof BaseQueryObjectTypeEnum];

/**
 * Directed acyclic graph for causal inference. Suitable as “standalone” object. 
 * @export
 * @interface CausalGraph
 */
export interface CausalGraph {
    /**
     * Name of the causal graph.
     * @type {string}
     * @memberof CausalGraph
     */
    'name': string;
    /**
     * Universally unique identifier
     * @type {string}
     * @memberof CausalGraph
     */
    'id': string;
    /**
     * Explanation and context of the causal graph. Format should be like a git commit message. First line 50 character header, then an empty line, then the rest of the description. This formatting allows displaying only the header line in a causal model table. 
     * @type {string}
     * @memberof CausalGraph
     */
    'description': string;
    /**
     * Version of the `CausalGraph`. Recommendation is to use semantic versioning [semver.org](https://semver.org/). 
     * @type {string}
     * @memberof CausalGraph
     */
    'version': string;
    /**
     * OpenAPI schema version this `CausalGraph` conforms to. Simplifies validation of a json object 
     * @type {string}
     * @memberof CausalGraph
     */
    'schema_version': string;
    /**
     * 
     * @type {Set<Node>}
     * @memberof CausalGraph
     */
    'nodes': Set<Node>;
    /**
     * 
     * @type {Set<CausalGraphLinksInner>}
     * @memberof CausalGraph
     */
    'links': Set<CausalGraphLinksInner>;
    /**
     * 
     * @type {CausalGraphInfo}
     * @memberof CausalGraph
     */
    'info'?: CausalGraphInfo;
}
/**
 * Wrapper for Information used by external users, e.g. the frontend or project partner. The Whyond backend is required to keep the information stored here persistantly attached. 
 * @export
 * @interface CausalGraphInfo
 */
export interface CausalGraphInfo {
    /**
     * 
     * @type {CausalGraphInfoFrontend}
     * @memberof CausalGraphInfo
     */
    'frontend'?: CausalGraphInfoFrontend;
    /**
     * 
     * @type {CausalGraphInfoTesting}
     * @memberof CausalGraphInfo
     */
    'testing'?: CausalGraphInfoTesting;
}
/**
 * Wrapper for the Whyond frontend. 
 * @export
 * @interface CausalGraphInfoFrontend
 */
export interface CausalGraphInfoFrontend {
    /**
     * 
     * @type {Array<Layout>}
     * @memberof CausalGraphInfoFrontend
     */
    'layout'?: Array<Layout>;
    /**
     * The last modification date of the `CausalGraph`.
     * @type {string}
     * @memberof CausalGraphInfoFrontend
     */
    'last_modified'?: string;
}
/**
 * Wrapper for testing purpose. A possible user is Imbus AG. 
 * @export
 * @interface CausalGraphInfoTesting
 */
export interface CausalGraphInfoTesting {
    /**
     * 
     * @type {Array<Taxonomy>}
     * @memberof CausalGraphInfoTesting
     */
    'taxonomies'?: Array<Taxonomy>;
}
/**
 * Directed link between two nodes, using id referencing
 * @export
 * @interface CausalGraphLinksInner
 */
export interface CausalGraphLinksInner {
    /**
     * Unique id for a link. Uniqueness must hold for a causal graph 
     * @type {number}
     * @memberof CausalGraphLinksInner
     */
    'id': number;
    /**
     * Id of start node
     * @type {number}
     * @memberof CausalGraphLinksInner
     */
    'from': number;
    /**
     * Id of destination node
     * @type {number}
     * @memberof CausalGraphLinksInner
     */
    'to': number;
}
/**
 * A `CausalModel` is one of the main objects used in causal inference and there suitable as a “standalone” object. A fully specified `CausalModel` contains basically of three things: 1. A `CausalGraph` 1. A collection of `Distribution` objects for exogeneous `Node`s 1. A collection of `Mechanisms` objects for the endogeneous `Node`s 
 * @export
 * @interface CausalModel
 */
export interface CausalModel {
    /**
     * Universally unique identifier for the `CausalModel`.
     * @type {string}
     * @memberof CausalModel
     */
    'id': string;
    /**
     * Name for the causal model
     * @type {string}
     * @memberof CausalModel
     */
    'name': string;
    /**
     * Version of the `CausalModel`. Recommendation is to use semantic versioning [semver.org](https://semver.org/). 
     * @type {string}
     * @memberof CausalModel
     */
    'version': string;
    /**
     * OpenAPI schema version this `CausalModel` conforms to. Simplifies validation of a json object. 
     * @type {string}
     * @memberof CausalModel
     */
    'schema_version': string;
    /**
     * Explanation and context of the causal model. Format should be like a git commit message: First line 50 character header, then an empty line, then the rest of the description. This formatting allows displaying only the header line in a causal model table. 
     * @type {string}
     * @memberof CausalModel
     */
    'description': string;
    /**
     * Universally unique id of the `CausalGraph` to which `CausalModel` belongs to. 
     * @type {string}
     * @memberof CausalModel
     */
    'causal_graph': string;
    /**
     * 
     * @type {Set<Distribution>}
     * @memberof CausalModel
     */
    'distributions': Set<Distribution>;
    /**
     * 
     * @type {Set<Mechanism>}
     * @memberof CausalModel
     */
    'mechanisms': Set<Mechanism>;
    /**
     * 
     * @type {CausalModelInfo}
     * @memberof CausalModel
     */
    'info'?: CausalModelInfo;
}
/**
 * Wrapper for Information used by external users, e.g. the frontend or project partner. The Whyond backend is required to keep the information stored here persistantly attached. 
 * @export
 * @interface CausalModelInfo
 */
export interface CausalModelInfo {
    /**
     * 
     * @type {CausalModelInfoFrontend}
     * @memberof CausalModelInfo
     */
    'frontend'?: CausalModelInfoFrontend;
    /**
     * Wrapper for testing purpose. A possible user is Imbus AG. 
     * @type {object}
     * @memberof CausalModelInfo
     */
    'testing'?: object;
}
/**
 * Wrapper for the Whyond frontend. 
 * @export
 * @interface CausalModelInfoFrontend
 */
export interface CausalModelInfoFrontend {
    /**
     * The last modification date of the `CausalModel`.
     * @type {string}
     * @memberof CausalModelInfoFrontend
     */
    'last_modified'?: string;
}
/**
 * Answer to a `CoCaQuery` containing   - *Either* a `body` with the corner cases, their measure and their   corresponding analysis   - *Or* an `error_message` explaining why no answer could be     calculated  The XOR of `body` and `error_message` is ensured by `minProperties = maxProperties = 3`. May need time to get calculated. 
 * @export
 * @interface CoCaAnswer
 */
export interface CoCaAnswer {
    /**
     * Universally unique ID for a `CoCaQuery`. Will normally be set by the server. 
     * @type {string}
     * @memberof CoCaAnswer
     */
    'id': string;
    /**
     * Universally unique ID of the answer\'s corresponding `CoCaQuery`. 
     * @type {string}
     * @memberof CoCaAnswer
     */
    'query': string;
    /**
     * 
     * @type {CoCaBody}
     * @memberof CoCaAnswer
     */
    'body'?: CoCaBody;
    /**
     * The reason why the `CoCaQuery` could not be processed
     * @type {string}
     * @memberof CoCaAnswer
     */
    'error_message'?: string;
}
/**
 * Corner case values of a successfully calculated `CoCaQuery`.  The “indices” of all fields (`node_values.target`, `node_values.coca`, `node_values.other`, `measure` and `analysis`) are “aligned”, to be more precise: all information regarding a specific corner case has the same “pandas index id”. - `node_values` are the actual values of the corner cases. May be used   as input parameters for scenario based testing. They correspond   directly to the `Node`s used in `CoCaQuery.nodes`. - `measure` scores the quality of the corner cases, where   `measure.index` references the index of the corner case. - `analysis` scores the influence of each single parameter of a corner   case, where `analysis.index` references the index of the corner   case. 
 * @export
 * @interface CoCaBody
 */
export interface CoCaBody {
    /**
     * 
     * @type {CoCaBodyNodeValues}
     * @memberof CoCaBody
     */
    'node_values': CoCaBodyNodeValues;
    /**
     * 
     * @type {PandasSeries}
     * @memberof CoCaBody
     */
    'measure': PandasSeries;
    /**
     * 
     * @type {CoCaData}
     * @memberof CoCaBody
     */
    'analysis': CoCaData;
}
/**
 * The actual values of the corner cases. May be used as input parameters for scenario based testing. They correspond directly to the `Node`s used in `CoCaQuery.nodes`. - `target` are the values of `CoCaQuery.nodes.target`. Here,   `target.index` references the index of a specific corner case. - `coca` are the values of the `Node`s actively used in the   `CoCaQuery.eval_score` algorithm to “optimize” the target `Node`   specified in `CoCaQuery.nodes.target`. Here, `coca.index`   references the index of a specific corner case. - `other` are the values of the `Node`s not actively used in the   corner case search as specified in `CoCaQuery.nodes.other`. Here,   `other.index` references the index of a specific corner case. 
 * @export
 * @interface CoCaBodyNodeValues
 */
export interface CoCaBodyNodeValues {
    /**
     * 
     * @type {PandasSeries}
     * @memberof CoCaBodyNodeValues
     */
    'target': PandasSeries;
    /**
     * 
     * @type {CoCaData}
     * @memberof CoCaBodyNodeValues
     */
    'coca': CoCaData;
    /**
     * 
     * @type {CoCaData}
     * @memberof CoCaBodyNodeValues
     */
    'other'?: CoCaData;
}
/**
 * Values regarding a `CausalModel`, separated into either `single` or `multi`. Here and below, a `Node` is in `CausalModel.causal_graph.nodes[]`.  Depending on `Node.multi_dimension`, the values are - *Either* in the `single` field when `Node.multi_dimension` is empty   (the `Node` has only one dimension) - *Or* in the `multi` field when `Node.multi_dimension` is __not__   empty (the `Node` has multiple dimensions).  __single__ - `columns` references `Node.id` - `index` references the id of the corner case - `data` is the table of the corner case values  __multi__ Mapping of `Node.id` in `Node` and its multidimensional table data in `values`, where - `columns` references `Node.multi_dimension.id` - `index` references the id of the corner case - `data` is the table of the corner case values 
 * @export
 * @interface CoCaData
 */
export interface CoCaData {
    /**
     * 
     * @type {PandasDataframe}
     * @memberof CoCaData
     */
    'single': PandasDataframe;
    /**
     * See description of `CoCaData`.
     * @type {Array<CoCaDataMultiInner>}
     * @memberof CoCaData
     */
    'multi': Array<CoCaDataMultiInner>;
}
/**
 * 
 * @export
 * @interface CoCaDataMultiInner
 */
export interface CoCaDataMultiInner {
    /**
     * Unique id referencing `Nodes.id`.
     * @type {number}
     * @memberof CoCaDataMultiInner
     */
    'node': number;
    /**
     * 
     * @type {PandasDataframe}
     * @memberof CoCaDataMultiInner
     */
    'values': PandasDataframe;
}
/**
 * Unique ids of `Node`s parametrizing the corner case search. The field `target` specifies the single `Node` the corner case algorithms optimizes its `CoCaAnswer.body.measure` to. The field `coca` specifies the `Node`s actively used in the `CoCaQuery.eval_score` algorithm to optimize. The field `other` specifies which `Node`s are additionaly returned in `CoCaAnswer.other`, for instance for debugging. In `other`, the `Node`s may be   - *Either* specified explicitly as `array` of all `Node.id`   - *Or* as `array` with the single element `-1` (which is an invalid     `Node.id` in itself) meaning “just all `Node`s”. 
 * @export
 * @interface CoCaNodes
 */
export interface CoCaNodes {
    /**
     * Unique ID of a `Node` representing the “target“ of the corner case search. 
     * @type {number}
     * @memberof CoCaNodes
     */
    'target': number;
    /**
     * The `Node`s actively used in the `CoCaQuery.eval_score` algorithm to optimize. 
     * @type {Array<number>}
     * @memberof CoCaNodes
     */
    'coca': Array<number>;
    /**
     * Which `Node`s are additionaly returned in `CoCaAnswer.other`, for instance for debugging. In `other`, the `Node`s may be - *Either* specified explicitly as `array` of all `Node.id` - *Or* as `array` with the single element `-1` (which is an invalid   `Node.id` in itself) meaning “just all `Node`s”. 
     * @type {Array<number>}
     * @memberof CoCaNodes
     */
    'other': Array<number>;
}
/**
 * 
 * @export
 * @interface CoCaQuery
 */
export interface CoCaQuery {
    /**
     * Universally unique ID for a `CoCaQuery`. Will normally be set by the server. 
     * @type {string}
     * @memberof CoCaQuery
     */
    'id'?: string;
    /**
     * Universally unique ID of the `CausalModel`
     * @type {string}
     * @memberof CoCaQuery
     */
    'causal_model': string;
    /**
     * 
     * @type {CoCaNodes}
     * @memberof CoCaQuery
     */
    'nodes': CoCaNodes;
    /**
     * How many corner cases the user wants to have in the resulting `CoCaAnswer`. 
     * @type {number}
     * @memberof CoCaQuery
     */
    'count': number;
    /**
     * 
     * @type {CoCaQueryTargetRange}
     * @memberof CoCaQuery
     */
    'target_range': CoCaQueryTargetRange;
    /**
     * 
     * @type {EvalScore}
     * @memberof CoCaQuery
     */
    'eval_score': EvalScore;
    /**
     * Universally unique ID of `CoCaAnswer` to this `CoCaQuery`.
     * @type {string}
     * @memberof CoCaQuery
     */
    'answer'?: string;
    /**
     * 
     * @type {QueryStatus}
     * @memberof CoCaQuery
     */
    'status'?: QueryStatus;
    /**
     * Calculation progress in percent.
     * @type {number}
     * @memberof CoCaQuery
     */
    'progress'?: number;
}


/**
 * Parameter for the corner case
 * @export
 * @interface CoCaQueryTargetRange
 */
export interface CoCaQueryTargetRange {
    /**
     * What values are critical for the `target_node`.
     * @type {string}
     * @memberof CoCaQueryTargetRange
     */
    'extremum_side': CoCaQueryTargetRangeExtremumSideEnum;
    /**
     * Beyond which values of `target_node` is a corner case critical. 
     * @type {any}
     * @memberof CoCaQueryTargetRange
     */
    'limit'?: any;
}

export const CoCaQueryTargetRangeExtremumSideEnum = {
    Low: 'low',
    High: 'high'
} as const;

export type CoCaQueryTargetRangeExtremumSideEnum = typeof CoCaQueryTargetRangeExtremumSideEnum[keyof typeof CoCaQueryTargetRangeExtremumSideEnum];

/**
 * Response object to communicate id of created object
 * @export
 * @interface CreatedId
 */
export interface CreatedId {
    /**
     * Universally unique id of created object
     * @type {string}
     * @memberof CreatedId
     */
    'id': string;
}
/**
 * Probabilistic distribution for the exogeneous `Node`s. A `Distribution` is meaningful as part of a `CausalModel` only, hence its to a `CausalModel` locally unique `node` and its reference to a `CausalGraph.nodes[_].id` from `Causalmodel.causal_graph`. 
 * @export
 * @interface Distribution
 */
export interface Distribution {
    /**
     * Unique `Node` ID to which the distribution belongs to. Uniqueness must hold true for a `CausalGraph`. 
     * @type {number}
     * @memberof Distribution
     */
    'node': number;
    /**
     * Type of distribution
     * @type {string}
     * @memberof Distribution
     */
    'type': string;
}
/**
 * Default object to describe error messages
 * @export
 * @interface ErrorMessage
 */
export interface ErrorMessage {
    /**
     * Error message explaining why something failed
     * @type {string}
     * @memberof ErrorMessage
     */
    'error_message': string;
}
/**
 * Algorithm of evaluation score for cornercase search. While `necessity` uses a sampling approach, `root_cause` may last several minutes using a more elaborated algorithm. 
 * @export
 * @enum {string}
 */

export const EvalScore = {
    Necessity: 'necessity',
    RootCause: 'root_cause'
} as const;

export type EvalScore = typeof EvalScore[keyof typeof EvalScore];


/**
 * Answer to a `IdfyQuery`. May need time to get calculated. It contains either   - A `body` containing the correct answer *or*   - An `error_message` explaining why no answer could be calculated  as ensured by `minProperties == 3` and `maxProperties == 3` 
 * @export
 * @interface IdfyAnswer
 */
export interface IdfyAnswer {
    /**
     * Universally unique ID of the answer
     * @type {string}
     * @memberof IdfyAnswer
     */
    'id': string;
    /**
     * Universally unique ID of the answer\'s corresponding `IdfyQuery`. 
     * @type {string}
     * @memberof IdfyAnswer
     */
    'query': string;
    /**
     * 
     * @type {IdfyAnswerBody}
     * @memberof IdfyAnswer
     */
    'body'?: IdfyAnswerBody;
    /**
     * The reason why the query could not be calculated
     * @type {string}
     * @memberof IdfyAnswer
     */
    'error_message'?: string;
}
/**
 * Values of a successfully calculated query
 * @export
 * @interface IdfyAnswerBody
 */
export interface IdfyAnswerBody {
    /**
     * 
     * @type {string}
     * @memberof IdfyAnswerBody
     */
    'text': string;
    /**
     * 
     * @type {string}
     * @memberof IdfyAnswerBody
     */
    'latex': string;
    /**
     * The `Node`s needing data to get an estimate for the `BaseQuery`. 
     * @type {Array<number>}
     * @memberof IdfyAnswerBody
     */
    'data_nodes': Array<number>;
}
/**
 * A `IdfyQuery` contains of two things: 1. The `object` the `IdfyQuery` is asked on which can be either:   - `CausalModel`   - `CausalGraph` 2. The `body` of the query which is “the question asked”  Therefere, both fields are required 
 * @export
 * @interface IdfyQuery
 */
export interface IdfyQuery {
    /**
     * Universally unique ID of this `IdfyQuery`. Will normally be set by the server. 
     * @type {string}
     * @memberof IdfyQuery
     */
    'id'?: string;
    /**
     * 
     * @type {BaseQueryObject}
     * @memberof IdfyQuery
     */
    'object': BaseQueryObject;
    /**
     * The mathematical representation of the query the user wants to pose. 
     * @type {string}
     * @memberof IdfyQuery
     */
    'body': string;
    /**
     * Type of the query
     * @type {string}
     * @memberof IdfyQuery
     */
    'type': IdfyQueryTypeEnum;
    /**
     * Universally unique ID of the queries `IdfyAnswer`.
     * @type {string}
     * @memberof IdfyQuery
     */
    'answer'?: string;
    /**
     * 
     * @type {QueryStatus}
     * @memberof IdfyQuery
     */
    'status'?: QueryStatus;
    /**
     * Calculation progress in percent.
     * @type {number}
     * @memberof IdfyQuery
     */
    'progress'?: number;
}

export const IdfyQueryTypeEnum = {
    Estimate: 'estimate',
    Estimand: 'estimand'
} as const;

export type IdfyQueryTypeEnum = typeof IdfyQueryTypeEnum[keyof typeof IdfyQueryTypeEnum];

/**
 * Information of display positions of the `CausalGraph`. Used by the Whyond Frontend. 
 * @export
 * @interface Layout
 */
export interface Layout {
    /**
     * Id referencing `CausalGraph.nodes[].id`.
     * @type {number}
     * @memberof Layout
     */
    'id': number;
    /**
     * Width of `CausalGraph.nodes[]`.
     * @type {number}
     * @memberof Layout
     */
    'width': number;
    /**
     * Height of `CausalGraph.nodes[]`.
     * @type {number}
     * @memberof Layout
     */
    'height': number;
    /**
     * The x position of `CausalGraph.nodes[]`.
     * @type {number}
     * @memberof Layout
     */
    'x': number;
    /**
     * The y position of `CausalGraph.nodes[]`.
     * @type {number}
     * @memberof Layout
     */
    'y': number;
}
/**
 * Functional relationship between an endogeneous `Node` and its parent `Node`s. A `Mechanism` is meaningful as part of a `CausalModel` only, hence its to a `CausalModel` locally unique `node` and its reference to a `CausalGraph.nodes[_].id` from `Causalmodel.causal_graph`. A `Mechanism` must at least contain either a `formula` or a `code` field (or both), as ensured by `minProperties == 2`. 
 * @export
 * @interface Mechanism
 */
export interface Mechanism {
    /**
     * Unique `Node` ID to which the distribution belongs. Uniqueness must hold true for a `CausalGraph`. 
     * @type {number}
     * @memberof Mechanism
     */
    'node': number;
    /**
     * Python code for `Mechanism`
     * @type {string}
     * @memberof Mechanism
     */
    'code'?: string;
    /**
     * LaTeX string representing the `Mechanism`
     * @type {string}
     * @memberof Mechanism
     */
    'formula'?: string;
}
/**
 * Node in `CausalGraph` corresponding to a causal variable. A `Node` is meaningful as part of a `CausalGraph` only, hence its to a `CausalGraph` locally unique `id`. 
 * @export
 * @interface Node
 */
export interface Node {
    /**
     * Unique name for `Node` as given by the frontend user. May contain whitespace characters. Uniqueness must hold true for a `CausalGraph`. 
     * @type {string}
     * @memberof Node
     */
    'name': string;
    /**
     * Unique ID for `Node`. Uniqueness must hold true for a `CausalGraph` 
     * @type {number}
     * @memberof Node
     */
    'id': number;
    /**
     * Description of the node.
     * @type {string}
     * @memberof Node
     */
    'description'?: string;
    /**
     * Type of the `Node`, either `\"discrete\"` or `\"continuous\"`. 
     * @type {string}
     * @memberof Node
     */
    'value_type': NodeValueTypeEnum;
    /**
     * Kind of node
     * @type {string}
     * @memberof Node
     */
    'kind': NodeKindEnum;
    /**
     * Boolean wether data to node is available
     * @type {boolean}
     * @memberof Node
     */
    'data': boolean;
    /**
     * If `Node` has two or more dimensions, the array is used to store the respective names of the single dimensions. If `Node` is onedimensional, the array is empty. 
     * @type {Array<NodeMultiDimensionInner>}
     * @memberof Node
     */
    'multi_dimension': Array<NodeMultiDimensionInner>;
    /**
     * A short and unique abbreviation for `Node.name`. Uniqueness must hold true for a `CausalGraph`. Must be a valid `python` identifier. 
     * @type {string}
     * @memberof Node
     */
    'abbr'?: string;
    /**
     * Unique `python` identifier used as internal `Node` name. For usability in the “abstract query language”, the first latter has to be capitalized. It is taken from `Node.abbr` if existing, otherwise from `Node.name` by replacing whitespace with `“_”`. Only set if `Node.py_sym != Node.abbr` _and_ `Node.py_sym != Node.name`. 
     * @type {string}
     * @memberof Node
     */
    'py_sym'?: string;
    /**
     * (Physical) unit. If `Node` is multidimensional, all dimensions must have the same unit. 
     * @type {string}
     * @memberof Node
     */
    'unit'?: string;
    /**
     * 
     * @type {NodeValueRange}
     * @memberof Node
     */
    'value_range'?: NodeValueRange;
    /**
     * Possible states of a discrete `Node`. If `Node` is multidimensional, all dimension must have the same possible states. 
     * @type {Array<string>}
     * @memberof Node
     */
    'states'?: Array<string>;
}

export const NodeValueTypeEnum = {
    Discrete: 'discrete',
    Continuous: 'continuous'
} as const;

export type NodeValueTypeEnum = typeof NodeValueTypeEnum[keyof typeof NodeValueTypeEnum];
export const NodeKindEnum = {
    Endogene: 'endogene',
    Exogene: 'exogene'
} as const;

export type NodeKindEnum = typeof NodeKindEnum[keyof typeof NodeKindEnum];

/**
 * Mapping between id of dimension and name of the dimension. 
 * @export
 * @interface NodeMultiDimensionInner
 */
export interface NodeMultiDimensionInner {
    /**
     * 
     * @type {number}
     * @memberof NodeMultiDimensionInner
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof NodeMultiDimensionInner
     */
    'name'?: string;
}
/**
 * Range of values. If `Node` is multidimensional, all dimensions must have the same range of values. 
 * @export
 * @interface NodeValueRange
 */
export interface NodeValueRange {
    /**
     * 
     * @type {number}
     * @memberof NodeValueRange
     */
    'min': number;
    /**
     * 
     * @type {number}
     * @memberof NodeValueRange
     */
    'max': number;
}
/**
 * Taxonomy of Scenario-Object-Types as defined by [OpenScenario 2.0 Domain Model](https://www.asam.net/static_downloads/public/asam-openscenario/2.0.0/domain-model/core_layout.html) 
 * @export
 * @enum {string}
 */

export const OscTaxonomy = {
    OscScenarioOscAction: 'OScScenario.OScAction',
    OscScenarioOscActionActionForMovableObject: 'OScScenario.OScAction.action_for_movable_object',
    OscScenarioOscActionActionForEnvironment: 'OScScenario.OScAction.action_for_environment',
    OscScenarioOscActionActionForMovableObjectActionForVehicle: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle',
    OscScenarioOscActionActionForMovableObjectMove: 'OScScenario.OScAction.action_for_movable_object.move',
    OscScenarioOscActionActionForMovableObjectAssignPosition: 'OScScenario.OScAction.action_for_movable_object.assign_position',
    OscScenarioOscActionActionForMovableObjectAssignOrientation: 'OScScenario.OScAction.action_for_movable_object.assign_orientation',
    OscScenarioOscActionActionForMovableObjectAssignSpeed: 'OScScenario.OScAction.action_for_movable_object.assign_speed',
    OscScenarioOscActionActionForMovableObjectAssignAcceleration: 'OScScenario.OScAction.action_for_movable_object.assign_acceleration',
    OscScenarioOscActionActionForMovableObjectReplayPath: 'OScScenario.OScAction.action_for_movable_object.replay_path',
    OscScenarioOscActionActionForMovableObjectReplayTrajectory: 'OScScenario.OScAction.action_for_movable_object.replay_trajectory',
    OscScenarioOscActionActionForMovableObjectReplayTrajectoryRelativeTransform: 'OScScenario.OScAction.action_for_movable_object.replay_trajectory.relative_transform',
    OscScenarioOscActionActionForMovableObjectActionForPerson: 'OScScenario.OScAction.action_for_movable_object.action_for_person',
    OscScenarioOscActionActionForMovableObjectActionForPersonWalk: 'OScScenario.OScAction.action_for_movable_object.action_for_person.walk',
    OscScenarioOscActionActionForEnvironmentActAir: 'OScScenario.OScAction.action_for_environment.actAir',
    OscScenarioOscActionActionForEnvironmentActRain: 'OScScenario.OScAction.action_for_environment.actRain',
    OscScenarioOscActionActionForEnvironmentActSnow: 'OScScenario.OScAction.action_for_environment.actSnow',
    OscScenarioOscActionActionForEnvironmentActWind: 'OScScenario.OScAction.action_for_environment.actWind',
    OscScenarioOscActionActionForEnvironmentActFog: 'OScScenario.OScAction.action_for_environment.actFog',
    OscScenarioOscActionActionForEnvironmentActClouds: 'OScScenario.OScAction.action_for_environment.actClouds',
    OscScenarioOscActionActionForEnvironmentAssignCelestialPosition: 'OScScenario.OScAction.action_for_environment.assign_celestial_position',
    OscScenarioOscActionActionForEnvironmentActAirActTemperature: 'OScScenario.OScAction.action_for_environment.actAir.actTemperature',
    OscScenarioOscActionActionForEnvironmentActAirActPressure: 'OScScenario.OScAction.action_for_environment.actAir.actPressure',
    OscScenarioOscActionActionForEnvironmentActAirActRelativeHumidity: 'OScScenario.OScAction.action_for_environment.actAir.actRelative_humidity',
    OscScenarioOscActionActionForEnvironmentActRainActRainIntensity: 'OScScenario.OScAction.action_for_environment.actRain.actRain_intensity',
    OscScenarioOscActionActionForEnvironmentActSnowActSnowIntensity: 'OScScenario.OScAction.action_for_environment.actSnow.actSnow_intensity',
    OscScenarioOscActionActionForEnvironmentActWindActSpeed: 'OScScenario.OScAction.action_for_environment.actWind.actSpeed',
    OscScenarioOscActionActionForEnvironmentActWindActWindDirection: 'OScScenario.OScAction.action_for_environment.actWind.actWind_direction',
    OscScenarioOscActionActionForEnvironmentActFogActVisualRange: 'OScScenario.OScAction.action_for_environment.actFog.actVisual_range',
    OscScenarioOscActionActionForEnvironmentActCloudsActCloudiness: 'OScScenario.OScAction.action_for_environment.actClouds.actCloudiness',
    OscScenarioOscActionActionForEnvironmentAssignCelestialPositionLightSource: 'OScScenario.OScAction.action_for_environment.assign_celestial_position.light_source',
    OscScenarioOscActionActionForEnvironmentAssignCelestialPositionAzimuth: 'OScScenario.OScAction.action_for_environment.assign_celestial_position.azimuth',
    OscScenarioOscActionActionForEnvironmentAssignCelestialPositionElevation: 'OScScenario.OScAction.action_for_environment.assign_celestial_position.elevation',
    OscScenarioOscActionActionForMovableObjectActionForVehicleDrive: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.drive',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLane: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLane: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGap: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeTimeHeadway: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_time_headway',
    OscScenarioOscActionActionForMovableObjectActionForVehicleKeepSpaceGap: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.keep_space_gap',
    OscScenarioOscActionActionForMovableObjectActionForVehicleKeepTimeHeadway: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.keep_time_headway',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneFlOffset: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.flOffset',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneRateProfile: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.rate_profile',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneRatePeak: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.rate_peak',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneFlTarget: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.flTarget',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneNumOfLanes: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.num_of_lanes',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneSide: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.side',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneReference: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.reference',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneClOffset: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.clOffset',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneRateprofile: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.rate:profile',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneClTarget: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.clTarget',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneDynamicProfile: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.dynamic_profile',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneDynamicProfileNone: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.dynamic_profile.none',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneDynamicProfileConstant: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.dynamic_profile.constant',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneDynamicProfileSmooth: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.dynamic_profile.smooth',
    OscScenarioOscActionActionForMovableObjectActionForVehicleFollowLaneDynamicProfileAsap: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.follow_lane.dynamic_profile.asap',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSide: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSideLeft: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side.left',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSideRight: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side.right',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSideInside: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side.inside',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSideOutside: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side.outside',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeLaneChangeLaneSideSame: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_lane.change_lane_side.same',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGapCsgTarget: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap.csgTarget',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGapCsgReference: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap.csgReference',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGapGapDirection: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap.gap_direction',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGapGapDirectionAhead: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap.gap_direction.ahead',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeSpaceGapGapDirectionBehind: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_space_gap.gap_direction.behind',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeTimeHeadwayCthTarget: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_time_headway.cthTarget',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeTimeHeadwayCthReference: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_time_headway.cthReference',
    OscScenarioOscActionActionForMovableObjectActionForVehicleChangeTimeHeadwayHeadwayDirection: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.change_time_headway.headway_direction',
    OscScenarioOscActionActionForMovableObjectActionForVehicleKeepSpaceGapKspReference: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.keep_space_gap.kspReference',
    OscScenarioOscActionActionForMovableObjectActionForVehicleKeepSpaceGapDistanceDirection: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.keep_space_gap.distance_direction',
    OscScenarioOscActionActionForMovableObjectActionForVehicleKeepTimeHeadwayKthReference: 'OScScenario.OScAction.action_for_movable_object.action_for_vehicle.keep_time_headway.kthReference',
    OscScenarioOscActor: 'OScScenario.OScActor',
    OscScenarioOscActorOscPhysicalObject: 'OScScenario.OScActor.OScPhysicalObject',
    OscScenarioOscActorOscEnvironment: 'OScScenario.OScActor.OScEnvironment',
    OscScenarioOscActorOscMap: 'OScScenario.OScActor.OScMap',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossing: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingStartLane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.start_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingEndLane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.end_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingStartSCoords: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.start_s_coords',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingEndSCoords: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.end_s_coords',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingWidth: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.width',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingType: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingElevation: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_elevation',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingMarking: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_marking',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUse: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingElevationRoadLevel: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_elevation.road_level',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingElevationCurbLevel: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_elevation.curb_level',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingElevationRefugeLevel: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_elevation.refuge_level',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingElevationOtherElevation: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_elevation.other_elevation',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingMarkingUnmarked: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_marking.unmarked',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingMarkingMarked: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_marking.marked',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingMarkingZebra: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_marking.zebra',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingMarkingOtherMarking: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_marking.other_marking',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUsePedestrian: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use.pedestrian',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUseAnimal: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use.animal',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUseBicycle: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use.bicycle',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUseRailRoad: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use.rail_road',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscCrossingOscCrossingTypeOscCrossingUseOtherUse: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScCrossing.OScCrossing_type.OScCrossing_use.other_use',
    OscScenarioOscActorOscEnvironmentOscWeather: 'OScScenario.OScActor.OScEnvironment.OScWeather',
    OscScenarioOscActorOscEnvironmentGeodeticPosition: 'OScScenario.OScActor.OScEnvironment.geodetic_position',
    OscScenarioOscActorOscEnvironmentDatetime: 'OScScenario.OScActor.OScEnvironment.datetime',
    OscScenarioOscActorOscEnvironmentSun: 'OScScenario.OScActor.OScEnvironment.sun',
    OscScenarioOscActorOscEnvironmentMoon: 'OScScenario.OScActor.OScEnvironment.moon',
    OscScenarioOscActorOscEnvironmentOscWeatherAir: 'OScScenario.OScActor.OScEnvironment.OScWeather.air',
    OscScenarioOscActorOscEnvironmentOscWeatherRain: 'OScScenario.OScActor.OScEnvironment.OScWeather.rain',
    OscScenarioOscActorOscEnvironmentOscWeatherSnow: 'OScScenario.OScActor.OScEnvironment.OScWeather.snow',
    OscScenarioOscActorOscEnvironmentOscWeatherWind: 'OScScenario.OScActor.OScEnvironment.OScWeather.wind',
    OscScenarioOscActorOscEnvironmentOscWeatherFog: 'OScScenario.OScActor.OScEnvironment.OScWeather.fog',
    OscScenarioOscActorOscEnvironmentOscWeatherClouds: 'OScScenario.OScActor.OScEnvironment.OScWeather.clouds',
    OscScenarioOscActorOscEnvironmentOscWeatherAirTemperature: 'OScScenario.OScActor.OScEnvironment.OScWeather.air.temperature',
    OscScenarioOscActorOscEnvironmentOscWeatherAirPressure: 'OScScenario.OScActor.OScEnvironment.OScWeather.air.pressure',
    OscScenarioOscActorOscEnvironmentOscWeatherAirHumidity: 'OScScenario.OScActor.OScEnvironment.OScWeather.air.humidity',
    OscScenarioOscActorOscEnvironmentOscWeatherRainIntensity: 'OScScenario.OScActor.OScEnvironment.OScWeather.rain.intensity',
    OscScenarioOscActorOscEnvironmentOscWeatherFogVisualRange: 'OScScenario.OScActor.OScEnvironment.OScWeather.fog.visual_range',
    OscScenarioOscActorOscEnvironmentOscWeatherCloudsCloudiness: 'OScScenario.OScActor.OScEnvironment.OScWeather.clouds.cloudiness',
    OscScenarioOscActorOscEnvironmentOscWeatherWindSpeedDirection: 'OScScenario.OScActor.OScEnvironment.OScWeather.wind.speed_direction',
    OscScenarioOscActorOscMapOscRouteOscRouteElement: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneType: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneMinimumNumberOfLanes: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.minimum_number_of_lanes',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneTrafficDirection: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.traffic_direction',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneRestriction: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.restriction',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeNormal: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.normal',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeHov: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.HOV',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeBidirectional: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.bidirectional',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureBiking: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.biking',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeBorder: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.border',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureBus: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.bus',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeConnectingRamp: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.connecting_ramp',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeCurb: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.curb',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureDriving: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.driving',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeEntry: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.entry',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeExit: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.exit',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeMedian: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.median',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeOffRamp: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.off_ramp',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeOnRamp: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.on_ramp',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeParking: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.parking',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureRail: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.rail',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeRestricted: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.restricted',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeRoadWorks: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.road_works',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeShoulder: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.shoulder',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureSidewalk: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.sidewalk',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneLaneTypeStop: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.lane_type.stop',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureTaxi: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.taxi',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureTram: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.tram',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes1Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.1_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes2Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.2_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes3Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.3_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes4Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.4_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes5Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.5_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneNumberOfLanes6Lane: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.number_of_lanes.6_lane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneTrafficDirectionRightHandTraffic: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.traffic_direction.right_hand_traffic',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneTrafficDirectionLeftHandTraffic: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.traffic_direction.left_hand_traffic',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneRestrictionHeightRestriction: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.restriction.height_restriction',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneRestrictionWeightRestriction: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.restriction.weight_restriction',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneRestrictionWidthRestriction: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.restriction.width_restriction',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneRestrictionVehicleTypeRestriction: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane.restriction.vehicle_type_restriction',
    OscScenarioOscActorOscMapOscJunction: 'OScScenario.OScActor.OScMap.OScJunction',
    OscScenarioOscActorOscMapOscRoute: 'OScScenario.OScActor.OScMap.OScRoute',
    OscScenarioOscActorOscMapOscJunctionOscRoad: 'OScScenario.OScActor.OScMap.OScJunction.OScRoad',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscPath: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScPath',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscPathPoints: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScPath.points',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscPathInterpolation: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScPath.interpolation',
    OscScenarioOscActorOscPhysicalObjectOscBoundingBox: 'OScScenario.OScActor.OScPhysicalObject.OScBoundingBox',
    OscScenarioOscActorOscPhysicalObjectColor: 'OScScenario.OScActor.OScPhysicalObject.color',
    OscScenarioOscActorOscPhysicalObjectGeometryReference: 'OScScenario.OScActor.OScPhysicalObject.geometryReference',
    OscScenarioOscActorOscPhysicalObjectCenterOfGravity: 'OScScenario.OScActor.OScPhysicalObject.centerOfGravity',
    OscScenarioOscActorOscPhysicalObjectColorWhite: 'OScScenario.OScActor.OScPhysicalObject.color.white',
    OscScenarioOscActorOscPhysicalObjectColorSilver: 'OScScenario.OScActor.OScPhysicalObject.color.silver',
    OscScenarioOscActorOscPhysicalObjectColorGray: 'OScScenario.OScActor.OScPhysicalObject.color.gray',
    OscScenarioOscActorOscPhysicalObjectColorBlack: 'OScScenario.OScActor.OScPhysicalObject.color.black',
    OscScenarioOscActorOscPhysicalObjectColorRed: 'OScScenario.OScActor.OScPhysicalObject.color.red',
    OscScenarioOscActorOscPhysicalObjectColorMaroon: 'OScScenario.OScActor.OScPhysicalObject.color.maroon',
    OscScenarioOscActorOscPhysicalObjectColorYellow: 'OScScenario.OScActor.OScPhysicalObject.color.yellow',
    OscScenarioOscActorOscPhysicalObjectColorOlive: 'OScScenario.OScActor.OScPhysicalObject.color.olive',
    OscScenarioOscActorOscPhysicalObjectColorLime: 'OScScenario.OScActor.OScPhysicalObject.color.lime',
    OscScenarioOscActorOscPhysicalObjectColorGreen: 'OScScenario.OScActor.OScPhysicalObject.color.green',
    OscScenarioOscActorOscPhysicalObjectColorAqua: 'OScScenario.OScActor.OScPhysicalObject.color.aqua',
    OscScenarioOscActorOscPhysicalObjectColorTeal: 'OScScenario.OScActor.OScPhysicalObject.color.teal',
    OscScenarioOscActorOscPhysicalObjectColorBlue: 'OScScenario.OScActor.OScPhysicalObject.color.blue',
    OscScenarioOscActorOscPhysicalObjectColorNavy: 'OScScenario.OScActor.OScPhysicalObject.color.navy',
    OscScenarioOscActorOscPhysicalObjectColorFuchsia: 'OScScenario.OScActor.OScPhysicalObject.color.fuchsia',
    OscScenarioOscActorOscPhysicalObjectColorPurple: 'OScScenario.OScActor.OScPhysicalObject.color.purple',
    OscScenarioOscActorOscPhysicalObjectOscBoundingBoxBbCenter: 'OScScenario.OScActor.OScPhysicalObject.OScBoundingBox.bbCenter',
    OscScenarioOscActorOscPhysicalObjectOscBoundingBoxBbLength: 'OScScenario.OScActor.OScPhysicalObject.OScBoundingBox.bbLength',
    OscScenarioOscActorOscPhysicalObjectOscBoundingBoxBbWidth: 'OScScenario.OScActor.OScPhysicalObject.OScBoundingBox.bbWidth',
    OscScenarioOscActorOscPhysicalObjectOscBoundingBoxBbHeight: 'OScScenario.OScActor.OScPhysicalObject.OScBoundingBox.bbHeight',
    OscScenarioOscActorOscPhysicalObjectOscMovableObject: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipant: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicle: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscPedestrian: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScPedestrian',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscAnimal: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScAnimal',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategory: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxle: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleRearOverhang: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.rear_overhang',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructure: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantIntendedInfrastructureHov: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.intended_infrastructure.hov',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategoryCar: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category.car',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategoryTruck: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category.truck',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategoryTrailer: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category.trailer',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategoryVruVehicle: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category.vru_vehicle',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleVehicleCategoryOtherVehicle: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.vehicle_category.other_vehicle',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxleMaxSteering: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.max_steering',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxleWheelDiameter: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.wheel_diameter',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxleTrackWidth: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.track_width',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxlePositionX: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.position_x',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxlePositionY: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.position_y',
    OscScenarioOscActorOscPhysicalObjectOscMovableObjectOscTrafficParticipantOscVehicleAxleNumberOfWheels: 'OScScenario.OScActor.OScPhysicalObject.OScMovableObject.OScTrafficParticipant.OScVehicle.axle.number_of_wheels',
    OscScenarioOscActorOscPhysicalObjectOscStationaryObject: 'OScScenario.OScActor.OScPhysicalObject.OScStationaryObject',
    OscScenarioOscActorOscMapOscRelativePath: 'OScScenario.OScActor.OScMap.OScRelativePath',
    OscScenarioOscActorOscMapOscRelativePathPathInterPolation: 'OScScenario.OScActor.OScMap.OScRelativePath.pathInterPolation',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathPose3d: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathPose3d',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathSt: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathSt',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathOdr: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathOdr',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathPose3dOscPose3d: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathPose3d.OScPose3d',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathStOscRoutePoint: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathSt.OScRoutePoint',
    OscScenarioOscActorOscMapOscRelativePathOscRelativePathOdrOscOdrPoint: 'OScScenario.OScActor.OScMap.OScRelativePath.OScRelativePathOdr.OScOdrPoint',
    OscScenarioOscActorOscMapOscRelativeTrajectory: 'OScScenario.OScActor.OScMap.OScRelativeTrajectory',
    OscScenarioOscActorOscMapOscRelativeTrajectoryOscRelativeTrajectoryPose3d: 'OScScenario.OScActor.OScMap.OScRelativeTrajectory.OScRelativeTrajectoryPose3d',
    OscScenarioOscActorOscMapOscRelativeTrajectoryOscRelativeTrajectorySt: 'OScScenario.OScActor.OScMap.OScRelativeTrajectory.OScRelativeTrajectorySt',
    OscScenarioOscActorOscMapOscRelativeTrajectoryOscRelativeTrajectoryOdr: 'OScScenario.OScActor.OScMap.OScRelativeTrajectory.OScRelativeTrajectoryOdr',
    OscScenarioOscActorOscMapOscRelativeTrajectoryOscRelativeTrajectoryPose3dOscTrajectory3d: 'OScScenario.OScActor.OScMap.OScRelativeTrajectory.OScRelativeTrajectoryPose3d.OScTrajectory3d',
    OscScenarioOscActorOscMapOscRouteOscCompoundRoute: 'OScScenario.OScActor.OScMap.OScRoute.OScCompoundRoute',
    OscScenarioOscActorOscMapOscRouteOscCompoundLane: 'OScScenario.OScActor.OScMap.OScRoute.OScCompoundLane',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscLaneSection: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScLane_section',
    OscScenarioOscActorOscMapOscRouteOscRouteElementOscXyzPoint: 'OScScenario.OScActor.OScMap.OScRoute.OScRouteElement.OScXyzPoint',
    OscScenario: 'OScScenario',
    OscScenarioOscPhysicalTypes: 'OScScenario.OScPhysicalTypes'
} as const;

export type OscTaxonomy = typeof OscTaxonomy[keyof typeof OscTaxonomy];


/**
 * Table-like Object extracted from the `pandas` python library.  To construct again, let e.g. the object be `obj`, use `pandas.read_json(obj, orient=\"split\")`, see [the pandas doc](https://pandas.pydata.org/pandas-docs/stable/user_guide/io.html#json). The `orient=\"split\"` option was chosen after comparing all six options `pandas` offers regarding minimal size with 10000 samples.  To fill this with python, use e.g. `df.to_json(orient=\"split\")` with a `pandas.DataFrame` named `df`.  Since only ids are used in this API as column and rown names, `columns` and `index` are type `integer`.  The schema-table correspondence is (for further reference, see the pandas doc, too):   - columns: column names   - index: row names   - data: two-dim array with `[[c0r0, c1r0, …], [c0r1, c1r1, …], …]`,     where     - `ci` means the column of index `i` and     - `rj` means the row of index `j`. 
 * @export
 * @interface PandasDataframe
 */
export interface PandasDataframe {
    /**
     * 
     * @type {Array<number>}
     * @memberof PandasDataframe
     */
    'columns': Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof PandasDataframe
     */
    'index': Array<number>;
    /**
     * 
     * @type {Array<Array<PandasSeriesDataInner>>}
     * @memberof PandasDataframe
     */
    'data': Array<Array<PandasSeriesDataInner>>;
}
/**
 * Array-like Object extracted from the `pandas` python library.  To construct again, let e.g. the object be `obj`, use `pandas.read_json(obj, orient=\"split\")`, see [the pandas doc](https://pandas.pydata.org/pandas-docs/stable/user_guide/io.html#json).  To fill this with python, use e.g. `s.to_json(orient=\"split\")` with a `pandas.Series` named `s`.  Since only ids are used in this API as indices, `index` is type `integer`.  The scheme-array correspondence is (for further reference, see the pandas doc, too):   - name: name of the `pandas.Series`   - index: entry index   - data: entries 
 * @export
 * @interface PandasSeries
 */
export interface PandasSeries {
    /**
     * 
     * @type {string}
     * @memberof PandasSeries
     */
    'name': string;
    /**
     * 
     * @type {Array<number>}
     * @memberof PandasSeries
     */
    'index': Array<number>;
    /**
     * 
     * @type {Array<PandasSeriesDataInner>}
     * @memberof PandasSeries
     */
    'data': Array<PandasSeriesDataInner>;
}
/**
 * @type PandasSeriesDataInner
 * @export
 */
export type PandasSeriesDataInner = boolean | number | string;

/**
 * Query status at the server
 * @export
 * @enum {string}
 */

export const QueryStatus = {
    Calculating: 'calculating',
    Invalid: 'invalid',
    Pending: 'pending',
    Solved: 'solved'
} as const;

export type QueryStatus = typeof QueryStatus[keyof typeof QueryStatus];


/**
 * Mapping object between a taxonomy and a single `Node`. Meaningful for a `Node` with `Node.kind == \"endogene\"` only. The field `node` referred to the unique `Node.id`. The taxonomy itself is either   - In `osc` with an `OscTaxonomy` enum *or*   - In `other` represented as string  as ensured by `minProperties == 2` and `maxProperties == 2`. 
 * @export
 * @interface Taxonomy
 */
export interface Taxonomy {
    /**
     * Unique `Node.id` to which the taxonomy belongs to. Uniqueness must hold true for this `CausalGraph`. 
     * @type {number}
     * @memberof Taxonomy
     */
    'node': number;
    /**
     * 
     * @type {OscTaxonomy}
     * @memberof Taxonomy
     */
    'osc'?: OscTaxonomy;
    /**
     * Taxonomy not part of `OscTaxonomy`.
     * @type {string}
     * @memberof Taxonomy
     */
    'other'?: string;
}



/**
 * BaseApi - axios parameter creator
 * @export
 */
export const BaseApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;BaseQuery&#x60; the &#x60;BaseAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetBaseAnswers: async (answerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerId' is not null or undefined
            assertParamExists('apiGetBaseAnswers', 'answerId', answerId)
            const localVarPath = `/base-answers/{answerId}`
                .replace(`{${"answerId"}}`, encodeURIComponent(String(answerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a `BaseQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;BaseQuery&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetBaseQueries: async (queryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('apiGetBaseQueries', 'queryId', queryId)
            const localVarPath = `/base-queries/{queryId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new `BaseQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {BaseQuery} [baseQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostBaseQueries: async (baseQuery?: BaseQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(baseQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all available `BaseQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchBaseQueries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/base-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BaseApi - functional programming interface
 * @export
 */
export const BaseApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BaseApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;BaseQuery&#x60; the &#x60;BaseAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetBaseAnswers(answerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetBaseAnswers(answerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a `BaseQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;BaseQuery&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetBaseQueries(queryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BaseQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetBaseQueries(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new `BaseQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {BaseQuery} [baseQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostBaseQueries(baseQuery?: BaseQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostBaseQueries(baseQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all available `BaseQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchBaseQueries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BaseQuery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchBaseQueries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BaseApi - factory interface
 * @export
 */
export const BaseApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BaseApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;BaseQuery&#x60; the &#x60;BaseAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetBaseAnswers(answerId: string, options?: any): AxiosPromise<BaseAnswer> {
            return localVarFp.apiGetBaseAnswers(answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a `BaseQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;BaseQuery&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetBaseQueries(queryId: string, options?: any): AxiosPromise<BaseQuery> {
            return localVarFp.apiGetBaseQueries(queryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new `BaseQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {BaseQuery} [baseQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostBaseQueries(baseQuery?: BaseQuery, options?: any): AxiosPromise<CreatedId> {
            return localVarFp.apiPostBaseQueries(baseQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all available `BaseQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchBaseQueries(options?: any): AxiosPromise<Array<BaseQuery>> {
            return localVarFp.apiSearchBaseQueries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BaseApi - object-oriented interface
 * @export
 * @class BaseApi
 * @extends {BaseAPI}
 */
export class BaseApi extends BaseAPI {
    /**
     * 
     * @summary Get a queries answer by the answers universally unique ID
     * @param {string} answerId Universally unique ID of the &#x60;BaseQuery&#x60; the &#x60;BaseAnswer&#x60; belongs to. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public apiGetBaseAnswers(answerId: string, options?: AxiosRequestConfig) {
        return BaseApiFp(this.configuration).apiGetBaseAnswers(answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a `BaseQuery` by its universally unique `id`.
     * @summary Retrieve a query by its universally unique ID
     * @param {string} queryId Universally unique ID of the &#x60;BaseQuery&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public apiGetBaseQueries(queryId: string, options?: AxiosRequestConfig) {
        return BaseApiFp(this.configuration).apiGetBaseQueries(queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new `BaseQuery` to the calculation dequeue
     * @summary Pose a new causal query to a causal model
     * @param {BaseQuery} [baseQuery] Add a new query to the calculation dequeue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public apiPostBaseQueries(baseQuery?: BaseQuery, options?: AxiosRequestConfig) {
        return BaseApiFp(this.configuration).apiPostBaseQueries(baseQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all available `BaseQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
     * @summary Retrieve all causal queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BaseApi
     */
    public apiSearchBaseQueries(options?: AxiosRequestConfig) {
        return BaseApiFp(this.configuration).apiSearchBaseQueries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CornerCasesApi - axios parameter creator
 * @export
 */
export const CornerCasesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a corner-case queries answer by the answers universally unique ID. 
         * @param {string} answerId Universally unique ID of the &#x60;CoCaQuery&#x60; the &#x60;CoCaAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCocaAnswers: async (answerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerId' is not null or undefined
            assertParamExists('apiGetCocaAnswers', 'answerId', answerId)
            const localVarPath = `/coca-answers/{answerId}`
                .replace(`{${"answerId"}}`, encodeURIComponent(String(answerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a `CoCaQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;CoCaQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCocaQueries: async (queryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('apiGetCocaQueries', 'queryId', queryId)
            const localVarPath = `/coca-queries/{queryId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new `CoCaQuery` to the calculation dequeue
         * @summary Pose a new corner-case query to a causal model
         * @param {CoCaQuery} [coCaQuery] Add a new corner-case query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCocaQueries: async (coCaQuery?: CoCaQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coca-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(coCaQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all available `CoCaQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all corner-case queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCocaQueries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/coca-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CornerCasesApi - functional programming interface
 * @export
 */
export const CornerCasesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CornerCasesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a corner-case queries answer by the answers universally unique ID. 
         * @param {string} answerId Universally unique ID of the &#x60;CoCaQuery&#x60; the &#x60;CoCaAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetCocaAnswers(answerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoCaAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetCocaAnswers(answerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a `CoCaQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;CoCaQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetCocaQueries(queryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoCaQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetCocaQueries(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new `CoCaQuery` to the calculation dequeue
         * @summary Pose a new corner-case query to a causal model
         * @param {CoCaQuery} [coCaQuery] Add a new corner-case query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostCocaQueries(coCaQuery?: CoCaQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostCocaQueries(coCaQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all available `CoCaQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all corner-case queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCocaQueries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCocaQueries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CornerCasesApi - factory interface
 * @export
 */
export const CornerCasesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CornerCasesApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a corner-case queries answer by the answers universally unique ID. 
         * @param {string} answerId Universally unique ID of the &#x60;CoCaQuery&#x60; the &#x60;CoCaAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCocaAnswers(answerId: string, options?: any): AxiosPromise<CoCaAnswer> {
            return localVarFp.apiGetCocaAnswers(answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a `CoCaQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;CoCaQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCocaQueries(queryId: string, options?: any): AxiosPromise<CoCaQuery> {
            return localVarFp.apiGetCocaQueries(queryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new `CoCaQuery` to the calculation dequeue
         * @summary Pose a new corner-case query to a causal model
         * @param {CoCaQuery} [coCaQuery] Add a new corner-case query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCocaQueries(coCaQuery?: CoCaQuery, options?: any): AxiosPromise<CreatedId> {
            return localVarFp.apiPostCocaQueries(coCaQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all available `CoCaQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all corner-case queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCocaQueries(options?: any): AxiosPromise<void> {
            return localVarFp.apiSearchCocaQueries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CornerCasesApi - object-oriented interface
 * @export
 * @class CornerCasesApi
 * @extends {BaseAPI}
 */
export class CornerCasesApi extends BaseAPI {
    /**
     * 
     * @summary Get a corner-case queries answer by the answers universally unique ID. 
     * @param {string} answerId Universally unique ID of the &#x60;CoCaQuery&#x60; the &#x60;CoCaAnswer&#x60; belongs to. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CornerCasesApi
     */
    public apiGetCocaAnswers(answerId: string, options?: AxiosRequestConfig) {
        return CornerCasesApiFp(this.configuration).apiGetCocaAnswers(answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a `CoCaQuery` by its universally unique `id`.
     * @summary Retrieve a query by its universally unique ID
     * @param {string} queryId Universally unique ID of the &#x60;CoCaQuery&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CornerCasesApi
     */
    public apiGetCocaQueries(queryId: string, options?: AxiosRequestConfig) {
        return CornerCasesApiFp(this.configuration).apiGetCocaQueries(queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new `CoCaQuery` to the calculation dequeue
     * @summary Pose a new corner-case query to a causal model
     * @param {CoCaQuery} [coCaQuery] Add a new corner-case query to the calculation dequeue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CornerCasesApi
     */
    public apiPostCocaQueries(coCaQuery?: CoCaQuery, options?: AxiosRequestConfig) {
        return CornerCasesApiFp(this.configuration).apiPostCocaQueries(coCaQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all available `CoCaQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
     * @summary Retrieve all corner-case queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CornerCasesApi
     */
    public apiSearchCocaQueries(options?: AxiosRequestConfig) {
        return CornerCasesApiFp(this.configuration).apiSearchCocaQueries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Check API health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/health`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get version of currently used OpenAPI schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetSchemaVersion: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/schema-version`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Check API health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCheckHealth(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCheckHealth(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Get version of currently used OpenAPI schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetSchemaVersion(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetSchemaVersion(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DefaultApiFp(configuration)
    return {
        /**
         * 
         * @summary Check API health status
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCheckHealth(options?: any): AxiosPromise<void> {
            return localVarFp.apiCheckHealth(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get version of currently used OpenAPI schema
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetSchemaVersion(options?: any): AxiosPromise<string> {
            return localVarFp.apiGetSchemaVersion(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Check API health status
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiCheckHealth(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiCheckHealth(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get version of currently used OpenAPI schema
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public apiGetSchemaVersion(options?: AxiosRequestConfig) {
        return DefaultApiFp(this.configuration).apiGetSchemaVersion(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IdentifiabilityApi - axios parameter creator
 * @export
 */
export const IdentifiabilityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;IdfyQuery&#x60; the &#x60;IdfyAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetIdfyAnswers: async (answerId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'answerId' is not null or undefined
            assertParamExists('apiGetIdfyAnswers', 'answerId', answerId)
            const localVarPath = `/idfy-answers/{answerId}`
                .replace(`{${"answerId"}}`, encodeURIComponent(String(answerId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a `IdfyQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;IdfyQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetIdfyQueries: async (queryId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'queryId' is not null or undefined
            assertParamExists('apiGetIdfyQueries', 'queryId', queryId)
            const localVarPath = `/idfy-queries/{queryId}`
                .replace(`{${"queryId"}}`, encodeURIComponent(String(queryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new `IdfyQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {IdfyQuery} [idfyQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostIdfyQueries: async (idfyQuery?: IdfyQuery, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idfy-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(idfyQuery, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all available `IdfyQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchIdfyQueries: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/idfy-queries`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IdentifiabilityApi - functional programming interface
 * @export
 */
export const IdentifiabilityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IdentifiabilityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;IdfyQuery&#x60; the &#x60;IdfyAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetIdfyAnswers(answerId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdfyAnswer>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetIdfyAnswers(answerId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a `IdfyQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;IdfyQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetIdfyQueries(queryId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IdfyQuery>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetIdfyQueries(queryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new `IdfyQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {IdfyQuery} [idfyQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostIdfyQueries(idfyQuery?: IdfyQuery, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostIdfyQueries(idfyQuery, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all available `IdfyQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchIdfyQueries(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<IdfyQuery>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchIdfyQueries(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IdentifiabilityApi - factory interface
 * @export
 */
export const IdentifiabilityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IdentifiabilityApiFp(configuration)
    return {
        /**
         * 
         * @summary Get a queries answer by the answers universally unique ID
         * @param {string} answerId Universally unique ID of the &#x60;IdfyQuery&#x60; the &#x60;IdfyAnswer&#x60; belongs to. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetIdfyAnswers(answerId: string, options?: any): AxiosPromise<IdfyAnswer> {
            return localVarFp.apiGetIdfyAnswers(answerId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a `IdfyQuery` by its universally unique `id`.
         * @summary Retrieve a query by its universally unique ID
         * @param {string} queryId Universally unique ID of the &#x60;IdfyQuery&#x60;.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetIdfyQueries(queryId: string, options?: any): AxiosPromise<IdfyQuery> {
            return localVarFp.apiGetIdfyQueries(queryId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new `IdfyQuery` to the calculation dequeue
         * @summary Pose a new causal query to a causal model
         * @param {IdfyQuery} [idfyQuery] Add a new query to the calculation dequeue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostIdfyQueries(idfyQuery?: IdfyQuery, options?: any): AxiosPromise<CreatedId> {
            return localVarFp.apiPostIdfyQueries(idfyQuery, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all available `IdfyQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal queries
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchIdfyQueries(options?: any): AxiosPromise<Array<IdfyQuery>> {
            return localVarFp.apiSearchIdfyQueries(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IdentifiabilityApi - object-oriented interface
 * @export
 * @class IdentifiabilityApi
 * @extends {BaseAPI}
 */
export class IdentifiabilityApi extends BaseAPI {
    /**
     * 
     * @summary Get a queries answer by the answers universally unique ID
     * @param {string} answerId Universally unique ID of the &#x60;IdfyQuery&#x60; the &#x60;IdfyAnswer&#x60; belongs to. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentifiabilityApi
     */
    public apiGetIdfyAnswers(answerId: string, options?: AxiosRequestConfig) {
        return IdentifiabilityApiFp(this.configuration).apiGetIdfyAnswers(answerId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a `IdfyQuery` by its universally unique `id`.
     * @summary Retrieve a query by its universally unique ID
     * @param {string} queryId Universally unique ID of the &#x60;IdfyQuery&#x60;.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentifiabilityApi
     */
    public apiGetIdfyQueries(queryId: string, options?: AxiosRequestConfig) {
        return IdentifiabilityApiFp(this.configuration).apiGetIdfyQueries(queryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new `IdfyQuery` to the calculation dequeue
     * @summary Pose a new causal query to a causal model
     * @param {IdfyQuery} [idfyQuery] Add a new query to the calculation dequeue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentifiabilityApi
     */
    public apiPostIdfyQueries(idfyQuery?: IdfyQuery, options?: AxiosRequestConfig) {
        return IdentifiabilityApiFp(this.configuration).apiPostIdfyQueries(idfyQuery, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all available `IdfyQuery`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
     * @summary Retrieve all causal queries
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IdentifiabilityApi
     */
    public apiSearchIdfyQueries(options?: AxiosRequestConfig) {
        return IdentifiabilityApiFp(this.configuration).apiSearchIdfyQueries(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ModellingApi - axios parameter creator
 * @export
 */
export const ModellingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Delete a specific `CausalGraph` by its `id`
         * @summary Delete a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;CausalGraph.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDelCausalGraphs: async (graphId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'graphId' is not null or undefined
            assertParamExists('apiDelCausalGraphs', 'graphId', graphId)
            const localVarPath = `/causal-graphs/{graphId}`
                .replace(`{${"graphId"}}`, encodeURIComponent(String(graphId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Delete a specific `CausalModel` by its Universally unique `id` 
         * @summary Delete a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDelCausalModels: async (modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiDelCausalModels', 'modelId', modelId)
            const localVarPath = `/causal-models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a specific `CausalGraph` by its `id`
         * @summary Retrieve a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCausalGraphs: async (graphId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'graphId' is not null or undefined
            assertParamExists('apiGetCausalGraphs', 'graphId', graphId)
            const localVarPath = `/causal-graphs/{graphId}`
                .replace(`{${"graphId"}}`, encodeURIComponent(String(graphId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve a specific `CausalModel` by its universally unique `id` 
         * @summary Retrieve a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCausalModels: async (modelId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiGetCausalModels', 'modelId', modelId)
            const localVarPath = `/causal-models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new `CausalGraph` to Whyond
         * @summary Post a new causal graph
         * @param {CausalGraph} [causalGraph] Add a new &#x60;CausalGraph&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCausalGraphs: async (causalGraph?: CausalGraph, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/causal-graphs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causalGraph, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Add a new `CausalModel` to Whyond
         * @summary Post a new causal model
         * @param {CausalModel} [causalModel] Add a new &#x60;CausalModel&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCausalModels: async (causalModel?: CausalModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/causal-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causalModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace specific `CausalGraph` under `graphId`
         * @summary Create or replace specific CausalGraph under graphId
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {CausalGraph} [causalGraph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPutCausalGraphs: async (graphId: string, causalGraph?: CausalGraph, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'graphId' is not null or undefined
            assertParamExists('apiPutCausalGraphs', 'graphId', graphId)
            const localVarPath = `/causal-graphs/{graphId}`
                .replace(`{${"graphId"}}`, encodeURIComponent(String(graphId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causalGraph, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Create or replace specific `CausalModel` under `modelId`
         * @summary Create or replace specific CausalModel under modelId
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {CausalModel} [causalModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPutCausalModels: async (modelId: string, causalModel?: CausalModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'modelId' is not null or undefined
            assertParamExists('apiPutCausalModels', 'modelId', modelId)
            const localVarPath = `/causal-models/{modelId}`
                .replace(`{${"modelId"}}`, encodeURIComponent(String(modelId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(causalModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all available `CausalGraph`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCausalGraphs: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/causal-graphs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Retrieve all available `CausalModel`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCausalModels: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/causal-models`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ModellingApi - functional programming interface
 * @export
 */
export const ModellingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ModellingApiAxiosParamCreator(configuration)
    return {
        /**
         * Delete a specific `CausalGraph` by its `id`
         * @summary Delete a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;CausalGraph.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDelCausalGraphs(graphId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDelCausalGraphs(graphId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Delete a specific `CausalModel` by its Universally unique `id` 
         * @summary Delete a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDelCausalModels(modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CausalModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDelCausalModels(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a specific `CausalGraph` by its `id`
         * @summary Retrieve a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetCausalGraphs(graphId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CausalGraph>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetCausalGraphs(graphId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve a specific `CausalModel` by its universally unique `id` 
         * @summary Retrieve a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiGetCausalModels(modelId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CausalModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiGetCausalModels(modelId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new `CausalGraph` to Whyond
         * @summary Post a new causal graph
         * @param {CausalGraph} [causalGraph] Add a new &#x60;CausalGraph&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostCausalGraphs(causalGraph?: CausalGraph, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostCausalGraphs(causalGraph, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Add a new `CausalModel` to Whyond
         * @summary Post a new causal model
         * @param {CausalModel} [causalModel] Add a new &#x60;CausalModel&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPostCausalModels(causalModel?: CausalModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreatedId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPostCausalModels(causalModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace specific `CausalGraph` under `graphId`
         * @summary Create or replace specific CausalGraph under graphId
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {CausalGraph} [causalGraph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPutCausalGraphs(graphId: string, causalGraph?: CausalGraph, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPutCausalGraphs(graphId, causalGraph, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Create or replace specific `CausalModel` under `modelId`
         * @summary Create or replace specific CausalModel under modelId
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {CausalModel} [causalModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPutCausalModels(modelId: string, causalModel?: CausalModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPutCausalModels(modelId, causalModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all available `CausalGraph`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCausalGraphs(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CausalGraph>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCausalGraphs(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Retrieve all available `CausalModel`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSearchCausalModels(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CausalModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSearchCausalModels(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ModellingApi - factory interface
 * @export
 */
export const ModellingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ModellingApiFp(configuration)
    return {
        /**
         * Delete a specific `CausalGraph` by its `id`
         * @summary Delete a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;CausalGraph.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDelCausalGraphs(graphId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDelCausalGraphs(graphId, options).then((request) => request(axios, basePath));
        },
        /**
         * Delete a specific `CausalModel` by its Universally unique `id` 
         * @summary Delete a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDelCausalModels(modelId: string, options?: any): AxiosPromise<CausalModel> {
            return localVarFp.apiDelCausalModels(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a specific `CausalGraph` by its `id`
         * @summary Retrieve a specific causal graph by its id
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCausalGraphs(graphId: string, options?: any): AxiosPromise<CausalGraph> {
            return localVarFp.apiGetCausalGraphs(graphId, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve a specific `CausalModel` by its universally unique `id` 
         * @summary Retrieve a specific causal model by its uuid
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiGetCausalModels(modelId: string, options?: any): AxiosPromise<CausalModel> {
            return localVarFp.apiGetCausalModels(modelId, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new `CausalGraph` to Whyond
         * @summary Post a new causal graph
         * @param {CausalGraph} [causalGraph] Add a new &#x60;CausalGraph&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCausalGraphs(causalGraph?: CausalGraph, options?: any): AxiosPromise<CreatedId> {
            return localVarFp.apiPostCausalGraphs(causalGraph, options).then((request) => request(axios, basePath));
        },
        /**
         * Add a new `CausalModel` to Whyond
         * @summary Post a new causal model
         * @param {CausalModel} [causalModel] Add a new &#x60;CausalModel&#x60; to Whyond
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPostCausalModels(causalModel?: CausalModel, options?: any): AxiosPromise<CreatedId> {
            return localVarFp.apiPostCausalModels(causalModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace specific `CausalGraph` under `graphId`
         * @summary Create or replace specific CausalGraph under graphId
         * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
         * @param {CausalGraph} [causalGraph] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPutCausalGraphs(graphId: string, causalGraph?: CausalGraph, options?: any): AxiosPromise<void> {
            return localVarFp.apiPutCausalGraphs(graphId, causalGraph, options).then((request) => request(axios, basePath));
        },
        /**
         * Create or replace specific `CausalModel` under `modelId`
         * @summary Create or replace specific CausalModel under modelId
         * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
         * @param {CausalModel} [causalModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPutCausalModels(modelId: string, causalModel?: CausalModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPutCausalModels(modelId, causalModel, options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all available `CausalGraph`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal graphs
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCausalGraphs(options?: any): AxiosPromise<Array<CausalGraph>> {
            return localVarFp.apiSearchCausalGraphs(options).then((request) => request(axios, basePath));
        },
        /**
         * Retrieve all available `CausalModel`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
         * @summary Retrieve all causal models
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSearchCausalModels(options?: any): AxiosPromise<Array<CausalModel>> {
            return localVarFp.apiSearchCausalModels(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ModellingApi - object-oriented interface
 * @export
 * @class ModellingApi
 * @extends {BaseAPI}
 */
export class ModellingApi extends BaseAPI {
    /**
     * Delete a specific `CausalGraph` by its `id`
     * @summary Delete a specific causal graph by its id
     * @param {string} graphId Universally unique &#x60;CausalGraph.id&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiDelCausalGraphs(graphId: string, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiDelCausalGraphs(graphId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Delete a specific `CausalModel` by its Universally unique `id` 
     * @summary Delete a specific causal model by its uuid
     * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiDelCausalModels(modelId: string, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiDelCausalModels(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a specific `CausalGraph` by its `id`
     * @summary Retrieve a specific causal graph by its id
     * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiGetCausalGraphs(graphId: string, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiGetCausalGraphs(graphId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve a specific `CausalModel` by its universally unique `id` 
     * @summary Retrieve a specific causal model by its uuid
     * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiGetCausalModels(modelId: string, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiGetCausalModels(modelId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new `CausalGraph` to Whyond
     * @summary Post a new causal graph
     * @param {CausalGraph} [causalGraph] Add a new &#x60;CausalGraph&#x60; to Whyond
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiPostCausalGraphs(causalGraph?: CausalGraph, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiPostCausalGraphs(causalGraph, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Add a new `CausalModel` to Whyond
     * @summary Post a new causal model
     * @param {CausalModel} [causalModel] Add a new &#x60;CausalModel&#x60; to Whyond
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiPostCausalModels(causalModel?: CausalModel, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiPostCausalModels(causalModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace specific `CausalGraph` under `graphId`
     * @summary Create or replace specific CausalGraph under graphId
     * @param {string} graphId Universally unique &#x60;id&#x60; of the &#x60;CausalGraph&#x60;
     * @param {CausalGraph} [causalGraph] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiPutCausalGraphs(graphId: string, causalGraph?: CausalGraph, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiPutCausalGraphs(graphId, causalGraph, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Create or replace specific `CausalModel` under `modelId`
     * @summary Create or replace specific CausalModel under modelId
     * @param {string} modelId Universally unique &#x60;CausalModel.id&#x60;
     * @param {CausalModel} [causalModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiPutCausalModels(modelId: string, causalModel?: CausalModel, options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiPutCausalModels(modelId, causalModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all available `CausalGraph`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
     * @summary Retrieve all causal graphs
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiSearchCausalGraphs(options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiSearchCausalGraphs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Retrieve all available `CausalModel`s. In future and with a big data base, this operation may be unpractical. A `filter` operation is therefore planned for a next API version. 
     * @summary Retrieve all causal models
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ModellingApi
     */
    public apiSearchCausalModels(options?: AxiosRequestConfig) {
        return ModellingApiFp(this.configuration).apiSearchCausalModels(options).then((request) => request(this.axios, this.basePath));
    }
}


