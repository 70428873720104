import { memo } from 'react';
import { Handle, Position } from 'reactflow';
import { ReactComponent as IconGears } from 'resources/assets/icons/icons8-gears.svg';
import { ReactComponent as IconClose } from 'resources/assets/icons/icons8-close.svg';
import { ReactComponent as IconNormalDist } from 'resources/assets/icons/icons8-normal-distribution-histogram.svg';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

type MultiPortNodeProps = { data: { label: string }; isConnectable: boolean };
export const MultiPortNode = memo(function MultiPortNode({ isConnectable, data }: MultiPortNodeProps) {
  const { t } = useTranslation();
  return (
    <>
      <div className='MultiPortNode-icon'>
        <Tooltip title={t('graphView.mechOkIconTooltip')}>
          <span className='nodeIcon nodeIcon-mech'>
            <IconGears width='48px' />
          </span>
        </Tooltip>
        <Tooltip title={t('graphView.wrongMechIconTooltip')}>
          <span className='nodeIcon nodeIcon-invalid'>
            <IconClose width='48px' />
          </span>
        </Tooltip>
        <Tooltip title={t('graphView.distOkIconTooltip')}>
          <span className='nodeIcon nodeIcon-dist'>
            <IconNormalDist width='48px' />
          </span>
        </Tooltip>
      </div>

      {data.label}
      <Handle isConnectable={isConnectable} type='source' position={Position.Top} id='t' />
      <Handle isConnectable={isConnectable} type='source' position={Position.Right} id='r' />
      <Handle isConnectable={isConnectable} type='source' position={Position.Bottom} id='b' />
      <Handle isConnectable={isConnectable} type='source' position={Position.Left} id='l' />
    </>
  );
});
