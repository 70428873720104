import { Link, useNavigate } from 'react-router-dom';
import { ReactComponent as IllustrationExpertInterviews } from 'resources/assets/illustrations/expertInterviews.svg';
import { ReactComponent as IllustrationModelCreation } from 'resources/assets/illustrations/causalModelCreation.svg';
import { ReactComponent as IllustrationInference } from 'resources/assets/illustrations/causalModelInference.svg';
import { useTranslation } from 'react-i18next';

type NavCardProperties = {
  title: string;
  description: string;
  tag: string;
  icon: JSX.Element;
  unavailable?: boolean;
};

const NavCard = ({ cardInformation }: { cardInformation: NavCardProperties }): JSX.Element => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  return (
    <div className={`nav-card${cardInformation.unavailable ? ' nav-card-unavailable text-muted' : ''}`}>
      <div
        className='card-container'
        onClick={() => {
          if (!cardInformation.unavailable) navigate(`../${cardInformation.tag}`);
        }}
        role='link'
        tabIndex={0}
        aria-hidden='true'
      >
        {cardInformation.icon}
        <h2>{cardInformation.title}</h2>
        {cardInformation.unavailable && <div className='card-unavailable-overlay'>{t('pages.unavailableOverlay')}</div>}
      </div>
      <p className='card-description'>{cardInformation.description}</p>
      {!cardInformation.unavailable && <Link to={`../${cardInformation.tag}`}>{t('pages.link')}</Link>}
    </div>
  );
};

export const Home = (): JSX.Element => {
  const { t } = useTranslation();

  const cards: readonly NavCardProperties[] = [
    {
      title: t('pages.titleInterviews'),
      description: t('pages.bodyInterviews'),
      tag: 'interviews',
      icon: <IllustrationExpertInterviews className='icon' />,
      unavailable: true,
    },
    {
      title: t('pages.titleCreation'),
      description: t('pages.bodyCreation'),
      tag: 'creation',
      icon: <IllustrationModelCreation className='icon' />,
    },
    {
      title: t('pages.titleInference'),
      description: t('pages.bodyInference'),
      tag: 'inference',
      icon: <IllustrationInference className='icon' />,
    },
  ];

  return (
    <div className='main-container' id='home-container'>
      {/* <h1 className='my-4'>{t('pages.homeTitle')}</h1> */}
      <h1 className='my-4'>{t('pages.homeTitle')}</h1>
      <h2>{t('pages.homeSubtitle')}</h2>
      <div id='nav-cards-container'>
        {cards.map((card) => (
          <NavCard key={card.tag} cardInformation={card} />
        ))}
      </div>
    </div>
  );
};
