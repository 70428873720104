import { useState } from 'react';
import { ReactComponent as IconExpand } from 'resources/assets/icons/icons8-forward-center.svg';

export const InformationEntry = ({
  name,
  entry,
  status,
}: {
  name: string;
  entry: number | string | JSX.Element;
  status?: string;
}): JSX.Element => {
  return (
    <div className='sideInfo-entry'>
      <div className='sideInfo-heading'>{name}</div>
      <span className={status}>{entry}</span>
    </div>
  );
};

InformationEntry.defaultProps = {
  status: '',
};

export const InformationEntryWithNode = ({
  name,
  entries,
  nodeType,
}: {
  name: string;
  entries: string[];
  nodeType: string;
}): JSX.Element => {
  return (
    <div className='sideInfo-entry'>
      <div className='sideInfo-heading'>
        {`${name} `}
        <span className={nodeType} />
      </div>
      {entries.length === 0 ? (
        <span>-</span>
      ) : (
        entries.map((entry) => {
          return <div key={entry}>{entry}</div>;
        })
      )}
    </div>
  );
};

export type InformationTableRow = { title: string; count: number };

const TableRow = ({ title, count }: InformationTableRow): JSX.Element => {
  return (
    <tr>
      <th className='p-0' scope='row'>
        {title}
      </th>
      <td className='p-0 text-end'>{count}</td>
    </tr>
  );
};

export const InformationTable = ({ name, entries }: { name: string; entries: InformationTableRow[] }): JSX.Element => {
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <div className='sideInfo-entry'>
      <div className='d-flex align-items-center'>
        <span className='sideInfo-heading'>{name}</span>
        <button
          className='sideInfo-button'
          type='button'
          aria-expanded={!collapsed}
          onClick={() => {
            setCollapsed((prevCollapsed) => !prevCollapsed);
          }}
        >
          <IconExpand style={{ transform: collapsed ? 'rotate(90deg)' : 'rotate(270deg)' }} />
        </button>
      </div>
      {collapsed ? (
        <div>{entries[0].count}</div>
      ) : (
        <table className='table table-borderless table-fixed'>
          <tbody>
            {entries.map((entry: InformationTableRow) => (
              <TableRow title={entry.title} count={entry.count} key={entry.title} />
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};
