import { checkMechanismCode } from 'components/MechanismTabs/MechanismUtils';
import { Graph } from 'components/ModelTabs/Graph';
import { Model } from 'components/ModelTabs/Model';
import { Dispatch, SetStateAction } from 'react';
import { Node } from 'components/openapi';
import { getNodesElligibleForDistribution, getNodesElligibleForMechanism } from 'components/Utils/funcs';

export enum CustomIcons {
  DIST_OK = 'dist',
  NO_DIST = 'missing',
  DEFAULT_DIST = 'dist',
  WRONG_DIST = 'invalid',

  MECH_OK = 'mech',
  NO_MECH = 'missing',
  DEFAULT_MECH = 'mech',
  WRONG_MECH = 'invalid',
}

export const updateMechanismIcons = (
  model: Model,
  graph: Graph,
  mechNodes: Node[],
  nodeIndices?: number[],
): { finishedMechs: number[]; wrongMechs: number[]; missingMechs: number[] } => {
  const allIndices = nodeIndices ?? mechNodes.map((node, index) => index);

  const finishedMechs: number[] = [];
  const wrongMechs: number[] = [];
  const missingMechs: number[] = [];
  allIndices.forEach((index) => {
    const node = mechNodes[index];
    const existingMechanism = Array.from(model.state().mechanisms).find((mech) => mech.node === node.id);
    if (existingMechanism === undefined || (!existingMechanism.code && !existingMechanism.formula)) {
      missingMechs.push(node.id);
      return;
    }

    if (existingMechanism.code && !checkMechanismCode(existingMechanism.code, node, graph.state())) {
      wrongMechs.push(node.id);
      return;
    }

    finishedMechs.push(node.id);
  });
  return { finishedMechs, wrongMechs, missingMechs };
};

export const setMechanismIcons = (
  model: Model,
  graph: Graph,
  setNodeIcons: Dispatch<SetStateAction<Record<string, CustomIcons>>>,
  nodeIndices?: number[],
): void => {
  const mechNodes = getNodesElligibleForMechanism(graph.state());
  const { finishedMechs, wrongMechs, missingMechs } = updateMechanismIcons(model, graph, mechNodes, nodeIndices);
  setNodeIcons((oldState) => {
    return {
      ...oldState,
      ...Object.fromEntries(finishedMechs.map((id) => [id, CustomIcons.MECH_OK])),
      ...Object.fromEntries(wrongMechs.map((id) => [id, CustomIcons.WRONG_MECH])),
      ...Object.fromEntries(missingMechs.map((id) => [id, CustomIcons.NO_MECH])),
    };
  });
};

export const updateDistributionIcons = (
  model: Model,
  distNodes: Node[],
  nodeIndices?: number[],
): { finishedDists: number[]; wrongDists: number[]; missingDists: number[] } => {
  const allIndices = nodeIndices ?? distNodes.map((node, index) => index);

  const finishedDists: number[] = [];
  const wrongDists: number[] = [];
  const missingDists: number[] = [];
  allIndices.forEach((index) => {
    const node = distNodes[index];
    const existingDistribution = Array.from(model.state().distributions).find((dist) => dist.node === node.id);
    if (existingDistribution === undefined) {
      missingDists.push(node.id);
      return;
    }
    if (!existingDistribution.type) {
      wrongDists.push(node.id);
      return;
    }

    finishedDists.push(node.id);
  });
  return { finishedDists, wrongDists, missingDists };
};

export const setDistributionIcons = (
  model: Model,
  graph: Graph,
  setNodeIcons: Dispatch<SetStateAction<Record<string, CustomIcons>>>,
  nodeIndices?: number[],
): void => {
  const distNodes = getNodesElligibleForDistribution(graph.state());
  const { finishedDists, wrongDists, missingDists } = updateDistributionIcons(model, distNodes, nodeIndices);
  setNodeIcons((oldState) => {
    return {
      ...oldState,
      ...Object.fromEntries(finishedDists.map((id) => [id, CustomIcons.DIST_OK])),
      ...Object.fromEntries(wrongDists.map((id) => [id, CustomIcons.WRONG_DIST])),
      ...Object.fromEntries(missingDists.map((id) => [id, CustomIcons.NO_DIST])),
    };
  });
};
