/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-useless-fragment */
import { useKeycloak } from '@react-keycloak/web';
import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

export const RequireAuthentication = () => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak?.login({
        redirectUri: `${window.location.origin}${location.pathname}`,
      });
    }
  }, [initialized, keycloak.authenticated]);

  return keycloak.authenticated ? <Outlet /> : null;
};
