export class EventHandler<EventType> {
  private eventListeners: Record<
    string,
    {
      type?: EventType;
      callback: () => void;
    }
  >;

  constructor() {
    this.eventListeners = {};
  }

  callEventListeners(eventType: EventType, skipIds: string[] = []) {
    Object.entries(this.eventListeners).forEach(([listenerId, { type: listenerType, callback }]) => {
      if (listenerType === undefined || eventType === listenerType) {
        const skip = skipIds.reduce<boolean>((any, skipId) => any || listenerId.startsWith(skipId), false);
        if (!skip) callback();
      }
    });
  }

  setListener(id: string, type: EventType | undefined, callback: () => void): void {
    this.eventListeners[id] = { type, callback };
  }
}
