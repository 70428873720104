import * as React from 'react';
import { textWithLineBreak } from 'components/QueryTabs/QueryTab';
import { ReactComponent as DownloadIcon } from 'resources/assets/icons/icons8-download.svg';
import Box from '@mui/material/Box';
import {
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Grid,
  Slider,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { Cancel as CancelIcon, Check } from '@mui/icons-material';
import {
  DataGrid,
  GridColDef,
  GridCsvExportOptions,
  GridCsvGetRowsToExportParams,
  GridRowSelectionModel,
  GridToolbarContainer,
  gridExpandedSortedRowIdsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';
import { useRerender, getNodeNameFromId } from 'components/Utils/funcs';
import { FormInput } from 'components/Utils/FormInput';
import { Model } from 'components/ModelTabs/Model';
import { Graph } from 'components/ModelTabs/Graph';
import { postCoCaQuery, getCoCaAnswer } from 'components/Utils/BackendApi';
import { CCUserInput } from 'components/CCTabs/CCResponse';
import { Node, CausalGraph, CoCaAnswer, CoCaQuery, CoCaBody, CoCaData, PandasDataframe } from 'components/openapi';

import { ApexHistogram } from 'components/CCTabs/ApexHistogram';
import { useTranslation } from 'react-i18next';
import { LinearProgressWithLabel } from './ProgressBar';

// Slider and Table elements from https://mui.com/material-ui/
function getAllEndogeneNodes(causalgraph: CausalGraph, filterIdString?: string): Node[] {
  // FilterIdString: id of a node that should NOT be returned
  const nodesArray = Array.from(causalgraph.nodes);
  const allEndogenes = nodesArray.filter((n) => n.kind === 'endogene');
  return filterIdString ? allEndogenes.filter((n) => n.id !== Number(filterIdString)) : allEndogenes;
}

function sortByNodeNames(nodeIdA: number, nodeIdB: number, graph: CausalGraph): number {
  const nodeNameA = getNodeNameFromId(graph, nodeIdA);
  const nodeNameB = getNodeNameFromId(graph, nodeIdB);
  if (nodeNameA < nodeNameB) return -1;
  if (nodeNameA > nodeNameB) return 1;
  return 0;
}

function sortCoCaAnalysisColumns(analysis: CoCaData, graph: CausalGraph): CoCaData {
  const newAnalysisSingle = { ...(analysis.single ?? {}) } as object;

  // Connects node IDs to analysis column to make it sortable
  newAnalysisSingle['data'] = newAnalysisSingle['data']?.map((cornerCase) => {
    return cornerCase.map((column, columnOrder) => {
      return { nodeIndex: newAnalysisSingle['columns'][columnOrder], column };
    });
  });

  // Sorting by node names
  newAnalysisSingle['data'] = newAnalysisSingle['data'].map((cornerCase) => {
    return cornerCase.sort((newColObjectA, newColObjectB) => {
      return sortByNodeNames(newColObjectA.nodeIndex, newColObjectB.nodeIndex, graph);
    });
  });

  newAnalysisSingle['columns'] = newAnalysisSingle['data'][0].map((newColObject) => newColObject.nodeIndex);

  // Splits node Ids and analysis columns
  newAnalysisSingle['data'] = newAnalysisSingle['data']?.map((cornerCase) => {
    return cornerCase.map((newColObject) => {
      return newColObject['column'];
    });
  });

  const newAnalysis = { ...analysis };

  newAnalysis['single'] = newAnalysisSingle as PandasDataframe;

  return newAnalysis;
}

const getFilteredSortedRows = ({ apiRef }: GridCsvGetRowsToExportParams) => gridExpandedSortedRowIdsSelector(apiRef);

const TableExportToolbar = () => {
  const { t } = useTranslation();

  const apiRef = useGridApiContext();

  const handleExport = (options: GridCsvExportOptions) => apiRef.current.exportDataAsCsv(options);

  return (
    <GridToolbarContainer style={{ marginLeft: 'auto', marginRight: 0 }}>
      <Tooltip title={t('ccSideTab.exportTableHover')}>
        <Button
          aria-label={t('ccSideTab.exportTableHover')}
          startIcon={<DownloadIcon />}
          className='toolbar-button'
          onClick={() => handleExport({ getRowsToExport: getFilteredSortedRows })}
        />
      </Tooltip>
    </GridToolbarContainer>
  );
};

type MUICCTableProps = {
  dataframes: CoCaBody;
  causalgraph: CausalGraph;
  selectionModel: GridRowSelectionModel;
  setSelectionModel: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>;
};

export const MUICCTable = (props: MUICCTableProps) => {
  const { dataframes, causalgraph, selectionModel, setSelectionModel } = props;

  const { node_values: nodeVals, measure } = dataframes;

  if (nodeVals.coca.single) {
    // Sort columns without the 'goal' column
    // Dirty way to make deep copy
    const sortedCornerCases = JSON.parse(JSON.stringify(nodeVals.coca)) as CoCaData;
    const orderedCases = sortCoCaAnalysisColumns(sortedCornerCases, causalgraph);

    let sortedOther = {
      single: { index: [], columns: [], data: orderedCases.single.index.map(() => []) },
      multi: [],
    } as CoCaData;
    if ((nodeVals.other?.single.columns.length ?? 0) > 0) {
      sortedOther = JSON.parse(JSON.stringify(nodeVals.other)) as CoCaData;
      sortedOther = sortCoCaAnalysisColumns(sortedOther, causalgraph);
    }

    orderedCases.single = {
      index: [...orderedCases.single.index],
      columns: [...orderedCases.single.columns, Number(nodeVals.target.name), ...sortedOther.single.columns],
      data: orderedCases.single.data.map((cornerCase, index) => {
        return [...cornerCase, nodeVals.target.data[index], ...sortedOther.single.data[index]];
      }),
    };

    // Create table
    const columns: GridColDef[] = [
      {
        field: 'ID',
        headerName: 'ID',
        sortable: true,
        filterable: false,
        width: 30,
      },
    ];
    orderedCases.single.columns.forEach((nodeId) =>
      columns.push({
        field: nodeId.toString(),
        headerName: getNodeNameFromId(causalgraph, nodeId, true),
        type: 'number',
        sortable: true,
        renderCell: ({ value }) => value.toFixed(2),
      }),
    );
    columns.push({
      field: 'measure',
      type: 'number',
      headerName: measure.name,
      sortable: true,
    });

    // Creating the rows
    const rows: object[] = [];
    orderedCases.single.data.forEach((rowArray, rowIndex) => {
      const newRow = { ID: orderedCases.single.index[rowIndex] + 1 };
      rowArray.forEach((columnValue, columnIndex) => {
        const colNodeId = orderedCases.single.columns[columnIndex].toString();
        newRow[colNodeId] = columnValue;
      });
      newRow['measure'] = measure.data[rowIndex];
      newRow['id'] = orderedCases.single.index[rowIndex]; // Row uses id of the Corner Case
      rows.push(newRow);
    });
    rows.sort((row1, row2) => {
      return row1['id'] - row2['id'];
    });

    return (
      <Grid
        maxWidth='80vw'
        sx={{
          width: '100%',
          maxHeight: '500px',
          overflow: 'auto',
        }}
      >
        <DataGrid
          rows={rows}
          columns={columns}
          // checkboxSelection // comment out to disable multiple selection
          onRowSelectionModelChange={(newRowSModel) => {
            setSelectionModel(newRowSModel);
          }}
          rowSelectionModel={selectionModel}
          autoHeight={false}
          hideFooter
          slots={{
            toolbar: TableExportToolbar,
          }}
        />
      </Grid>
    );
  }
  return null; // Multi corner cases must be implemented
};

const ExtremumSideSlider = (props: {
  onChange: (event: Event, newValue: number | number[]) => void;
  value: number;
}) => {
  const { t } = useTranslation();

  const { onChange, value } = props;
  const marks = [
    { value: 0, label: t('ccSideTab.signLabelLow') },
    { value: 1, label: t('ccSideTab.signLabelHigh') },
  ];
  return (
    <Box sx={{ width: 300 }}>
      <Slider
        id='extremum-slider'
        aria-label='extremum-slider'
        value={value}
        min={0}
        max={1}
        step={1}
        valueLabelDisplay='off'
        marks={marks}
        onChange={onChange}
      />
    </Box>
  );
};

const ccData: Record<string, { input: CCUserInput; query: CoCaQuery; response?: CoCaAnswer }> = {};

function getDefaultQuery(modelId: string): CoCaQuery {
  return {
    causal_model: modelId,
    nodes: { coca: [], target: 0, other: [] },
    count: 5,
    target_range: { extremum_side: 'low', limit: 0.0 },
    eval_score: 'necessity',
    // TODO: limit should be optional
  };
}

export const CCTab = ({ model, graph }: { model: Model; graph: Graph }): JSX.Element => {
  const { t } = useTranslation();

  const rerender = useRerender();
  const modelId = model.state().id as string;

  const [displayedPlots, setDisplayedPlots] = React.useState<GridRowSelectionModel>([0]);

  if (ccData[modelId] === undefined)
    ccData[modelId] = {
      input: {
        goal: getAllEndogeneNodes(graph.state())[0]?.id.toString(),
        ccnode: [],
        sign: 0,
        limit: '',
        count: '',
        debug_mode: false,
        algorithm: 0,
      },
      query: getDefaultQuery(modelId),
    };
  const { input, query, response } = ccData[modelId];

  const handleSubmit = async (event) => {
    event.preventDefault();

    ccData[modelId].query = getDefaultQuery(modelId);

    //  -- cc range of target range: n → low | h → high
    query.target_range.extremum_side = input.sign ? 'high' : 'low';

    //   -- cc limit
    if (input.limit !== '' && !Number.isNaN(Number(input.limit))) {
      query.target_range.limit = Number(input.limit);
    }

    //  -- cc count
    if (Number(input.count) > 0) {
      query.count = Number(input.count);
    }

    //  -- eval score
    query.eval_score = input.algorithm ? 'root_cause' : 'necessity';

    // -- target, coca and other nodes
    let cocaNodes = input.ccnode.map((s) => Number(s));
    if (cocaNodes.length === 0) {
      cocaNodes = getAllEndogeneNodes(graph.state(), input.goal).map((node) => Number(node.id));
    }
    const targetNodeId = !Number.isNaN(Number(input.goal)) ? Number(input.goal) : 0;
    query.nodes = { target: targetNodeId, coca: cocaNodes, other: input.debug_mode ? [-1] : [] };

    query.progress = undefined;
    const progressUpdate = async (progress: number) => {
      if (ccData[modelId].query.progress !== progress) {
        ccData[modelId].query.progress = progress;
        rerender();
      }

      if (progress === 100) {
        setTimeout(() => {
          // Hide the progress bar
          ccData[modelId].query.progress = undefined;
          rerender();
        }, 600);

        // TODO: Comment out after implementation in backend
        /* if (query.answer) {
          const cocaAnswer = await getCoCaAnswer(query.answer);
          if (cocaAnswer) {
            setDisplayedPlots([0]);
            ccData[modelId].response = cocaAnswer;
            rerender();
          }
        } */
      }
    };

    query.causal_model = modelId;
    const uncompleteAnswer = await postCoCaQuery(query, progressUpdate);
    if (uncompleteAnswer.error_message) {
      ccData[modelId].response = uncompleteAnswer;
      rerender();
    } else {
      query.answer = uncompleteAnswer.id;
      const cocaAnswer = await getCoCaAnswer(query.answer);
      if (cocaAnswer) {
        setDisplayedPlots([0]);
        ccData[modelId].response = cocaAnswer;
        rerender();
      }
    }
  };

  return (
    <div className='cc-tab-container'>
      <form onSubmit={handleSubmit}>
        <div>
          <p className='text-muted'>{t('ccSideTab.goalTitle')}</p>

          <label htmlFor='target_node'>{t('ccSideTab.goalQuestion')}</label>
          <select
            id='target_node'
            className='form-select single-select'
            value={input.goal}
            onChange={(e) => {
              ccData[modelId].input.goal = e.target.value;
              if (input.ccnode.includes(input.goal)) {
                // Remove target node from the selected ccnodes
                ccData[modelId].input.ccnode = input.ccnode.filter((val) => val !== input.goal);
              }
              rerender();
            }}
            required
          >
            {getAllEndogeneNodes(graph.state())
              .sort((nodeA, nodeB) => sortByNodeNames(nodeA.id, nodeB.id, graph.state()))
              .map((node) => (
                <option value={node.id} key={`sel${node.id}`}>
                  {node.name}
                </option>
              ))}
          </select>

          <label htmlFor='ccnodes'>{t('ccSideTab.ccNodeQuestion')}</label>
          <br />
          <FormControl sx={{ m: 0, width: '60%' }} size='small'>
            <Select
              id='ccnodes'
              multiple
              value={ccData[modelId].input.ccnode}
              onChange={(event: SelectChangeEvent<string[]>) => {
                const {
                  target: { value },
                } = event;
                ccData[modelId].input.ccnode = (typeof value === 'string' ? value.split(',') : value) as string[];
                rerender();
              }}
              renderValue={() => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {ccData[modelId].input.ccnode
                    .sort((idNodeA, idNodeB) => sortByNodeNames(Number(idNodeA), Number(idNodeB), graph.state()))
                    .map((nodeId) => (
                      <Chip
                        key={`chip${nodeId}`}
                        label={getNodeNameFromId(graph.state(), Number(nodeId))}
                        clickable
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                        onDelete={() => {
                          ccData[modelId].input.ccnode = ccData[modelId].input.ccnode.filter(
                            (ccnodeId) => ccnodeId !== nodeId,
                          );
                          rerender();
                        }}
                      />
                    ))}
                </Box>
              )}
            >
              {getAllEndogeneNodes(graph.state())
                .sort((nodeA, nodeB) => sortByNodeNames(nodeA.id, nodeB.id, graph.state()))
                .map((node) => (
                  <MenuItem
                    dense
                    disabled={node.id.toString() === input.goal}
                    key={`muisel2${node.id}`}
                    value={node.id.toString()}
                  >
                    <ListItemText primary={node.name} />
                    {input.ccnode.indexOf(node.id.toString()) > -1 && <Check />}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>

        <br />
        <div>
          <p className='text-muted'>{t('ccSideTab.signTitle')}</p>
          <label htmlFor='extremum_slider'>{t('ccSideTab.signQuestion')}</label>
          <ExtremumSideSlider
            onChange={(event: Event, newValue: number | number[]) => {
              ccData[modelId].input.sign = newValue as number;
              rerender();
            }}
            value={input.sign}
          />
          <FormInput
            id='limit'
            name='limit'
            label={t('ccSideTab.targetLimitQuestion')}
            placeholder={t('ccSideTab.targetLimitPlaceholder')}
            value={input.limit}
            onChange={(e) => {
              ccData[modelId].input.limit = e.target.value;
              rerender();
            }}
          />
        </div>
        <br />
        <div>
          <p className='text-muted'>{t('ccSideTab.countTitle')}</p>
          <FormInput
            type='number'
            id='count'
            name='count'
            min='1'
            max='100'
            // defaultValue='5'
            label={t('ccSideTab.countQuestion')}
            placeholder={t('ccSideTab.countPlaceholder')}
            value={input.count}
            onChange={(e) => {
              if (ccData[modelId].input) {
                ccData[modelId].input.count = e.target.value;
              }
              rerender();
            }}
          />
        </div>
        <br />
        <div>
          <p className='text-muted'>{t('ccSideTab.debugModeTitle')}</p>
          <FormControlLabel
            label={t('ccSideTab.debugModeQuestion')}
            control={
              <Checkbox
                checked={input.debug_mode}
                onChange={(e) => {
                  if (ccData[modelId].input) {
                    ccData[modelId].input.debug_mode = e.target.checked;
                  }
                  rerender();
                }}
              />
            }
          />
        </div>
        <br />
        <div>
          <p className='text-muted'>{t('ccSideTab.evalScoreTitle')}</p>
          <label htmlFor='algo_type'>{t('ccSideTab.evalScoreQuestion')}</label>
          <select
            id='algo_type'
            className='form-select single-select'
            value={input.algorithm}
            onChange={(e) => {
              ccData[modelId].input.algorithm = Number(e.target.value);

              rerender();
            }}
            required
          >
            <option value='0'>{t('ccSideTab.evalScoreOption1')}</option>
            <option value='1'>{t('ccSideTab.evalScoreOption2')}</option>
          </select>
        </div>
        <br />
        <button type='submit' className={query ? '' : 'disabled text-muted'}>
          {t('querySideTab.button')}
        </button>
      </form>
      {query.progress !== undefined && (
        <>
          <p className='py-2'>{t('ccSideTab.progressLabel')}</p>
          <LinearProgressWithLabel value={query.progress} progressWidth='60%' />
        </>
      )}
      {response && (
        <div id='ccquery-response-container'>
          <p className='text-muted pt-3'>{t('ccSideTab.resultTitle')}</p>
          {response?.error_message && textWithLineBreak(response.error_message, 'pb-3 query-tab-error-message')}
          {response.body && Object.keys(response.body).length !== 0 && (
            <MUICCTable
              dataframes={response.body}
              causalgraph={graph.state()}
              selectionModel={displayedPlots}
              setSelectionModel={setDisplayedPlots}
            />
          )}
          <div className='ccplots'>
            {response && response?.body?.analysis && (
              <ApexHistogram
                analysis={sortCoCaAnalysisColumns(response?.body?.analysis, graph.state())}
                CoCaIndex={Number(displayedPlots[0])}
                graph={graph.state()}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
};
