import { v4 as uuid } from 'uuid';
import { CausalGraph, CausalModel, Node, CausalGraphLinksInner, Distribution, Mechanism } from 'components/openapi';
import { getCurrentTime } from './funcs';

export const addMissingCausalModelData = (model: CausalModel): CausalModel => {
  const newModel = model;
  newModel.id = model.id === '' ? uuid() : model.id;
  return newModel;
};

export const addMissingCausalGraphData = (graph: CausalGraph): CausalGraph => {
  const newGraph = graph;
  newGraph.info = graph.info ?? { frontend: { last_modified: getCurrentTime() } };
  newGraph.id = graph.id === '' ? uuid() : graph.id;
  newGraph.nodes = new Set(
    Array.from(graph.nodes).map((node) => {
      const newNode = node;
      if (!node.data) newNode.data = false;
      if (!node.description) newNode.description = '';
      return newNode;
    }),
  );
  return newGraph;
};

export const makeEmptyCausalModel = (): CausalModel => {
  return {
    id: '',
    description: '',
    causal_graph: '',
    distributions: new Set<Distribution>(),
    mechanisms: new Set<Mechanism>(),
    name: '',
    schema_version: '',
    version: '1.0.0',
  };
};

export const makeEmptyCausalGraph = (): CausalGraph => {
  return {
    id: '' /* graph needs uuid, so a new model can reference it */,
    description: '',
    name: '',
    schema_version: '',
    version: '1.0.0',
    links: new Set<CausalGraphLinksInner>(),
    nodes: new Set<Node>(),
    info: { frontend: { last_modified: '' } },
  };
};

export const emptyNode: Node = {
  id: 0,
  description: '',
  data: true,
  kind: 'endogene',
  multi_dimension: [],
  name: '',
  value_type: 'continuous',
};
