import { useCallback, useEffect, useRef, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { Logo } from 'components/Sidebar/Logo';
import { User } from 'components/Sidebar/User';
import { ReactComponent as IconHome } from 'resources/assets/icons/icons8-home.svg';
import { ReactComponent as IconOnboarding } from 'resources/assets/icons/icons8-mortarboard.svg';
import { ReactComponent as IconInterviews } from 'resources/assets/icons/icons8-communication.svg';
import { ReactComponent as IconCreation } from 'resources/assets/icons/modeling.svg';
import { ReactComponent as IconInference } from 'resources/assets/icons/icons8-search-more.svg';
import { ReactComponent as IconExpand } from 'resources/assets/icons/icons8-forward.svg';
import { ReactComponent as IconBurgerMenu } from 'resources/assets/icons/icons8-menu.svg';
import { ReactComponent as IconLogout } from 'resources/assets/icons/icons8-close.svg';
import i18n from 'i18n';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from '@mui/material';
import { useKeycloak } from '@react-keycloak/web';

type SidebarItems = {
  label: string;
  tag: string;
  icon: JSX.Element;
  ariaLabel: string;
  selected?: boolean;
};

const LanguageSwitcher = ({ collapsed }: { collapsed: boolean }): JSX.Element => {
  const [switchable, setSwitchable] = useState(false);

  const switchLanguage = () => {
    i18n.changeLanguage(i18n.resolvedLanguage === 'de' ? 'en' : 'de');
  };

  return (
    <div
      className='sidebar--language-wrapper'
      onMouseEnter={() => setSwitchable(true)}
      onMouseLeave={() => setSwitchable(false)}
    >
      <ButtonGroup variant='text' orientation={collapsed ? 'vertical' : 'horizontal'}>
        <button
          className={`sidebar--${i18n.resolvedLanguage === 'de' ? 'activeLanguage' : 'inactiveLanguage'}`}
          hidden={collapsed && !switchable && i18n.resolvedLanguage !== 'de'}
          onClick={switchLanguage}
          type='button'
        >
          DE
        </button>
        <button
          className={`sidebar--${i18n.resolvedLanguage === 'en' ? 'activeLanguage' : 'inactiveLanguage'}`}
          hidden={collapsed && !switchable && i18n.resolvedLanguage !== 'en'}
          onClick={switchLanguage}
          type='button'
        >
          EN
        </button>
      </ButtonGroup>
    </div>
  );
};

export const Sidebar = (): JSX.Element => {
  const { t } = useTranslation();
  const location = useLocation();

  const { keycloak } = useKeycloak();
  const [isSidebarCollapsed, setSidebarCollapsed] = useState(true);

  function toggleSidebar(): void {
    setSidebarCollapsed((prevCollapsed) => !prevCollapsed);
  }

  const [isNavbarCollapsed, setNavbarCollapsed] = useState(false);
  const navbarLastHeightBeforeCollapsed = useRef(0);

  useEffect(() => {
    const handleResize = () => {
      if (isNavbarCollapsed) {
        const burgerMenu = document.querySelector('.burger-menu');
        const langualgeSwitcher = document.querySelector('.sidebar--language-wrapper');

        if (burgerMenu && langualgeSwitcher) {
          const burgerMenuBottom = Math.round(burgerMenu.getBoundingClientRect().bottom);
          const languageSwitcherTop = Math.round(langualgeSwitcher.getBoundingClientRect().top);
          const freeSpaceInbetween = languageSwitcherTop - burgerMenuBottom;

          if (navbarLastHeightBeforeCollapsed.current <= freeSpaceInbetween) {
            setNavbarCollapsed(false);
          }
        }
      } else {
        const sidebarContainer = document.querySelector('.sidebar--container');
        const lastSidebarItem = document.querySelector('.sidebar--collapseButton');

        if (sidebarContainer && lastSidebarItem) {
          const sidebarContainerBottom = Math.round(sidebarContainer.getBoundingClientRect().bottom);
          const lastSidebarItemBottom = Math.round(lastSidebarItem.getBoundingClientRect().bottom);
          const lastSidebarItemOutsideViewport = lastSidebarItemBottom > sidebarContainerBottom;

          if (lastSidebarItemOutsideViewport) {
            setNavbarCollapsed(true);

            const userButton = document.querySelector('.userWrapper');
            const navbar = document.querySelector('.nav');
            if (navbar && userButton) {
              navbarLastHeightBeforeCollapsed.current =
                navbar.getBoundingClientRect().height + userButton.getBoundingClientRect().height;
            }
          }
        }
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isNavbarCollapsed]);

  const logout = useCallback(() => {
    keycloak?.logout();
  }, [keycloak]);

  const items: readonly SidebarItems[] = [
    {
      label: t('sidebar.home'),
      tag: 'home',
      icon: <IconHome className='icon' />,
      ariaLabel: t('sidebar.homeAriaLabel'),
      selected: location.pathname === '/home',
    },
    {
      label: t('sidebar.onboarding'),
      tag: 'onboarding',
      icon: <IconOnboarding className='icon' />,
      ariaLabel: t('sidebar.onboardingAriaLabel'),
      selected: location.pathname === '/onboarding',
    },
    {
      label: t('sidebar.interviews'),
      tag: 'interviews',
      icon: <IconInterviews className='icon' />,
      ariaLabel: t('sidebar.interviewsAriaLabel'),
      selected: location.pathname === '/interviews',
    },
    {
      label: t('sidebar.creation'),
      tag: 'creation',
      icon: <IconCreation className='icon' />,
      ariaLabel: t('sidebar.creationAriaLabel'),
      selected: location.pathname === '/creation',
    },
    {
      label: t('sidebar.inference'),
      tag: 'inference',
      icon: <IconInference className='icon' />,
      ariaLabel: t('sidebar.inferenceAriaLabel'),
      selected: location.pathname === '/inference',
    },
    {
      label: t('sidebar.navigateToLogin'),
      tag: 'logout',
      icon: <IconLogout className='icon' onClick={logout} />,
      ariaLabel: t('sidebar.loginAriaLabel'),
    },
  ];

  return (
    <nav className='sidebar--container' style={{ width: isSidebarCollapsed ? '5.5rem' : '16.875rem' }}>
      <Logo collapsed={isSidebarCollapsed} navLinkTo={items[0].tag} aria-label={items[0].ariaLabel} />
      {isNavbarCollapsed && (
        <div className='burger-menu'>
          <button
            className='navbar-toggler'
            type='button'
            onClick={() => {
              toggleSidebar();
            }}
          >
            <IconBurgerMenu className='icon' />
          </button>
        </div>
      )}
      {((isSidebarCollapsed && !isNavbarCollapsed) || !isSidebarCollapsed) && (
        <User sidebarCollapsed={isSidebarCollapsed} navbarCollapsed={isNavbarCollapsed} />
      )}
      {((isSidebarCollapsed && !isNavbarCollapsed) || !isSidebarCollapsed) && (
        <ul className='nav'>
          {items.map((item) => (
            <li
              key={item.tag}
              className={`sidebar--nav-item ${!isSidebarCollapsed && isNavbarCollapsed ? 'expanded' : ''} `}
            >
              <NavLink
                to={item.tag}
                aria-label={item.ariaLabel}
                className={`sidebar--item-link d-flex ${
                  !isSidebarCollapsed && isNavbarCollapsed ? 'justify-content-center' : 'justify-content-start'
                } ${(isActive: boolean) => (isActive ? 'active' : '')}`}
              >
                <button
                  className={`sidebar--nav-link ${item.selected ? 'active' : ''} ${
                    !isSidebarCollapsed && isNavbarCollapsed ? '' : 'with-icon'
                  }`}
                  type='button'
                  aria-controls={item.tag}
                >
                  {!isNavbarCollapsed && <div className='sidebar--iconWrapper'>{item.icon}</div>}
                  {!isSidebarCollapsed && (
                    <div className={`sidebar--text ${!isSidebarCollapsed && isNavbarCollapsed ? '' : 'with-icon'}`}>
                      {item.label}
                    </div>
                  )}
                </button>
              </NavLink>
            </li>
          ))}
        </ul>
      )}
      <LanguageSwitcher collapsed={isSidebarCollapsed} />
      <div className='sidebar--collapseButton'>
        <button
          className='sidebar--iconWrapper'
          type='button'
          onClick={() => {
            toggleSidebar();
          }}
        >
          {isSidebarCollapsed ? (
            <IconExpand className='sidebar--icon' aria-label={t('sidebar.sidebarExpandButtonAriaLabel')} />
          ) : (
            <IconExpand
              className='icon'
              aria-label={t('sidebar.sidebarCollapseButtonAriaLabel')}
              style={{ transform: 'rotate(180deg)' }}
            />
          )}
        </button>
      </div>
    </nav>
  );
};
