export type DropdownItem = {
  name: string;
  shortcut: string;
  startsNewSection: boolean;
  onClick?: () => void;
};

export type NavbarElementInfo = {
  name: string;
  items: DropdownItem[];
};

export const NavbarElement = ({ items }: { items: DropdownItem[] }): JSX.Element => {
  return (
    <ul className='dropdown-menu'>
      {items.map((item) => {
        return (
          <div key={item.name + item.shortcut}>
            {item.startsNewSection && <div className='dropdown-divider' />}
            <li>
              <button
                className='navbar--dropdown-item'
                type='button'
                onClick={item.onClick}
                disabled={item.onClick === undefined}
              >
                {item.name}
                <span className='navbar--shortcut'>{item.shortcut}</span>
              </button>
            </li>
          </div>
        );
      })}
    </ul>
  );
};

export const NavbarMenu = ({ navElements }: { navElements: NavbarElementInfo[] }): JSX.Element => {
  return (
    <ul className='navbar--menu'>
      {navElements.map((element) => (
        <li className='navbar--item' key={element.name}>
          <a className='navbar--link' href='/#' data-bs-toggle='dropdown'>
            {element.name}{' '}
          </a>
          <NavbarElement items={element.items} />
        </li>
      ))}
    </ul>
  );
};
