/* eslint-disable react/jsx-props-no-spreading */
import { LinearProgressProps, Box, LinearProgress, Typography } from '@mui/material';

export const LinearProgressWithLabel = (props: LinearProgressProps & { value: number; progressWidth: string }) => {
  const { value, progressWidth, ...otherProps } = props;

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: progressWidth, mr: 1 }}>
        <LinearProgress
          variant='determinate'
          {...otherProps}
          value={value}
          sx={{
            height: 10,
            borderRadius: 10,
          }}
          color='primary'
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant='body2' color='text.primary'>{`${Math.round(value)}%`}</Typography>
      </Box>
    </Box>
  );
};
