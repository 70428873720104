import { Navigate, Route, Routes } from 'react-router-dom';

import { Sidebar } from 'components/Sidebar/Sidebar';
import { Home } from 'pages/Home/Home';
import { Inference } from 'pages/Inference/Inference';
import { Creation } from 'pages/Creation/Creation';
import { Markers } from '../../components/GraphView/Markers';
import { LoginPage } from '../Login/LoginPage';
import keycloak from '../../keycloak';
import { RequireAuthentication } from '../../router/RequireAuthentication';

export const Dashboard = (): JSX.Element => {
  return (
    <div id='dashboard-container'>
      <Markers />
      <Sidebar />
      <Routes>
        {!keycloak?.authenticated && <Route path='/login' element={<LoginPage />} />}
        <Route element={<RequireAuthentication />}>
          <Route path='home' element={<Home />} />
          <Route path='inference' element={<Inference />} />
          <Route path='creation' element={<Creation />} />
          <Route path='login' element={<LoginPage />} />
        </Route>
        <Route path='*' element={<Navigate to='home' replace />} />
      </Routes>
    </div>
  );
};
