import { useEffect } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { useKeycloak } from '@react-keycloak/web';

interface LocationState {
  from: { pathname: string };
}

export const LoginPage = () => {
  const { keycloak, initialized } = useKeycloak();
  const location = useLocation();
  const navigate = useNavigate();
  const from = (location.state !== null && (location?.state as LocationState).from) || '/home';

  useEffect(() => {
    if (initialized && !keycloak.authenticated) {
      keycloak?.login({
        redirectUri: `${window.location.origin}${from}`,
      });
    }
  }, [initialized, keycloak.authenticated]);

  if (keycloak?.authenticated) navigate(from, { replace: true });

  return <Outlet />;
};
