import distributions, { Distribution as PredefinedDist } from './DistributionTypes';

export const parseDistString = (typeString: string): PredefinedDist | undefined => {
  const distName = typeString.split('(')[0];
  const distParams: number[] = typeString
    .replace(')', '')
    .split('(')[1]
    .replaceAll(' ', '')
    .split(',')
    .map((str) => Number(str));

  const foundDist: PredefinedDist | undefined = Object.values(distributions).find((dist) => dist.name === distName);
  if (foundDist) {
    foundDist.params = foundDist.params.map((param, index) => {
      return { ...param, value: distParams[index] };
    });
  }
  return foundDist;
};
