import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import DownloadIcon from 'resources/assets/icons/icons8-download.svg';
import ZoomInIcon from 'resources/assets/icons/layout_zoom_in.svg';
import ZoomOutIcon from 'resources/assets/icons/layout_zoom_out.svg';

const MAX_NUM_OF_XLABELS = 12;
const X_LABELS_PRECISION = 2;
const Y_LABELS_PRECISION = 3;

type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar';

type ApexChart = {
  type: ChartType;
  options: ApexOptions;
};
type BaseAnswerBodyScatter = { x?: number[]; xlabel?: string; y?: number[]; ylabel?: string };

function createApexAreaChart(scatter: BaseAnswerBodyScatter, xLabel?: string): ApexChart {
  const chart: ApexChart = {
    type: 'area',
    options: {
      series: [
        {
          name: scatter.ylabel ?? '',
          data: (scatter.x ?? []).map((xval, index) => ({ x: xval, y: (scatter.y ?? [])[index] })),
        },
      ],
      stroke: { curve: 'straight' },
      chart: {
        toolbar: {
          tools: {
            download: `<img src=${DownloadIcon}>`,
            zoomin: `<img src=${ZoomInIcon}>`,
            zoomout: `<img src=${ZoomOutIcon}>`,
            selection: false,
            zoom: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '100%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        min: Math.min(...(scatter.x || []).map((x) => -Math.abs(x))), // TODO: Enable user-change
        max: Math.max(...(scatter.x || []).map((x) => Math.abs(x))),
        title: {
          text: xLabel ?? scatter.xlabel,
          style: {
            fontSize: '0.9rem',
            fontWeight: '500',
          },
        },
        tickPlacement: 'on',
        tickAmount: Math.min(scatter.x?.length ?? MAX_NUM_OF_XLABELS, MAX_NUM_OF_XLABELS),
        type: 'numeric',
        labels: { formatter: (x) => (Number.isInteger(Number(x)) ? x : Number(x).toFixed(X_LABELS_PRECISION)) },
      },
      yaxis: {
        max: Math.max(0.5, ...(scatter.y ?? [])), // TODO: Enable user-change
        title: {
          text: scatter.ylabel,
          style: {
            fontSize: '0.9rem',
            fontWeight: '500',
          },
        },
        labels: {
          formatter: (y) => (Number.isInteger(Number(y)) ? y.toString() : Number(y).toFixed(Y_LABELS_PRECISION)),
        },
      },
    },
  };

  return chart;
}

export const ApexAreaChart = ({
  scatter,
  xLabel,
}: {
  scatter: BaseAnswerBodyScatter | undefined;
  xLabel: string | undefined;
}): JSX.Element | null => {
  if (scatter === undefined) {
    return null;
  }
  const chart = createApexAreaChart(scatter, xLabel);
  return <ReactApexChart options={chart?.options} series={chart.options?.series} type={chart?.type} height='500' />;
};
