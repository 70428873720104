import Elk, { ElkNode, LayoutOptions as ElkLayoutOptions } from 'elkjs';
import Dagre from 'dagre';

export type XY = {
  x: number;
  y: number;
};

export type Pos = {
  position: XY;
};

export type Id = {
  id: string;
};

export type Size = {
  width?: number | null;
  height?: number | null;
};

export type Connection = {
  source: string;
  target: string;
};

type AlignToGridProps<TNode extends Pos> = {
  nodes: TNode[];
  raster: XY;
};

export function alignToGrid<TNode extends Pos>({ nodes, raster }: AlignToGridProps<TNode>): TNode[] {
  return nodes.map((node) => ({
    ...node,
    position: {
      x: Math.round(node.position.x / raster.x) * raster.x,
      y: Math.round(node.position.y / raster.y) * raster.y,
    },
  }));
}

type ELKProps<TNode extends Id & Size> = {
  nodes: TNode[];
  edges: Connection[];
  elkOptions: ElkLayoutOptions;
  options: {
    additionalVerticalNodeSpacing: number;
    useMaxWidthForEachNode: boolean;
    useMaxHeightForEachNode: boolean;
  };
};

export async function elkGeneric<TNode extends Id & Size>(props: ELKProps<TNode>): Promise<(TNode & Pos)[]> {
  const { nodes, edges, options, elkOptions } = props;
  const maxWidth = Math.max(...nodes.map((n) => n.width ?? 0));
  const maxHeight = Math.max(...nodes.map((n) => n.height ?? 0));

  const elk = new Elk();
  let graph: ElkNode = {
    id: 'root',
    layoutOptions: elkOptions,
    children: nodes.map((n) => ({
      id: n.id,
      width: options.useMaxWidthForEachNode ? maxWidth : n.width ?? 100,
      height: (options.useMaxHeightForEachNode ? maxHeight : n.height ?? 50) + options.additionalVerticalNodeSpacing,
    })),
    edges: edges.map((edge) => ({
      id: `${edge.source} - ${edge.target}`,
      sources: [edge.source],
      targets: [edge.target],
    })),
  };
  graph = await elk.layout(graph);

  const nodeMap: Record<string, TNode> = Object.fromEntries(nodes.map((node) => [node.id, node]));
  return (graph.children ?? []).map((node) => {
    return {
      ...nodeMap[node.id],
      position: {
        x: (node.x ?? 0) + (node.width ?? 100) / 2,
        y: (node.y ?? 0) + (node.height ?? 50) / 2,
      },
    };
  });
}

type ELKLayerDownProps<TNode extends Id & Size> = {
  nodes: TNode[];
  edges: Connection[];
};

export async function elkLayerDown<TNode extends Id & Size>(props: ELKLayerDownProps<TNode>): Promise<(TNode & Pos)[]> {
  return elkGeneric({
    ...props,
    options: {
      additionalVerticalNodeSpacing: 20,
      useMaxWidthForEachNode: false,
      useMaxHeightForEachNode: true,
    },
    elkOptions: {
      'elk.algorithm': 'layered',
      'elk.direction': 'DOWN',
      'elk.layered.considerModelOrder.strategy': 'PREFER_EDGES',
      'elk.layered.nodePlacement.bk.edgeStraightening': 'IMPROVE_STRAIGHTNESS',
      'elk.layered.nodePlacement.bk.fixedAlignment': 'BALANCED',
      'elk.layered.nodePlacement.strategy': 'BRANDES_KOEPF',
      'elk.layered.mergeEdges': 'true',
      'spacing.baseValue': '20',
    },
  });
}

type DagreProps<TNode extends Id & Size> = {
  nodes: TNode[];
  edges: Connection[];
  options: Record<string, string>;
};

export async function dagreGeneric<TNode extends Id & Size>(props: DagreProps<TNode>): Promise<(TNode & Pos)[]> {
  const { nodes, edges, options } = props;

  const graph = new Dagre.graphlib.Graph();
  graph.setGraph(options);
  graph.setDefaultEdgeLabel(() => ({}));
  nodes.forEach((node) =>
    graph.setNode(node.id, {
      label: node.id,
      width: node.width ?? 100,
      height: node.height ?? 50,
    }),
  );
  edges.forEach((edge) => graph.setEdge(edge.source, edge.target));
  await Dagre.layout(graph);

  const nodeMap: Record<string, TNode> = Object.fromEntries(nodes.map((node) => [node.id, node]));
  return graph.nodes().map((id: string) => {
    const node = graph.node(id);
    return {
      ...nodeMap[id],
      position: {
        x: node.x,
        y: node.y,
      },
    };
  });
}

type DagreLayerDownProps<TNode extends Id & Size> = {
  nodes: TNode[];
  edges: Connection[];
};

export async function dagreLayerDown<TNode extends Id & Size>(
  props: DagreLayerDownProps<TNode>,
): Promise<(TNode & Pos)[]> {
  return dagreGeneric({
    ...props,
    options: {
      align: 'UL',
    },
  });
}
