import { createTheme } from '@mui/material';
import { deDE as gridDE, enUS as gridUS } from '@mui/x-data-grid';
import { deDE, enUS } from '@mui/material/locale';

declare module '@mui/material/styles' {
  interface Palette {
    newColor: string;
  }
  interface PaletteOptions {
    newColor: string;
  }
}

// Colors are originally defined in styles/_variables.scss
const colorPalette = {
  primary: {
    main: '#0a2a46',
  },
  secondary: {
    main: '#7a838c',
  },
  info: {
    main: '#64c3d2',
  },
  newColor: '#00ff00',
  /* success: {
    main: '#c4e8bf',
  }, */
};

const overridenComponents = {
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: '14px',
        backgroundColor: colorPalette.primary.main,
      },
    },
  },
};

export const muiThemeEnglish = createTheme(
  {
    components: overridenComponents,
    palette: colorPalette,
  },
  gridUS,
  enUS,
);

export const muiThemeGerman = createTheme(
  {
    components: overridenComponents,
    palette: colorPalette,
  },
  gridDE,
  deDE,
);
