import 'bootstrap/dist/js/bootstrap';
import './styles/index.scss';
import { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import { Dashboard } from 'pages/Dashboard/DashBoard';
import keycloak from './keycloak';
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <ReactKeycloakProvider initOptions={{ onLoad: 'login-required' }} authClient={keycloak}>
      <Suspense fallback='...is loading'>
        <StrictMode>
          <Routes>
            <Route path='/*' element={<Dashboard />} />
          </Routes>
        </StrictMode>
      </Suspense>
    </ReactKeycloakProvider>
  </BrowserRouter>,
);
