import { Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseIcon } from 'resources/assets/icons/close.svg';
import { ReactComponent as MaximizeIcon } from 'resources/assets/icons/maximize-button.svg';
import { ReactComponent as MinimizeIcon } from 'resources/assets/icons/minimize-button.svg';

export enum DisplayMode {
  DEFAULT,
  MINIMIZED,
  MAXIMIZED,
}

type CollapsableAreaProps = {
  title: string;
  content?: JSX.Element;
  displayMode?: DisplayMode;
  onDisplayModeChanged?: (displayMode: DisplayMode) => void;
};
export const CollapsableArea = ({
  title,
  content,
  displayMode: initialDisplayMode = DisplayMode.DEFAULT,
  onDisplayModeChanged,
}: CollapsableAreaProps): JSX.Element => {
  const { t } = useTranslation();

  const [displayMode, setDisplayMode] = useState(initialDisplayMode);
  useEffect(() => {
    setDisplayMode(initialDisplayMode);
  }, [initialDisplayMode]);

  const updateDisplayMode = (mode: DisplayMode) => {
    setDisplayMode(mode);
    if (onDisplayModeChanged) onDisplayModeChanged(mode);
  };

  if (displayMode === DisplayMode.MINIMIZED)
    return (
      <div className='collapsable-area'>
        <span className='collapsable-area--header'>
          <span>
            <Tooltip title={t('verticalTabs.minimizeTooltip')}>
              <MinimizeIcon onClick={() => updateDisplayMode(DisplayMode.DEFAULT)} />
            </Tooltip>
          </span>
        </span>
      </div>
    );

  return (
    <div className='collapsable-area'>
      <span className='collapsable-area--header'>
        {title}
        <span>
          {displayMode === DisplayMode.MAXIMIZED ? (
            <Tooltip title={t('verticalTabs.minimizeTooltip')}>
              <MinimizeIcon onClick={() => updateDisplayMode(DisplayMode.DEFAULT)} />
            </Tooltip>
          ) : (
            <Tooltip title={t('verticalTabs.maximizeTooltip')}>
              <MaximizeIcon onClick={() => updateDisplayMode(DisplayMode.MAXIMIZED)} />
            </Tooltip>
          )}
          <Tooltip title={t('verticalTabs.closeTooltip')}>
            <CloseIcon onClick={() => updateDisplayMode(DisplayMode.MINIMIZED)} />
          </Tooltip>
        </span>
      </span>
      <div className='collapsable-area--content'>{content}</div>
    </div>
  );
};
