import { Graph } from 'components/ModelTabs/Graph';
import { Model } from 'components/ModelTabs/Model';
import {
  getNodeParents,
  getNodesElligibleForMechanism,
  updateDateAndVersion,
  useRerender,
} from 'components/Utils/funcs';
import { getNodeAbbr } from 'components/Utils/Abbreviations';
import { createDefaultMechanismCode } from 'components/MechanismTabs/MechanismUtils';
import { CausalGraph, CausalModel, Node as CausalNode, Mechanism } from 'components/openapi';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Radio } from 'components/GraphView/AddNodeDialog';
import Latex from 'react-latex';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import { Collapse, Alert, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setMechanismIcons, CustomIcons, setDistributionIcons } from 'components/GraphView/MechDistIcons';
import { useTranslation } from 'react-i18next';
import { NodeIterationDisplay } from './NodeIterationDisplay';

const READONLY_LINES_NUM = 5;

enum Datatype {
  REALWORLD,
  SIMULATED,
  NO_DATA,
}

enum FunctionType {
  CONCRETE_VALUES,
  PLACEHOLDERS,
  CODE,
}

type SingleMechInput = {
  hasMechanism?: boolean;
  functionHasConcreteVals?: FunctionType;
  mechanismInput?: string;
  datatype?: Datatype;
  codeInput?: string;
};

type ModelToMechInputs = Record<string, Record<number, SingleMechInput>>;

const mechanismsInput: ModelToMechInputs = {};
const editorValues: Record<string, Record<number, string>> = {};

const createDefaultMechanismInput = (node: CausalNode, model: CausalModel, graph: CausalGraph): SingleMechInput => {
  const existingMechanism = Array.from(model.mechanisms).find((mech) => mech.node === node.id);
  if (existingMechanism === undefined) {
    const existingDataInfo = Array.from(graph.nodes).find((otherNode) => otherNode.id === node.id)?.data;
    if (existingDataInfo === false) {
      return { hasMechanism: false, datatype: Datatype.NO_DATA };
    }
    // TODO: Update based on the managing of data field (can a node have data when it has a mechanism?)
    return {
      hasMechanism: true,
      functionHasConcreteVals: FunctionType.CODE,
      codeInput: createDefaultMechanismCode(node, graph),
    };
  }
  if (existingMechanism.code !== undefined && existingMechanism.code !== '') {
    return {
      hasMechanism: true,
      functionHasConcreteVals: FunctionType.CODE,
      mechanismInput: existingMechanism.formula,
      codeInput: existingMechanism.code,
    };
  }

  // TODO: These values should be changed as well as datatype
  return {
    hasMechanism: true,
    functionHasConcreteVals: FunctionType.CONCRETE_VALUES,
    mechanismInput: existingMechanism.formula,
  };
};

const getReadOnlyLines = (graph: CausalGraph, node: CausalNode, readOnlyLines: number): string => {
  let prefixLines = ['import numpy as np', 'import numpy.typing as npt', 'from scipy import constants', '', ''];
  if (prefixLines.length > readOnlyLines) {
    prefixLines[readOnlyLines - 1] = prefixLines.slice(readOnlyLines - 1).join('; ');
    prefixLines = prefixLines.slice(0, readOnlyLines);
  }
  while (prefixLines.length < readOnlyLines) prefixLines.push('    ');
  prefixLines.push('');
  return prefixLines.join('\n');
};

export const MechanismTab = ({
  model,
  graph,
  nodeId,
  update,
  setNodeIcons,
  onNextSection: nextSection,
  openDistTab,
  onNodeSelect: selectNode,
}: {
  model: Model;
  graph: Graph;
  update?: number;
  nodeId?: number;
  setNodeIcons: Dispatch<SetStateAction<Record<string, CustomIcons>>>;
  onNextSection: () => void;
  openDistTab: () => void;
  onNodeSelect: (id?: number) => void;
}): JSX.Element => {
  const { t } = useTranslation();

  const rerender = useRerender();

  // Will iterate over nodes that have at least one endogene parent node
  const mechNodes = getNodesElligibleForMechanism(graph.state());
  const [selectedNodeIndex, setSelectedNodeIndex] = useState<number | undefined>(undefined);
  const [alert, setAlert] = useState<{ node: string; opened: boolean }>({ node: '', opened: false });

  if (mechanismsInput[model.state().id] === undefined) {
    mechanismsInput[model.state().id] = {};
  }

  // Input initialization
  mechNodes.forEach((node) => {
    if (mechanismsInput[model.state().id][node.id] === undefined) {
      mechanismsInput[model.state().id][node.id] = createDefaultMechanismInput(node, model.state(), graph.state());
    }
  });

  // Remove input nodes if they are removed through the editor
  const nodesToRemove = Object.keys(mechanismsInput[model.state().id]).filter(
    (id) => !mechNodes.map((node) => node.id).includes(Number(id)),
  );
  nodesToRemove.forEach((id) => {
    delete mechanismsInput[model.state().id][id];
  });

  const getParentAbbrsFromNodeIndex = (nodeIndex, onlyEndogene = true): string[] => {
    if (mechNodes[nodeIndex] === undefined) return [];
    return getNodeParents(mechNodes[nodeIndex], graph.state())
      .filter((node) => node.kind === (onlyEndogene ? 'endogene' : 'exogene'))
      .map((node) => getNodeAbbr(node));
  };
  const getNodeInput = (index?: number): SingleMechInput | undefined => {
    const nodeIndex = index ?? selectedNodeIndex;
    if (nodeIndex === undefined) return undefined;
    return mechanismsInput[model.state().id][mechNodes[nodeIndex]?.id] ?? undefined;
  };
  const setNodeInput = (property: string, value: string | boolean | FunctionType | Datatype) => {
    if (selectedNodeIndex === undefined || property === undefined) return;
    const currentInput = mechanismsInput[model.state().id][mechNodes[selectedNodeIndex]?.id] ?? undefined;
    if (currentInput === undefined) return;

    currentInput[property] = value;
    rerender();
  };

  const hasAllData = (index?): boolean => {
    const input = getNodeInput(index);
    if (input === undefined || input.hasMechanism === undefined) return false;
    return (
      (input.hasMechanism &&
        (input.functionHasConcreteVals === FunctionType.CONCRETE_VALUES ||
          input.functionHasConcreteVals === FunctionType.PLACEHOLDERS) &&
        (input.mechanismInput ?? '') !== '') ||
      (input.hasMechanism && input.functionHasConcreteVals === FunctionType.CODE && (input.codeInput ?? '') !== '') ||
      (!input.hasMechanism && input.datatype !== undefined)
    );
  };

  const refreshNodeIcons = (): void => {
    setDistributionIcons(model, graph, setNodeIcons);
    setMechanismIcons(model, graph, setNodeIcons);
  };

  const setDefaultEditorValues = (): Record<number, string> => {
    const newEditorValues = {
      ...Object.fromEntries(mechNodes.map((node) => [node.id, ''])),
      ...editorValues[model.state().id],
    };
    mechNodes.forEach((node, nodeIndex) => {
      if (newEditorValues[node.id] === undefined || newEditorValues[node.id] === '') {
        const userInputWithoutHeader = getNodeInput(nodeIndex)?.codeInput;
        const savedString = userInputWithoutHeader ?? createDefaultMechanismCode(node, graph.state());
        newEditorValues[node.id] = getReadOnlyLines(graph.state(), node, READONLY_LINES_NUM) + savedString;
      }
    });
    return newEditorValues;
  };

  const onLoadAceEditor = (editor) => {
    editor.commands.on('exec', (e) => {
      if (e.command.readOnly) return;
      // const editableRow = editor.session.getLength() - 1;
      const deletesLeft = e.command.name === 'backspace' || e.command.name === 'removewordleft';

      const notEditable = editor.selection.getAllRanges().some((r) => {
        if (deletesLeft && r.start.column === 0 && r.end.column === 0 && r.start.row < READONLY_LINES_NUM + 1)
          return true;
        return r.start.row < READONLY_LINES_NUM || r.end.row < READONLY_LINES_NUM;
      });
      if (notEditable) e.preventDefault();
    });
  };
  const onUpdateAceEditor = (updatedValue, node: CausalNode) => {
    const predefinedVariablesStrings = getReadOnlyLines(graph.state(), node, READONLY_LINES_NUM);

    const userValue = updatedValue.split('\n').slice(READONLY_LINES_NUM).join('\n');
    setNodeInput('codeInput', userValue);
    const newEditorValues = { ...(editorValues[model.state().id] ?? {}) };
    newEditorValues[node.id] = predefinedVariablesStrings + userValue;
    editorValues[model.state().id] = newEditorValues;
  };

  const nextNode = () => {
    const newIndex = ((selectedNodeIndex ?? -1) + 1) % mechNodes.length;
    setSelectedNodeIndex(newIndex);
    selectNode(mechNodes[newIndex]?.id);
    setAlert({ node: alert.node, opened: false });
    rerender();
  };
  const previousNode = () => {
    const newIndex = (selectedNodeIndex ?? 1) - 1 < 0 ? mechNodes.length - 1 : (selectedNodeIndex ?? 1) - 1;
    setSelectedNodeIndex(newIndex);
    selectNode(mechNodes[newIndex]?.id);
    setAlert({ node: alert.node, opened: false });
    rerender();
  };
  const goForward = () => {
    setSelectedNodeIndex(mechNodes.length - 1);
    selectNode(mechNodes[mechNodes.length - 1]?.id);
    setAlert({ node: alert.node, opened: false });
    rerender();
  };

  const setModelChanges = (
    inputNodeIndex: number,
    inputNodeId: number,
    currentModel: CausalModel,
  ): { modelChanged: boolean; newModel: CausalModel } => {
    const newModelState = currentModel;
    let modelChanged = false;
    const currentMechanisms = Array.from(newModelState.mechanisms);
    let mechanismIndex: number = currentMechanisms.findIndex((mech) => mech.node === inputNodeId);

    if (getNodeInput(inputNodeIndex)?.hasMechanism === false) {
      if (mechanismIndex !== -1) {
        currentMechanisms.splice(mechanismIndex, 1);
        newModelState.mechanisms = new Set(currentMechanisms);
        modelChanged = true;
      }
      return { modelChanged, newModel: newModelState };
    }

    modelChanged = true;
    let newMechanism: Mechanism;
    if (mechanismIndex === -1) {
      newMechanism = {
        node: inputNodeId,
        formula: '',
        code: '',
      };
      mechanismIndex = currentMechanisms.length;
    } else {
      newMechanism = { ...currentMechanisms[mechanismIndex] };
    }

    newMechanism.formula = getNodeInput(inputNodeIndex)?.mechanismInput;
    newMechanism.code = getNodeInput(inputNodeIndex)?.codeInput;
    currentMechanisms[mechanismIndex] = newMechanism;

    newModelState.mechanisms = new Set(currentMechanisms);

    return { modelChanged, newModel: newModelState };
  };

  const setGraphChanges = (
    inputNodeIndex: number,
    inputNodeId: number,
    currentGraph: CausalGraph,
  ): { graphChanged: boolean; newGraph: CausalGraph } => {
    const newGraphState = currentGraph;
    let graphChanged = false;
    if (getNodeInput(inputNodeIndex)?.hasMechanism === false && getNodeInput(inputNodeIndex)?.datatype !== undefined) {
      const currentNodes = Array.from(newGraphState.nodes);
      const newNodeIndex = currentNodes.findIndex((node) => node.id === inputNodeId);
      if (newNodeIndex !== -1) {
        if (
          (getNodeInput(inputNodeIndex)?.datatype === Datatype.REALWORLD ||
            getNodeInput(inputNodeIndex)?.datatype === Datatype.SIMULATED) &&
          currentNodes[newNodeIndex].data !== true
        ) {
          graphChanged = true;
          currentNodes[newNodeIndex].data = true;
        } else if (
          getNodeInput(inputNodeIndex)?.datatype === Datatype.NO_DATA &&
          currentNodes[newNodeIndex].data !== false
        ) {
          graphChanged = true;
          currentNodes[newNodeIndex].data = false;
        }
        newGraphState.nodes = new Set<CausalNode>(currentNodes);
      }
    }
    return { graphChanged, newGraph: newGraphState };
  };

  const handleSubmit = async (event?, nodeIndex?: number): Promise<void> => {
    const currentNodeIndex = nodeIndex ?? selectedNodeIndex;
    event?.preventDefault();
    if (!hasAllData() || currentNodeIndex === undefined) return;
    const inputNodeId = mechNodes[currentNodeIndex]?.id;
    if (inputNodeId === undefined) return;

    const { modelChanged, newModel } = setModelChanges(currentNodeIndex, inputNodeId, { ...model.state() });
    const { graphChanged, newGraph } = setGraphChanges(currentNodeIndex, inputNodeId, { ...graph.state() });
    if (modelChanged || graphChanged) {
      model.setState(updateDateAndVersion(newModel));
      graph.setState(updateDateAndVersion(newGraph), graphChanged ? [] : ['GraphView']);

      if (await graph.save()) model.save();
    }
    refreshNodeIcons();
  };

  const handleAllRemainingSubmit = async (): Promise<void> => {
    let newModelState = { ...model.state() };
    let newGraphState = { ...graph.state() };
    let graphUpdated = false;
    mechNodes.forEach((node, index) => {
      if (hasAllData(index)) {
        const { newModel } = setModelChanges(index, node.id, { ...newModelState });
        newModelState = newModel;
        const { graphChanged, newGraph } = setGraphChanges(index, node.id, { ...newGraphState });
        graphUpdated = graphUpdated || graphChanged;
        newGraphState = newGraph;
      }
    });

    model.setState(updateDateAndVersion(newModelState));
    graph.setState(updateDateAndVersion(newGraphState), graphUpdated ? [] : ['GraphView']);
    refreshNodeIcons();
    if (await graph.save()) model.save();
    goForward();
  };

  useEffect(() => {
    if (
      (selectedNodeIndex === undefined || selectedNodeIndex >= mechNodes.length) &&
      (nodeId === undefined || !mechNodes.map((node) => node.id).includes(nodeId)) &&
      mechNodes.length > 0
    ) {
      setSelectedNodeIndex(0);
    }
  }, [mechNodes]);

  useEffect(() => {
    if (update !== undefined && selectedNodeIndex !== undefined) {
      selectNode(mechNodes[selectedNodeIndex]?.id);
    }
  }, [update]);

  useEffect(() => {
    setAlert({ node: alert.node, opened: false });
    if (nodeId !== undefined) {
      if (mechNodes.map((node) => node.id).includes(nodeId)) {
        const newIndex = mechNodes.indexOf(mechNodes.find((node) => node.id === nodeId) ?? mechNodes[0]);
        if (newIndex !== selectedNodeIndex) {
          setSelectedNodeIndex(newIndex);
        }
      } else {
        const selectedNode = Array.from(graph.state().nodes).find((node) => node.id === nodeId);
        openDistTab();
        if (selectedNode) setAlert({ node: selectedNode.name, opened: true });
      }
    }
  }, [nodeId]);
  if (mechNodes.length === 0 && selectedNodeIndex !== undefined) setSelectedNodeIndex(undefined);

  if (
    editorValues[model.state().id] === undefined ||
    Object.keys(editorValues[model.state().id]).filter((key) => editorValues[model.state().id][key] !== '').length <
      mechNodes.length
  ) {
    editorValues[model.state().id] = setDefaultEditorValues();
  }

  return (
    <div className='mechanism-tab-container'>
      {selectedNodeIndex === undefined && <div>{t('mechanismTab.noAvailableNodes')}</div>}

      {selectedNodeIndex !== undefined && (
        <div>
          <NodeIterationDisplay
            previousButtonAction={() => {
              handleSubmit();
              previousNode();
            }}
            nodeOnClickAction={() => selectNode(mechNodes[selectedNodeIndex ?? 0]?.id)}
            nextButtonAction={() => {
              handleSubmit();
              nextNode();
            }}
            forwardButtonAction={handleAllRemainingSubmit}
            allNodes={mechNodes}
            currentNodeIndex={selectedNodeIndex}
            forwardButtonTitle={t('mechanismTab.forwardButtonTitle')}
          />

          <Collapse in={alert.opened}>
            <Alert
              action={
                <IconButton
                  aria-label='close'
                  color='inherit'
                  size='small'
                  onClick={() => {
                    setAlert({ opened: false, node: alert.node });
                  }}
                >
                  <CloseIcon fontSize='inherit' />
                </IconButton>
              }
              severity='info'
              sx={{ mb: 2, mt: 2 }}
            >
              {t('mechanismTab.alertFirstHalf')}
              <strong>{alert.node}</strong>
              {t('mechanismTab.alertSecondHalf')}
            </Alert>
          </Collapse>

          <div className='mechanism-form'>
            <form
              onSubmit={(event) => {
                handleSubmit(event);
                nextNode();
              }}
            >
              {/* First dispatch (has mechanism / no mechanism) */}
              <p>{t('mechanismTab.labelFunctionKnownQuestion')}</p>
              <div className='addNodeDialog__attribute-wrapper'>
                <Radio
                  onClick={() => {
                    setNodeInput('hasMechanism', true);
                  }}
                  enabled={getNodeInput()?.hasMechanism === true}
                  label={t('mechanismTab.labelFunctionKnownTrue')}
                />
                <Radio
                  onClick={() => {
                    setNodeInput('hasMechanism', false);
                  }}
                  enabled={getNodeInput()?.hasMechanism === false}
                  label={t('mechanismTab.labelFunctionKnownFalse')}
                />
              </div>

              {/* Mechanism Type (with concrete values / with placeholders) */}
              <div className={getNodeInput()?.hasMechanism ? '' : 'd-none'}>
                <div className='mech-tip-container'>
                  <span className='inline-node react-flow__node-MultiPortNode nopan'>
                    <Latex>{t('mechanismTab.tipNode1Name')}</Latex>
                  </span>
                  <svg>
                    <g className='react-flow__edge-path react-flow__edge-default nopan selected'>
                      <path
                        className='react-flow__edge-path'
                        markerEnd='url(#arrowclosed-selected)'
                        markerStart='url(#)'
                        d='M0,25   75,25'
                      />
                    </g>
                  </svg>
                  <span className='inline-node react-flow__node-MultiPortNode nopan'>
                    <Latex>{t('mechanismTab.tipNode2Name')}</Latex>
                  </span>

                  <br />
                  <div className='equation-grid'>
                    <Latex displayMode>{t('mechanismTab.tipEquation1')}</Latex>
                    <div className='my-auto ml-2'>{t('mechanismTab.tipEq1Description')}</div>

                    <Latex displayMode>{t('mechanismTab.tipEquation2')}</Latex>
                    <div className='my-auto ml-2'>{t('mechanismTab.tipEq2Description')}</div>
                  </div>
                </div>

                <p>{t('mechanismTab.labelFuncTypeQuestion')}</p>
                <Radio
                  onClick={() => {
                    setNodeInput('functionHasConcreteVals', FunctionType.CONCRETE_VALUES);
                  }}
                  enabled={getNodeInput()?.functionHasConcreteVals === FunctionType.CONCRETE_VALUES}
                  label={t('mechanismTab.labelFuncTypeConcrete')}
                />
                <Radio
                  onClick={() => {
                    setNodeInput('functionHasConcreteVals', FunctionType.PLACEHOLDERS);
                  }}
                  enabled={getNodeInput()?.functionHasConcreteVals === FunctionType.PLACEHOLDERS}
                  label={t('mechanismTab.labelFuncTypePlaceholders')}
                />
                <Radio
                  onClick={() => {
                    setNodeInput('functionHasConcreteVals', FunctionType.CODE);
                  }}
                  enabled={getNodeInput()?.functionHasConcreteVals === FunctionType.CODE}
                  label={t('mechanismTab.labelFuncTypeCode')}
                />

                <div className={getNodeInput()?.functionHasConcreteVals !== undefined ? '' : 'd-none'}>
                  <p className='text-muted'>{t('mechanismTab.headline')}</p>
                  {t('mechanismTab.mechanismQuestion')}
                  <br />
                  {`${
                    mechNodes[selectedNodeIndex] !== undefined ? getNodeAbbr(mechNodes[selectedNodeIndex]) : ''
                  } = f(${[
                    ...getParentAbbrsFromNodeIndex(selectedNodeIndex, true),
                    ...getParentAbbrsFromNodeIndex(selectedNodeIndex, false),
                  ].join(', ')})`}
                  <br />
                  <br />

                  <span className={getNodeInput()?.functionHasConcreteVals !== FunctionType.CODE ? '' : 'd-none'}>
                    <label htmlFor='mechText'>
                      {`${
                        mechNodes[selectedNodeIndex] !== undefined ? getNodeAbbr(mechNodes[selectedNodeIndex]) : ''
                      } = f(`}
                    </label>
                    <input
                      type='text'
                      id='mechText'
                      onChange={(event) => {
                        setNodeInput('mechanismInput', event.target.value);
                      }}
                      placeholder={[...getParentAbbrsFromNodeIndex(selectedNodeIndex, true)].join(' + ')}
                      value={getNodeInput()?.mechanismInput ?? ''}
                    />
                    <label htmlFor='mechText'>
                      {` + ${[...getParentAbbrsFromNodeIndex(selectedNodeIndex, false)].join(' + ')})`}
                    </label>
                  </span>

                  {mechNodes.map((node, index) => {
                    return (
                      <div
                        key={`div${node.id}`}
                        className={`ace-editor ${
                          index === selectedNodeIndex && getNodeInput()?.functionHasConcreteVals === FunctionType.CODE
                            ? ' d-block '
                            : ' d-none '
                        }`}
                      >
                        <button
                          type='button'
                          className='addNodeDialog__add-button'
                          onClick={() => {
                            const newEditorVal =
                              getReadOnlyLines(graph.state(), node, READONLY_LINES_NUM) +
                              createDefaultMechanismCode(node, graph.state());
                            onUpdateAceEditor(newEditorVal, node);
                          }}
                        >
                          {t('mechanismTab.defaultMechButtonTitle')}
                        </button>
                        <AceEditor
                          key={node.id}
                          width='90%'
                          height='400px'
                          mode='python'
                          theme='monokai' // 'github'
                          name={`mechEditor${index.toString()}`}
                          onLoad={(editor) => onLoadAceEditor(editor)}
                          onChange={(val) => onUpdateAceEditor(val, node)}
                          fontSize={14}
                          showPrintMargin
                          showGutter
                          highlightActiveLine
                          value={(editorValues[model.state().id] ?? {})[node.id]}
                          setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: true,
                            enableSnippets: true,
                            showLineNumbers: true,
                            tabSize: 4,
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* Type of data (Real-world / simulated / no data) */}
              <div className={getNodeInput()?.hasMechanism === false ? '' : 'd-none'}>
                <p>{t('mechanismTab.datatypeQuestion')}</p>
                <Radio
                  onClick={() => {
                    setNodeInput('datatype', Datatype.REALWORLD);
                  }}
                  enabled={getNodeInput()?.datatype === Datatype.REALWORLD}
                  label={t('mechanismTab.datatypeRealworld')}
                />
                <Radio
                  onClick={() => {
                    setNodeInput('datatype', Datatype.SIMULATED);
                  }}
                  enabled={getNodeInput()?.datatype === Datatype.SIMULATED}
                  label={t('mechanismTab.datatypeSimulated')}
                />
                <Radio
                  onClick={() => {
                    setNodeInput('datatype', Datatype.NO_DATA);
                  }}
                  enabled={getNodeInput()?.datatype === Datatype.NO_DATA}
                  label={t('mechanismTab.datatypeNodata')}
                />
              </div>
              <br />

              <button
                type='submit'
                disabled={!hasAllData()}
                className={hasAllData() ? 'addNodeDialog__add-button' : 'addNodeDialog__add-button disabled text-muted'}
              >
                {t('mechanismTab.buttonNextNode')}
              </button>
            </form>
          </div>
          {model.state().mechanisms.size === mechNodes.length && (
            <div>
              <br />
              <button
                type='button'
                className='addNodeDialog__add-button'
                onClick={async () => {
                  if (await graph.save()) model.save();
                  nextSection();
                }}
              >
                {t('mechanismTab.buttonOpenInference')}
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
