import { Node as CausalNode, CausalGraph } from 'components/openapi';
import { Node as FlowNode } from 'reactflow';

const PYTHON_KEYWORDS = [
  'False',
  'await',
  'else',
  'import',
  'pass',
  'None',
  'break',
  'except',
  'in',
  'raise',
  'True',
  'class',
  'finally',
  'is',
  'return',
  'and',
  'continue',
  'for',
  'lambda',
  'try',
  'as',
  'def',
  'from',
  'nonlocal',
  'while',
  'assert',
  'del',
  'global',
  'not',
  'with',
  'async',
  'elif',
  'if',
  'or',
  'yield',
];

const PYTHON_MODULES_KEYWORDS = ['math', 'pd', 'np', 'npt'];

export const createAbbrFromName = (uneditedAbbr: string): string => {
  // Returns abbr in UPPER_CASE (for backend)
  // const parenthesisRegex = /\(([a-zA-Z_]+)\)/;
  if (uneditedAbbr.toUpperCase().startsWith('U_')) return uneditedAbbr.replace(/[^a-zA-Z0-9_]/g, '').toUpperCase();
  return uneditedAbbr.replace(/[^a-zA-Z0-9]/g, '').toUpperCase();
};

export const getNodeAbbr = (node: CausalNode): string => {
  // Returns abbr in lower_case (for editor)
  if (node.abbr !== undefined && node.abbr !== '') return node.abbr.toLowerCase();
  return node.name.toLowerCase();
};

export const findNodeAbbrsInString = (str: string, graph: CausalGraph): CausalNode[] => {
  // For parsing query or python code
  return Array.from(graph.nodes).filter((node) => str.includes(getNodeAbbr(node)));
};

export const createNameForExogeneParent = (endogene: FlowNode, causalGraph: CausalGraph): string => {
  let newBackgroundName = `U_${endogene.data.abbr !== '' ? endogene.data.abbr : endogene.data.name}`;
  const currentGraphNodes = Array.from(causalGraph.nodes ?? []).filter((node) => node.id !== Number(endogene.id));
  const usedPythonSymbols = currentGraphNodes.map((node: CausalNode) => {
    if (node.abbr !== undefined && node.abbr !== '') return node.abbr.toLowerCase();
    return node.name.toLowerCase();
  });

  while (usedPythonSymbols.includes(newBackgroundName.toLowerCase()))
    newBackgroundName = newBackgroundName.replace(/\d+$/, '') + (Number(newBackgroundName.match(/\d+$/)?.[0] ?? 1) + 1);
  return newBackgroundName;
};

export const abbrIsValid = (abbr: string): { valid: boolean; error: string } => {
  if (abbr.match(/^\d/)) return { valid: false, error: 'addNode.errorStartsWithNumber' };
  if (abbr.replace(/^(U_)/, '').match(/[^A-Z0-9]/))
    return { valid: false, error: 'addNode.errorAbbrHasSpecialSymbols' };

  if (PYTHON_KEYWORDS.includes(abbr.toLowerCase())) return { valid: false, error: 'addNode.errorPythonKeyword' };

  if (PYTHON_MODULES_KEYWORDS.includes(abbr.toLowerCase())) return { valid: false, error: 'addNode.errorPythonModule' };
  return { valid: true, error: '' };
};
