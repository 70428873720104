import { Model } from 'components/ModelTabs/Model';
import { EventHandler } from 'components/Utils/EventHandler';
import { openAlertDialog } from 'components/Utils/funcs';
import { Graph } from 'components/ModelTabs/Graph';
import { makeEmptyCausalGraph } from 'components/Utils/emptyModelGraph';
import { WorkflowPage } from '../Utils/WorkflowContext';

type EventType =
  | 'onModelRemoved'
  | 'onModelAdded'
  | 'onActiveModelChanged'
  | 'onGraphRemoved'
  | 'onGraphAdded'
  | 'onActiveGraphChanged';

class ModelManager {
  models: Model[];

  graphs: Graph[];

  private activeModel: Map<WorkflowPage, Model | undefined>;

  private activeGraph: Map<WorkflowPage, Graph | undefined>;

  private eventHandler: EventHandler<EventType>;

  constructor() {
    this.models = [];
    this.graphs = [];
    this.activeModel = new Map<WorkflowPage, Model | undefined>();
    this.activeGraph = new Map<WorkflowPage, Graph | undefined>();
    this.eventHandler = new EventHandler();
  }

  setListener(id: string, type: EventType | undefined, callback: () => void): void {
    this.eventHandler.setListener(id, type, callback);
  }

  addModel(model: Model, skipListeners: string[] = []): boolean {
    const { id } = model.state();
    const modelAlreadyLoaded = this.models.find((m) => m.state().id === id);
    if (modelAlreadyLoaded) {
      const workflows = modelAlreadyLoaded.getWorkflows().filter((workflow) => model.getWorkflows().includes(workflow));
      if (workflows.length > 0) {
        openAlertDialog(`Model ${id} already loaded!`);
        return false;
      }
      model.getWorkflows().forEach((workflow) => modelAlreadyLoaded.makeVisibleInWorkflow(workflow));
    } else {
      this.models.push(model);
      this.eventHandler.callEventListeners('onModelAdded', skipListeners);
    }
    return true;
  }

  removeModel(workflow: WorkflowPage, id?: string, skipListeners: string[] = []) {
    const modelsVisibleInWorkflow = this.models.filter((model) => model.isVisibleInWorkflow(workflow));
    const modelToRemove = modelsVisibleInWorkflow.find((m) => m.state().id === id);
    const workflows = modelToRemove?.getWorkflows();
    if (workflows !== undefined && workflows.length > 1) {
      modelToRemove?.makeUnvisibleInWorkflow(workflow);
    } else if (workflows !== undefined && workflows.length === 1) {
      this.models = this.models.filter((model) => model.state().id !== id);
    }
    if (this.activeModel.get(workflow)?.state().id === id) {
      const deletedModelIdx = modelsVisibleInWorkflow.findIndex((model) => model.state().id === id);
      this.setModelAsActive(
        workflow,
        modelsVisibleInWorkflow.at(Math.max(0, deletedModelIdx - 1))?.state().id,
        skipListeners,
      );
    }
    this.eventHandler.callEventListeners('onModelRemoved', skipListeners);
  }

  setModelAsActive(workflow: WorkflowPage, id?: string, skipListeners: string[] = []) {
    this.activeModel.set(
      workflow,
      this.models.find((m) => m.state().id === id),
    );
    this.eventHandler.callEventListeners('onActiveModelChanged', skipListeners);
  }

  getActiveModel(workflow: WorkflowPage): Model | undefined {
    return this.activeModel.get(workflow);
  }

  getModelById(id: string): Model | undefined {
    return this.models.find((model) => model.state().id === id);
  }

  addGraph(graph: Graph, skipListeners: string[] = []): boolean {
    const { id } = graph.state();
    const graphAlreadyLoaded = this.graphs.find((g) => g.state().id === id);
    if (graphAlreadyLoaded) {
      graph.getWorkflows().forEach((workflow) => graphAlreadyLoaded.makeVisibleInWorkflow(workflow));
      return false;
    }
    this.graphs.push(graph);
    this.eventHandler.callEventListeners('onGraphAdded', skipListeners);
    return true;
  }

  removeGraph(workflow: WorkflowPage, id?: string, skipListeners: string[] = []) {
    const graphsVisibleInWorkflow = this.graphs.filter((graph) => graph.isVisibleInWorkflow(workflow));
    const graphToRemove = graphsVisibleInWorkflow.find((graph) => graph.state().id === id);
    const workflows = graphToRemove?.getWorkflows();
    if (workflows !== undefined && workflows.length > 1) {
      graphToRemove?.makeUnvisibleInWorkflow(workflow);
    } else if (workflows !== undefined && workflows.length === 1) {
      this.graphs = this.graphs.filter((graph) => graph.state().id !== id);
    }
    if (this.activeGraph.get(workflow)?.state().id === id) {
      const deletedModelIdx = graphsVisibleInWorkflow.findIndex((model) => model.state().id === id);
      this.setGraphAsActive(
        workflow,
        graphsVisibleInWorkflow.at(Math.max(0, deletedModelIdx - 1))?.state().id,
        skipListeners,
      );
    }
    this.eventHandler.callEventListeners('onGraphRemoved', skipListeners);
  }

  setGraphAsActive(workflow: WorkflowPage, id?: string, skipListeners: string[] = []) {
    this.activeGraph.set(
      workflow,
      this.graphs.find((graph) => graph.state().id === id),
    );
    this.eventHandler.callEventListeners('onActiveGraphChanged', skipListeners);
  }

  getActiveGraph(workflow: WorkflowPage): Graph | undefined {
    return this.activeGraph.get(workflow);
  }

  getGraphCorrespondingToModel(model: Model, workflow: WorkflowPage): Graph {
    let foundGraph = this.graphs.find((graph) => graph.state().id === model.state().causal_graph);
    if (!foundGraph) {
      const causalGraph = makeEmptyCausalGraph();
      causalGraph.id = model.state().causal_graph;
      foundGraph = new Graph(causalGraph, workflow);
      if (model.state().causal_graph === '') {
        const newModelState = model.state();
        model.resetHistOnNextState();
        newModelState.causal_graph = foundGraph.state().id;
        model.setState(newModelState);
      }

      this.addGraph(foundGraph);
    }
    return foundGraph;
  }
}

export const modelManager = new ModelManager();
