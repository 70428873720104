import { CausalGraph, CausalModel } from 'components/openapi';
import JSZip from 'jszip';
import FileSaver from 'file-saver';
import { openSaveAlertDialog } from 'components/Utils/SaveAlertDialog';

export const openExportModelGraphDialog = (model: CausalModel | undefined, graph: CausalGraph | undefined) => {
  if (!model || !graph) {
    return;
  }
  const zip = new JSZip();
  // Cannot directly stringify graph, because it contains Sets
  const graphToExport = graph as object;
  graphToExport['nodes'] = Array.from(graph.nodes);
  graphToExport['links'] = Array.from(graph.links);

  const modelToExport = model as object;
  modelToExport['mechanisms'] = Array.from(model.mechanisms);
  modelToExport['distributions'] = Array.from(model.distributions);

  const modelFile = new Blob([JSON.stringify(modelToExport, null, 2)], { type: 'text/plain' });
  const graphFile = new Blob([JSON.stringify(graphToExport, null, 2)], { type: 'text/plain' });

  const folderName = `${model.name}_${model.version}`;
  zip.file(`${folderName}.causal-model.json`, modelFile);
  zip.file(`${folderName}.causal-graph.json`, graphFile);

  zip.generateAsync({ type: 'blob' }).then((content) => {
    FileSaver.saveAs(content, `${folderName}.zip`);
  });
  openSaveAlertDialog(true);
};
