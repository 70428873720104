import { createContext } from 'react';

export type WorkflowPage = 'CREATION' | 'INFERENCE';

export class Workflow {
  private page: WorkflowPage;

  static GRAPH_CREATION = new Workflow('CREATION');

  static MECHANISM_CREATION = new Workflow('CREATION');

  static CAUSAL_INFERENCE = new Workflow('INFERENCE');

  private constructor(page: WorkflowPage) {
    this.page = page;
  }

  getPage(): WorkflowPage {
    return this.page;
  }
}

export const WorkflowContext = createContext({
  currentWorkflow: Workflow.GRAPH_CREATION,
  setCurrentWorkflow: () => {},
} as { currentWorkflow: Workflow; setCurrentWorkflow: React.Dispatch<React.SetStateAction<Workflow>> });
