import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import DownloadIcon from 'resources/assets/icons/icons8-download.svg';
import ZoomInIcon from 'resources/assets/icons/layout_zoom_in.svg';
import ZoomOutIcon from 'resources/assets/icons/layout_zoom_out.svg';
import { getNodeNameFromId } from 'components/Utils/funcs';
import { CausalGraph, CoCaData } from 'components/openapi';
import { useTranslation } from 'react-i18next';

const Y_LABELS_PRECISION = 2;
const Y_LABELS_IN_TOOLTIP_PREC = 3;
// Height of the bar when value == 0
// Must equal 0 when rounding with precision 'Y_LABELS_IN_TOOLTIP_PREC'
const NO_VALUE_BAR_HEIGTH = 0.00049;

type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar';

type ApexChart = {
  type: ChartType;
  options: ApexOptions;
};

function createApexHistogram(analysis: CoCaData, index: number, graph: CausalGraph): ApexChart {
  const { t } = useTranslation();

  const singleAnalysis = analysis.single; // Multi-analysis not implemented
  const chartTitle = `Corner Case ${index + 1}`;

  const chart: ApexChart = {
    type: 'bar',
    options: {
      series: [
        {
          name: t('ccSideTab.ccAnalysisPlotYLabel'),
          data:
            (singleAnalysis.data[index] as number[]).map((num) => {
              // Ensure, that 0 values are showing as well
              if (num === 0) {
                if (Math.max(...(singleAnalysis.data[index] as number[])) <= 0) {
                  return -NO_VALUE_BAR_HEIGTH;
                }
                return NO_VALUE_BAR_HEIGTH;
              }
              return num;
            }) ?? [],
        },
      ],
      chart: {
        toolbar: {
          tools: {
            download: `<img src=${DownloadIcon}>`,
            zoomin: `<img src=${ZoomInIcon}>`,
            zoomout: `<img src=${ZoomOutIcon}>`,
            selection: false,
            zoom: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      title: {
        text: chartTitle,
        align: 'center',
        margin: 10,
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickPlacement: 'on',
        type: 'category',
        categories: singleAnalysis.columns.map((nodeId) => getNodeNameFromId(graph, nodeId)),
        // min: Math.min(...(histogram.bin_edges || [])),
        // max: Math.max(...(histogram.bin_edges || [])),
        // tickAmount: Math.min(histogram.hist?.length, MAX_NUM_OF_XLABELS),
        // labels: { formatter: (x) => (Number.isInteger(Number(x)) ? x : Number(x).toFixed(X_LABELS_PRECISION)) },
      },
      yaxis: {
        tickAmount: 2,
        min: () => {
          if (
            Math.max(...(singleAnalysis.data[index] as number[])) >= 0 &&
            Math.min(...(singleAnalysis.data[index] as number[])) >= 0
          ) {
            return 0;
          }
          return Math.min(...((singleAnalysis.data[index] as number[]) || []));
        },
        max: () => {
          if (
            Math.max(...(singleAnalysis.data[index] as number[])) <= 0 &&
            Math.min(...(singleAnalysis.data[index] as number[])) <= 0
          ) {
            return 0;
          }
          return Math.max(...((singleAnalysis.data[index] as number[]) || []));
        },
        title: {
          text: t('ccSideTab.ccAnalysisPlotYLabel'),
          style: {
            fontSize: '0.9rem',
            fontWeight: '500',
          },
        },
        labels: {
          formatter: (y) => (Number.isInteger(Number(y)) ? y.toString() : Number(y).toFixed(Y_LABELS_PRECISION)),
        },
      },
      tooltip: {
        y: {
          formatter: (value) => `${value.toFixed(Y_LABELS_IN_TOOLTIP_PREC)}`,
        },
        intersect: false,
      },
      annotations: {
        yaxis: [
          {
            y: 0,
            strokeDashArray: 5,
            borderWidth: 3,
          },
        ],
      },
    },
  };

  return chart;
}

export const ApexHistogram = ({
  analysis,
  CoCaIndex,
  graph,
}: {
  analysis: CoCaData;
  CoCaIndex: number;
  graph: CausalGraph;
}): JSX.Element => {
  const chart = createApexHistogram(analysis, CoCaIndex, graph);
  return <ReactApexChart options={chart?.options} series={chart.options?.series} type={chart?.type} height='500' />;
};
