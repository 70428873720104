import { memo } from 'react';

export const Markers = memo(function Markers(): JSX.Element {
  return (
    <svg style={{ position: 'absolute', top: '-100%', left: '-100%', opacity: '0' }}>
      <defs>
        <marker
          className='react-flow__arrowhead'
          id='arrowclosed-default'
          markerWidth='12.5'
          markerHeight='12.5'
          viewBox='-10 -10 20 20'
          markerUnits='strokeWidth'
          orient='auto-start-reverse'
          refX='-2'
          refY='0'
        >
          <polyline
            stroke='#b1b1b7'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
            fill='#b1b1b7'
            points='-5,-4 0,0 -5,4 -5,-4'
          />
        </marker>
        <marker
          className='react-flow__arrowhead'
          id='arrowclosed-selected'
          markerWidth='12.5'
          markerHeight='12.5'
          viewBox='-10 -10 20 20'
          markerUnits='strokeWidth'
          orient='auto-start-reverse'
          refX='-2'
          refY='0'
        >
          <polyline
            stroke='#8e0f58'
            strokeLinecap='round'
            strokeLinejoin='round'
            strokeWidth='1'
            fill='#8e0f58'
            points='-5,-4 0,0 -5,4 -5,-4'
          />
        </marker>
      </defs>
    </svg>
  );
});
