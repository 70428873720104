import { InformationEntry, InformationEntryWithNode } from 'components/SideInformation/common';
import { CausalGraph, NodeKindEnum } from 'components/openapi';
import { logToConsole, formatLatex } from 'components/Utils/funcs';
import { Graph } from 'components/ModelTabs/Graph';
import Latex from 'react-latex';
import { useTranslation } from 'react-i18next';

const findDirectRelativesOfNode = (
  graph: CausalGraph,
  selectedNodeId: number,
): { parents: string[]; background: string[]; children: string[] } => {
  const AncestorPool = Array.from(graph.links)
    .filter((link) => link.to === selectedNodeId)
    .map((link) => link.from);
  const ChildrenPool = Array.from(graph.links)
    .filter((link) => link.from === selectedNodeId)
    .map((link) => link.to);
  const parents = Array.from(graph.nodes)
    .filter((node) => AncestorPool.includes(node.id) && node.kind === 'endogene')
    .map((node) => node.name);
  const background = Array.from(graph.nodes)
    .filter((node) => AncestorPool.includes(node.id) && node.kind === 'exogene')
    .map((node) => node.name);
  const children = Array.from(graph.nodes)
    .filter((node) => ChildrenPool.includes(node.id))
    .map((node) => node.name);

  return { parents, background, children };
};

export const NodeInformation = ({
  graph,
  nodeId,
  highlightNodes,
  setHighlightNodes,
}: {
  graph: Graph;
  nodeId: number;
  highlightNodes: boolean;
  setHighlightNodes: React.Dispatch<React.SetStateAction<boolean>>;
}): JSX.Element => {
  const { t } = useTranslation();
  const causalGraph = graph.state();
  const currentNode = Array.from(causalGraph.nodes).find((node) => nodeId === node.id);
  const directRelativesOfNode = findDirectRelativesOfNode(causalGraph, nodeId);
  const choseType = (valueType: string) => {
    return valueType === 'continuous' ? t('nodeInfo.continousVariable') : t('nodeInfo.discreteVariable');
  };
  const currentNodeType = currentNode?.value_type ? choseType(currentNode.value_type) : '-';
  const currentNodeRange = (
    <>
      {`[${currentNode?.value_range?.min ?? '-'}, ${currentNode?.value_range?.max ?? '-'}] `}
      <Latex>{`${formatLatex(currentNode?.unit ?? '')}`}</Latex>
    </>
  );

  return (
    <div className='sideInfo'>
      <InformationEntryWithNode
        name={t('nodeInfo.name')}
        entries={[`${currentNode?.name ?? ''}${currentNode?.abbr ? ` (${currentNode.abbr})` : ''}`]}
        nodeType='sideInfo-selected-node'
      />
      <InformationEntry
        name={t('nodeInfo.type')}
        entry={`${currentNode?.kind === NodeKindEnum.Endogene ? t('nodeInfo.endogenous') : t('nodeInfo.exogenous')} ${
          currentNode?.data ? t('nodeInfo.withData') : t('nodeInfo.withoutData')
        }`}
      />
      <InformationEntry name={t('nodeInfo.description')} entry={currentNode?.description ?? '-'} status='wrap' />
      <InformationEntry name={t('nodeInfo.typOfVariable')} entry={currentNodeType} />
      <InformationEntry name={t('nodeInfo.rangeOfValues')} entry={currentNodeRange} />
      <InformationEntry
        name={t('nodeInfo.mechanism')}
        entry={
          directRelativesOfNode.parents.length > 0 || directRelativesOfNode.background.length > 0
            ? `Y=f(${[...directRelativesOfNode.parents, ...directRelativesOfNode.background].join(', ')})`
            : '-'
        }
      />
      <div className='sideInfo-entry'>
        <div className='sideInfo-heading'>{t('nodeInfo.pathsToGoal')}</div>
        <button
          style={{ background: 'transparent', border: 'none' }}
          type='button'
          onClick={() => {
            logToConsole('highlight path');
          }}
        >
          <span style={{ textDecorationLine: 'underline', color: '#0a2a46' }}>...</span>
        </button>
      </div>
      <InformationEntryWithNode
        name={t('nodeInfo.backgroundNodes')}
        entries={directRelativesOfNode.background}
        nodeType='sideInfo-background-node'
      />
      <InformationEntryWithNode
        name={t('nodeInfo.parents')}
        entries={directRelativesOfNode.parents}
        nodeType='sideInfo-parent-node'
      />
      <InformationEntryWithNode
        name={t('nodeInfo.children')}
        entries={directRelativesOfNode.children}
        nodeType='sideInfo-child-node'
      />
      <div className='sideInfo-entry'>
        <div className='sideInfo-heading'>{t('nodeInfo.actions')}</div>
        <button
          className='graphView-actions-check'
          type='button'
          onClick={() => {
            setHighlightNodes((prevHighlightNodes) => !prevHighlightNodes);
          }}
        >
          <div className={`graphView-actions-check-input ${highlightNodes && 'checked'}`} />
          <div className='graphView-actions-check-label'>{t('nodeInfo.checkboxText')}</div>
        </button>
      </div>
    </div>
  );
};
