import { ReactElement } from 'react';
import { TabId, TabView } from 'components/Utils/TabView';
import { Placeholder } from 'components/Utils/Placeholder';
import { ModelTabs } from 'components/ModelTabs/ModelTabs';

export const MainTabs = (): ReactElement => {
  return (
    <TabView
      tabs={[
        {
          ...TabId.MODEL,
          content: <ModelTabs />,
        },
        {
          ...TabId.VERIFICATION,
          content: <Placeholder />,
        },
      ]}
      darkmode
    />
  );
};
