import { Tooltip } from '@mui/material';
import { Node } from 'components/openapi';
import { useTranslation } from 'react-i18next';

type NodeIterationDisplayProps = {
  previousButtonAction: () => void;
  nodeOnClickAction: () => void;
  nextButtonAction: () => void;
  forwardButtonAction: () => void;
  allNodes: Node[];
  currentNodeIndex: number;
  forwardButtonTitle: string;
};
export const NodeIterationDisplay = ({
  nodeOnClickAction,
  previousButtonAction,
  nextButtonAction,
  forwardButtonAction,
  allNodes,
  currentNodeIndex,
  forwardButtonTitle,
}: NodeIterationDisplayProps) => {
  const { t } = useTranslation();
  return (
    <div className='single-node-display'>
      <div className='single-node-with-controls'>
        <Tooltip title={t('distributionTab.previousNodeButton')}>
          <button
            type='button'
            className='btn btn-default'
            onClick={previousButtonAction}
            aria-label={t('distributionTab.previousNodeButton')}
          >
            <span className='glyphicon glyphicon-menu-left' />
          </button>
        </Tooltip>

        <div className='single-node-display--node-name'>
          <button
            type='button'
            onClick={nodeOnClickAction}
            className='inline-node react-flow__node-MultiPortNode nopan selected'
          >
            {allNodes[currentNodeIndex ?? 0]?.name ?? allNodes[0]?.name}
          </button>
        </div>
        <Tooltip title={t('distributionTab.nextNodeButton')}>
          <button
            type='button'
            className='btn btn-default'
            onClick={nextButtonAction}
            aria-label={t('distributionTab.nextNodeButton')}
          >
            <span className='glyphicon glyphicon-menu-right' />
          </button>
        </Tooltip>
        <Tooltip title={forwardButtonTitle}>
          <button
            type='button'
            className='btn btn-default forward-button'
            onClick={forwardButtonAction}
            aria-label={forwardButtonTitle}
          >
            <span className='glyphicon glyphicon-menu-right' />
            <span className='glyphicon glyphicon-menu-right' />
          </button>
        </Tooltip>
      </div>

      <div>
        ({currentNodeIndex + 1}/{allNodes.length})
      </div>
    </div>
  );
};
