type DistributionParameter = {
  name: string;
  value?: number;
  label?: string;
};

export type Distribution = {
  type: 'continuous' | 'discrete';
  name: string;
  params: DistributionParameter[];
  label?: string;
};

export const NormDistribution: Distribution = {
  type: 'continuous',
  name: 'norm',
  label: 'distributionTab.normDistributionLabel',
  params: [
    { name: 'mu', value: 0, label: 'distributionTab.normMuLabel' },
    { name: 'sigma', value: 1, label: 'distributionTab.normSigmaLabel' },
  ],
};

const HalfnormDistribution: Distribution = {
  type: 'continuous',
  name: 'halfnorm',
  label: 'distributionTab.halfnormDistributionLabel',
  params: [
    { name: 'mu', value: 0, label: 'distributionTab.halfnormMuLabel' },
    { name: 'sigma', value: 1, label: 'distributionTab.halfnormSigmaLabel' },
  ],
};

const UniformDistribution: Distribution = {
  type: 'continuous',
  name: 'uniform',
  label: 'distributionTab.uniformDistributionLabel',
  params: [
    { name: 'a', value: 0, label: 'distributionTab.uniformALabel' },
    { name: 'scale', value: 1, label: 'distributionTab.uniformScaleLabel' },
  ],
};

export const continuousDistributions: Distribution[] = [NormDistribution, HalfnormDistribution, UniformDistribution];

const Binomial: Distribution = {
  type: 'discrete',
  name: 'binom',
  label: 'distributionTab.binomialDistributionLabel',
  params: [
    { name: 'p', value: 0, label: 'distributionTab.binomPLabel' },
    { name: 'n', value: 1, label: 'distributionTab.binomNLabel' },
  ],
};

export const discreteDistributions: Distribution[] = [Binomial];
const distributions: Record<string, Distribution> = {};
[...discreteDistributions, ...continuousDistributions].forEach((dist) => {
  distributions[dist.name] = dist;
});

export default distributions;
export const DEFAULT_DISTRIBUTION = { ...NormDistribution };
