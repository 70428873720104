import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const SplitScreen = ({
  leftPaneContent,
  rightPaneContent,
}: {
  leftPaneContent: JSX.Element;
  rightPaneContent: JSX.Element;
}): JSX.Element => {
  const initWidth = 400;
  const minWidth = 300;
  const splitScreenRef = useRef<HTMLInputElement>(null);
  const maxWidth = useRef<number | undefined>(undefined);
  const [width, setWidth] = useState(initWidth);
  const [mouseDown, setMouseDown] = useState(false);

  const { t } = useTranslation();

  const onMouseDown = () => {
    setMouseDown(true);
  };

  const onMouseUp = () => {
    setMouseDown(false);
  };

  const onMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    if (mouseDown) {
      const newWidth = -event.movementX + width;

      if (newWidth >= minWidth) {
        if (maxWidth.current === undefined) {
          if (splitScreenRef.current && splitScreenRef.current.offsetWidth > 0) {
            maxWidth.current = splitScreenRef.current.offsetWidth - initWidth;
          }
        }
        if (maxWidth.current) {
          if (newWidth <= maxWidth.current) {
            setWidth(newWidth);
          }
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', onMouseUp);
    return () => {
      document.removeEventListener('mouseup', onMouseUp);
    };
  }, []);

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className='d-flex flex-fill h-100' ref={splitScreenRef} onMouseMove={onMouseMove}>
      <div className={`d-flex flex-fill ${mouseDown ? 'user-select-none' : 'user-select-auto'}`}>{leftPaneContent}</div>
      <div
        className='px-2'
        role='button'
        style={{ cursor: 'col-resize', width: '0.3125rem' }}
        aria-label={t('splitscreen.splitScreenDividerAriaLabel')}
        tabIndex={0}
        onMouseDown={onMouseDown}
      />
      <div className={mouseDown ? 'user-select-none' : 'user-select-auto'} style={{ width }}>
        {rightPaneContent}
      </div>
    </div>
  );
};
