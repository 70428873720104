import { modelManager } from 'components/ModelTabs/ModelManager';
import { Model } from 'components/ModelTabs/Model';
import { Graph } from 'components/ModelTabs/Graph';
import JSZip from 'jszip';
import { ChangeEvent, useContext } from 'react';
import { CausalGraph, CausalModel } from 'components/openapi';
import { Workflow, WorkflowContext } from '../Utils/WorkflowContext';

const MODEL_UPLOADER_ID = 'MODEL_UPLOADER';

export const openUploadModelDialog = () => {
  document.getElementById(MODEL_UPLOADER_ID)?.click();
};

export const ModelUploader = (): JSX.Element => {
  const loadFromFileEntries = async (
    graphFileEntry: [string, JSZip.JSZipObject],
    modelFileEntry: [string, JSZip.JSZipObject],
    workflow: Workflow,
  ): Promise<{ newGraph: Graph; newModel: Model }> => {
    const [newGraph, newModel] = await Promise.all([
      (await graphFileEntry[1].async('blob')).text().then(async (content) => {
        const newCausalGraph = JSON.parse(content);
        newCausalGraph.nodes = new Set(newCausalGraph.nodes);
        newCausalGraph.links = new Set(newCausalGraph.links);

        return new Graph(newCausalGraph as CausalGraph, workflow.getPage());
      }),

      (await modelFileEntry[1].async('blob')).text().then(async (content) => {
        const newCausalModel = JSON.parse(content);
        newCausalModel.distributions = new Set(newCausalModel.distributions);
        newCausalModel.mechanisms = new Set(newCausalModel.mechanisms);

        return new Model(newCausalModel as CausalModel, workflow.getPage());
      }),
    ]);

    return { newGraph, newModel };
  };

  async function loadModel(event: React.ChangeEvent<HTMLInputElement>, workflow: Workflow) {
    if (!event.target.files) return;
    let causalGraphFileEntry: undefined | [string, JSZip.JSZipObject];
    let causalModelFileEntry: undefined | [string, JSZip.JSZipObject];

    await JSZip.loadAsync(event.target.files[0]).then((content) => {
      causalGraphFileEntry = Object.entries(content.files).find(
        (entry) =>
          entry[0].toLowerCase().endsWith('.causal-graph.json') || entry[0].toLowerCase().startsWith('causal_graph_'),
      );
      causalModelFileEntry = Object.entries(content.files).find(
        (entry) =>
          entry[0].toLowerCase().endsWith('.causal-model.json') || entry[0].toLowerCase().startsWith('causal_model_'),
      );
    });
    if (!causalGraphFileEntry || !causalModelFileEntry) return;

    let canBeSaved = true;
    const { newGraph, newModel } = await loadFromFileEntries(causalGraphFileEntry, causalModelFileEntry, workflow);

    canBeSaved = modelManager.addGraph(newGraph);
    modelManager.setGraphAsActive(workflow.getPage(), newGraph.state().id);
    if (canBeSaved) canBeSaved = await newGraph.save();

    modelManager.addModel(newModel);
    modelManager.setModelAsActive(workflow.getPage(), newModel.state().id);
    if (canBeSaved) await newModel.save();

    const element = event.target as HTMLInputElement;
    element.value = '';
  }

  const { currentWorkflow, setCurrentWorkflow } = useContext(WorkflowContext);

  return (
    <input
      id={MODEL_UPLOADER_ID}
      type='file'
      style={{ display: 'none' }}
      onInput={(event) => {
        loadModel(event as ChangeEvent<HTMLInputElement>, currentWorkflow);
        if (currentWorkflow.getPage() === 'CREATION') {
          setCurrentWorkflow(Workflow.GRAPH_CREATION);
        }
      }}
      accept='.zip'
    />
  );
};
