import { useEffect, useState } from 'react';
import { NavbarElement, DropdownItem } from 'components/Navbar/NavbarMenu';
import { ReactComponent as CloseIcon } from 'resources/assets/icons/close.svg';
import { ReactComponent as AddIcon } from 'resources/assets/icons/icons8-plus-math.svg';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';

export class TabId {
  readonly id: string;

  readonly title: string;

  static MODEL = new TabId('model', 'model.model');

  static VERIFICATION = new TabId('verification', 'model.verification');

  static INFORMATIONS = new TabId('informations', 'verticalTabs.informations');

  static DATA = new TabId('data', 'verticalTabs.data');

  static PLOTS = new TabId('plots', 'verticalTabs.plots');

  static MECHANISMS = new TabId('mechanisms', 'verticalTabs.mechanisms');

  static DISTRIBUTIONS = new TabId('distributions', 'verticalTabs.distributions');

  static IDENTIFIABILITY = new TabId('identifiability', 'verticalTabs.identifiability');

  static QUESTIONS = new TabId('questions', 'verticalTabs.questions');

  static CORNER_CASES = new TabId('cornerCases', 'verticalTabs.cornerCases');

  private constructor(id: string, title: string) {
    this.id = id;
    this.title = title;
  }
}

export const mutedContent = (isMuted: boolean) => {
  return {
    content: <div />,
    muted: isMuted,
  };
};

export type TabMenuItem = TabId & {
  muted?: boolean;
};

export type Tab = TabMenuItem & {
  content?: JSX.Element;
};

export type TabCallbacks = {
  onActiveTabChanged?: (id: string) => void;
  addTab?: () => void;
  addTabDropdown?: DropdownItem[];
  removeTab?: (id: string) => void;
};

export enum TabMenuPosition {
  TOP,
  RIGHT,
  BOTTOM,
  LEFT,
}

const RotatedMenu = ({ children, position }: { children: JSX.Element; position: TabMenuPosition }): JSX.Element => {
  let containerClass;
  let menuClass = 'tab-view--menu';

  if (position === TabMenuPosition.RIGHT) {
    containerClass = 'tab-view--right-container';
    menuClass = 'tab-view--right-menu';
  } else if (position === TabMenuPosition.LEFT) {
    containerClass = 'tab-view--left-container';
    menuClass = 'tab-view--left-menu';
  }

  return (
    <div className={containerClass} style={{ zIndex: 1 }}>
      <div className={menuClass}>{children}</div>
    </div>
  );
};

export const TabView = ({
  tabs,
  defaultContent,
  initialActiveTabId,
  callbacks,
  menuPosition = TabMenuPosition.TOP,
  darkmode: isDarkmode = false,
}: {
  tabs: Tab[];
  defaultContent?: JSX.Element;
  initialActiveTabId?: string;
  callbacks?: TabCallbacks;
  menuPosition?: TabMenuPosition;
  darkmode?: boolean;
}): JSX.Element => {
  const { t } = useTranslation();
  const [activeTabId, setActiveTabId] = useState<string>(initialActiveTabId ?? tabs[0]?.id ?? '');
  useEffect(() => {
    if (tabs.length > 0) {
      setActiveTabId(initialActiveTabId ?? tabs[0].id);
    }
  }, [tabs, initialActiveTabId]);

  const darkmode = isDarkmode ? ' dark' : '';
  const horizontal =
    menuPosition === TabMenuPosition.RIGHT || menuPosition === TabMenuPosition.LEFT ? ' flex-row' : ' flex-column';

  const contentIsBeforeMenu: boolean =
    menuPosition === TabMenuPosition.BOTTOM || menuPosition === TabMenuPosition.RIGHT;
  let activeElementIndex = tabs.findIndex((tab) => tab.id === activeTabId);
  if (activeElementIndex < 0) activeElementIndex = tabs.findIndex((tab) => tab.id === initialActiveTabId);
  if (activeElementIndex < 0) activeElementIndex = 0;

  const tabContents = [...tabs.map((tab) => tab.content), defaultContent];
  const content = (
    <>
      {tabContents
        .filter((tab) => tab)
        .map((tab, i) => (
          <div
            className={`tab-view--content ${i !== activeElementIndex ? 'd-none' : 'd-flex'}`}
            key={tabs[i]?.id ?? 'default'}
          >
            {tab}
          </div>
        ))}
    </>
  );

  return (
    <div className={`tab-view${darkmode}${horizontal}`}>
      {contentIsBeforeMenu && content}
      <RotatedMenu position={menuPosition}>
        <div className={`tab-view--menu ${darkmode}`}>
          {tabs.map((tab, idx) => {
            if (tab.muted) {
              return (
                <div className={`tab-view--menu-item-disabled ${darkmode}`} key={tab.id}>
                  <span className={`tab-view--menu-item-name ${darkmode}`}> {t(tab.title)}</span>
                </div>
              );
            }
            return (
              <button
                className={`tab-view--menu-item ${tab.id === activeTabId && 'active'} ${darkmode}`}
                style={{ zIndex: tabs.length - idx }}
                type='button'
                key={tab.id}
                onClick={() => {
                  setActiveTabId(tab.id);
                  if (callbacks?.onActiveTabChanged) {
                    callbacks.onActiveTabChanged(tab.id);
                  }
                }}
              >
                <Tooltip title={t(tab.title)}>
                  <span className={`tab-view--menu-item-name ${darkmode}`}>{t(tab.title)}</span>
                </Tooltip>
                {callbacks?.removeTab && (
                  <Tooltip title={t('model.closeModelTooltip')}>
                    <span>
                      <CloseIcon
                        aria-label={t('model.closeModelTooltip')}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (callbacks?.removeTab) callbacks.removeTab(tab.id);
                        }}
                      />
                    </span>
                  </Tooltip>
                )}
              </button>
            );
          })}
          {callbacks?.addTab && (
            <button
              className='tab-view--add-item-btn'
              type='button'
              onClick={() => {
                if (callbacks?.addTab) callbacks.addTab();
              }}
            >
              <AddIcon />
            </button>
          )}
          {callbacks?.addTabDropdown && (
            <div className='dropend'>
              <button type='button' className='tab-view--add-item-btn' data-bs-toggle='dropdown' aria-expanded='false'>
                <AddIcon />
              </button>
              <NavbarElement items={callbacks.addTabDropdown} />
            </div>
          )}
        </div>
      </RotatedMenu>
      {!contentIsBeforeMenu && content}
    </div>
  );
};
