import { HistoryState } from 'components/Utils/HistoryState';
import { EventHandler } from 'components/Utils/EventHandler';
import { addMissingCausalModelData, makeEmptyCausalModel } from 'components/Utils/emptyModelGraph';
import { CausalModel } from 'components/openapi';
import { WorkflowPage } from 'components/Utils/WorkflowContext';
import { AxiosError } from 'axios';
import { openAlertDialog } from 'components/Utils/funcs';
import { openSaveAlertDialog } from 'components/Utils/SaveAlertDialog';
import { getModellingApi } from '../Utils/ServerHolder';

export class Model {
  private history: HistoryState<CausalModel>;

  private eventHandler: EventHandler<'onModelChanged'>;

  private resetHist: boolean;

  private workflows: WorkflowPage[];

  constructor(data: CausalModel, workflow: WorkflowPage) {
    this.history = new HistoryState({ initState: addMissingCausalModelData({ ...data }) });
    this.eventHandler = new EventHandler();
    this.resetHist = false;
    this.workflows = [workflow];
  }

  setModelChangedListener(id: string, callback: () => void): void {
    this.eventHandler.setListener(id, 'onModelChanged', callback);
  }

  state(): CausalModel {
    return this.history.state() ?? makeEmptyCausalModel();
  }

  setState(state: CausalModel, skipListeners: string[] = []): void {
    if (this.resetHist) {
      this.history.clear();
      this.resetHist = false;
    }
    this.history.setState(state);
    this.eventHandler.callEventListeners('onModelChanged', skipListeners);
  }

  resetHistOnNextState(): void {
    this.resetHist = true;
  }

  undo(skipListeners: string[] = []): void {
    this.history.undo();
    this.eventHandler.callEventListeners('onModelChanged', skipListeners);
  }

  redo(skipListeners: string[] = []): void {
    this.history.redo();
    this.eventHandler.callEventListeners('onModelChanged', skipListeners);
  }

  canUndo(): boolean {
    return this.history.canUndo();
  }

  canRedo(): boolean {
    return this.history.canRedo();
  }

  async save(): Promise<boolean> {
    if (!this.state().id) {
      const response = await getModellingApi().apiPostCausalModels(this.state());
      this.state().id = response.data.id;
      return true;
    }
    try {
      const saveResponse = await getModellingApi().apiPutCausalModels(this.state().id, this.state());
      if (saveResponse !== undefined) {
        openSaveAlertDialog();
        return true;
      }
      return false;
    } catch (error) {
      openAlertDialog(
        `Model could not be saved\n${
          error instanceof AxiosError ? error.response?.data?.error_message : String(error)
        }`,
      );
      return false;
    }
  }

  isVisibleInWorkflow(workflow: WorkflowPage): boolean {
    return this.workflows.includes(workflow);
  }

  makeVisibleInWorkflow(workflow: WorkflowPage): void {
    if (!this.isVisibleInWorkflow(workflow)) {
      this.workflows.push(workflow);
    }
  }

  makeUnvisibleInWorkflow(workflow: WorkflowPage): void {
    if (this.isVisibleInWorkflow(workflow)) {
      this.workflows = this.workflows.filter((wflow) => wflow !== workflow);
    }
  }

  getWorkflows(): WorkflowPage[] {
    return this.workflows;
  }
}
