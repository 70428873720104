import { InformationEntry, InformationTable, InformationTableRow } from 'components/SideInformation/common';
import { Graph } from 'components/ModelTabs/Graph';
import { Model } from 'components/ModelTabs/Model';
import { Node } from 'components/openapi';
import { useTranslation } from 'react-i18next';

const getTableRowsWithNodeCount = (graph: Graph): InformationTableRow[] => {
  const { t } = useTranslation();

  const info: InformationTableRow[] = [];

  const backgroundNodes = new Set(
    Array.from(graph.state().nodes)
      .map((n) => (n.kind === 'exogene' ? n.id : null))
      .filter((n) => n != null),
  );
  const sources = new Set(
    Array.from(graph.state().links)
      .map((l) => (!backgroundNodes.has(l.from) ? l.from : null))
      .filter((n) => n != null),
  );
  const sinks = new Set(
    Array.from(graph.state().links)
      .map((l) => (!backgroundNodes.has(l.from) ? l.to : null))
      .filter((n) => n != null),
  );

  info.push({ title: t('graphInfo.intotal'), count: new Set([...sinks, ...sources, ...backgroundNodes]).size });
  info.push({ title: t('graphInfo.rootNodes'), count: [...sources].filter((s) => !sinks.has(s)).length });
  info.push({ title: t('graphInfo.leafNodes'), count: [...sinks].filter((s) => !sources.has(s)).length });
  info.push({
    title: t('graphInfo.innerNodes'),
    count: info[0].count - info[1].count - info[2].count - backgroundNodes.size,
  });
  info.push({ title: t('graphInfo.backgroundNodes'), count: backgroundNodes.size });
  return info;
};

const getNumberOfMechanisms = (nodes: Node[]) => {
  return nodes.filter((node) => node.kind === 'endogene').length.toString();
};

export const GraphInformation = ({ model, graph }: { model: Model; graph: Graph }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className='sideInfo'>
      <InformationEntry name={t('graphInfo.fileName')} entry='...' />
      <InformationEntry name={t('graphInfo.modelName')} entry={model.state().name} />
      <InformationEntry name={t('graphInfo.description')} entry={model.state().description} />
      <InformationTable name={t('graphInfo.numberOfNodes')} entries={getTableRowsWithNodeCount(graph)} />
      <InformationEntry name={t('graphInfo.numberOfEdges')} entry={graph.state().links.size} />
      <InformationEntry
        name={t('graphInfo.numberOfMechanisms')}
        entry={getNumberOfMechanisms(Array.from(graph.state().nodes))}
      />
      <InformationEntry name={t('graphInfo.numberOfCycles')} entry='...' />
      <InformationEntry name={t('graphInfo.stateOfReview')} entry='...' status='sideInfo-warning' />
      <InformationEntry name={t('graphInfo.reliability')} entry='...' status='sideInfo-success' />
      <InformationEntry name={t('graphInfo.lastReview')} entry='...' />
      <InformationEntry name={t('graphInfo.reviewMethods')} entry='...' />
    </div>
  );
};
