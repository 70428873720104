import { useContext, useState } from 'react';
import { Model } from 'components/ModelTabs/Model';
import { Graph } from 'components/ModelTabs/Graph';
import { Tab, TabId } from 'components/Utils/TabView';
import { Placeholder } from 'components/Utils/Placeholder';
import { GraphView } from 'components/GraphView/GraphView';
import { GraphInformation } from 'components/SideInformation/GraphInformation';
import { NodeInformation } from 'components/SideInformation/NodeInformation';
import { QueryTab } from 'components/QueryTabs/QueryTab';
import { MechanismTab } from 'components/MechanismTabs/MechanismTab';
import { DistributionTab } from 'components/DistributionTabs/DistributionTab';
import { CCTab } from 'components/CCTabs/CCTab';
import { IdentifiabilityTab } from 'components/IdentifiabilityTabs/IdentifiabilityTab';
import { Workspace } from 'components/Utils/Workspace';
import { getNodesElligibleForDistribution, getNodesElligibleForMechanism, useRerender } from 'components/Utils/funcs';
import { Workflow, WorkflowContext } from 'components/Utils/WorkflowContext';
import { useNavigate } from 'react-router-dom';
import { CustomIcons } from 'components/GraphView/MechDistIcons';

export const ModelWorkspace = ({ model, graph }: { model: Model; graph: Graph }): JSX.Element => {
  const [nodeId, setSelectedNodeId] = useState<number | undefined>(undefined);
  const [nodeIcons, setNodeIcons] = useState<Record<string, CustomIcons>>({});
  // Use more React-y method
  const [forceOpenTabId, setForceOpenTabId] = useState<[string | undefined, number]>([undefined, 0]);
  const [highlightNodes, setHighlightNodes] = useState(false);
  const rerender = useRerender();
  const navigate = useNavigate();
  model.setModelChangedListener('workspace', rerender);
  graph.setGraphChangedListener('workspace', rerender);

  const { currentWorkflow, setCurrentWorkflow } = useContext(WorkflowContext);

  const openTab = (type: 'mech' | 'dist', currentModel: Model): void => {
    setCurrentWorkflow(Workflow.MECHANISM_CREATION);
    currentModel.makeVisibleInWorkflow(Workflow.MECHANISM_CREATION.getPage());
    setForceOpenTabId([type === 'mech' ? TabId.MECHANISMS.id : TabId.DISTRIBUTIONS.id, (forceOpenTabId[1] + 1) % 10]);
  };

  const graphView: JSX.Element = (
    <GraphView
      model={model}
      graph={graph}
      onNodeClick={(id) => setSelectedNodeId(id === undefined ? undefined : Number(id))}
      highlightRelatedNodes={highlightNodes}
      nodeIdSelectedOutside={nodeId?.toString()}
      nodeIcons={nodeIcons}
      setNodeIcons={setNodeIcons}
      onMechButtonClick={(type) => {
        openTab(type, model);
      }}
      key={model.state().id}
    />
  );

  const graphInformations: JSX.Element =
    nodeId !== null && nodeId !== undefined ? (
      <NodeInformation
        graph={graph}
        nodeId={nodeId}
        highlightNodes={highlightNodes}
        setHighlightNodes={setHighlightNodes}
      />
    ) : (
      <GraphInformation model={model} graph={graph} />
    );

  let sideTabs: Tab[] = [];
  if (currentWorkflow === Workflow.GRAPH_CREATION) {
    sideTabs = [
      {
        ...TabId.INFORMATIONS,
        content: graphInformations,
      },
      {
        ...TabId.DATA,
        content: <Placeholder />,
      },
      {
        ...TabId.PLOTS,
        content: <Placeholder />,
      },
    ];
  } else if (currentWorkflow === Workflow.MECHANISM_CREATION) {
    sideTabs = [
      {
        ...TabId.INFORMATIONS,
        content: graphInformations,
      },
      {
        ...TabId.DISTRIBUTIONS,
        muted: getNodesElligibleForDistribution(graph.state()).length === 0,
        content: (
          <DistributionTab
            model={model}
            graph={graph}
            nodeId={nodeId}
            onNextSection={() => openTab('mech', model)}
            openMechTab={() => {
              if (forceOpenTabId[0] === TabId.DISTRIBUTIONS.id) openTab('mech', model);
            }}
            setNodeIcons={setNodeIcons}
            update={forceOpenTabId[0] === TabId.DISTRIBUTIONS.id ? forceOpenTabId[1] : undefined}
            onNodeSelect={(id: number | undefined) => setSelectedNodeId(id)}
          />
        ),
      },
      {
        ...TabId.MECHANISMS,
        muted: getNodesElligibleForMechanism(graph.state()).length === 0,
        content: (
          <MechanismTab
            model={model}
            graph={graph}
            nodeId={nodeId}
            onNextSection={() => {
              setCurrentWorkflow(Workflow.CAUSAL_INFERENCE);
              model.makeVisibleInWorkflow(Workflow.CAUSAL_INFERENCE.getPage());
              navigate(`../inference`);
            }}
            openDistTab={() => {
              if (forceOpenTabId[0] === TabId.MECHANISMS.id) openTab('dist', model);
            }}
            setNodeIcons={setNodeIcons}
            update={forceOpenTabId[0] === TabId.MECHANISMS.id ? forceOpenTabId[1] : undefined}
            onNodeSelect={(id: number | undefined) => setSelectedNodeId(id)}
          />
        ),
      },
    ];
  } else if (currentWorkflow === Workflow.CAUSAL_INFERENCE) {
    sideTabs = [
      {
        ...TabId.QUESTIONS,
        content: <QueryTab modelId={model.state().id as string} />,
      },
      {
        ...TabId.CORNER_CASES,
        content: <CCTab model={model} graph={graph} />,
      },
      {
        ...TabId.IDENTIFIABILITY,
        content: <IdentifiabilityTab modelId={model.state().id as string} />,
      },
    ];
  }

  return (
    <Workspace
      forceOpenTab={forceOpenTabId}
      mainElement={graphView}
      activeTabChanged={(id: string) => {
        if (currentWorkflow === Workflow.MECHANISM_CREATION) setForceOpenTabId([id, (forceOpenTabId[1] + 1) % 10]);
      }}
      sideTabs={sideTabs}
    />
  );
};
