import parse from 'html-react-parser';
import { useRerender } from 'components/Utils/funcs';
import { postBaseQueryGetAnswer } from 'components/Utils/BackendApi';
import { ApexHistogram } from 'components/QueryTabs/ApexHistogram';
import { FormInput } from 'components/Utils/FormInput';
import { BaseAnswer, BaseQuery } from 'components/openapi';
import { LinearProgressWithLabel } from 'components/CCTabs/ProgressBar';
import { useTranslation } from 'react-i18next';

const queries: Record<string, { input: string; query: BaseQuery; response?: BaseAnswer }> = {};

export const textWithLineBreak = (text: string, className?: string): JSX.Element[] => {
  return text.split('\n').map((line, index) => (
    <p className={className} key={line + index.toString()}>
      {line}
    </p>
  ));
};

export const QueryTab = ({ modelId }: { modelId: string }): JSX.Element => {
  const { t } = useTranslation();

  const rerender = useRerender();

  if (queries[modelId] === undefined) {
    queries[modelId] = {
      input: '',
      query: {
        object: {
          id: modelId,
          type: 'model',
        },
        body: '',
      },
    };
  }
  const { input, query, response } = queries[modelId];

  const handleSubmit = async (event) => {
    event.preventDefault();

    query.body = input;

    query.progress = undefined;
    // TODO: Comment out after changes in backend
    /* const progressUpdate = (progress: number) => {
      queries[modelId].query.progress = progress;
      rerender();
      if (progress === 100) {
        setTimeout(() => {
          queries[modelId].query.progress = undefined;
          rerender();
        }, 600);
      }
    };
    */

    const baseAnswer = await postBaseQueryGetAnswer(query, undefined);
    if (baseAnswer) {
      queries[modelId].response = baseAnswer;
      rerender();
    }
  };

  return (
    <div className='query-tab-container'>
      <form onSubmit={handleSubmit}>
        <p className='text-muted'>{t('querySideTab.hint')}</p>
        <FormInput
          placeholder={t('querySideTab.placeholder')}
          value={input}
          onChange={(e) => {
            queries[modelId].input = e.target.value;
            rerender();
          }}
          label={t('querySideTab.placeholder')}
          id='query'
          name='query'
        />
        <button type='submit' className={input ? '' : 'disabled text-muted'} disabled={!input}>
          {t('querySideTab.button')}
        </button>
      </form>
      {query.progress !== undefined && (
        <>
          <p className='py-2'>{t('querySideTab.progressLabel')}</p>
          <LinearProgressWithLabel value={query.progress} progressWidth='60%' />
        </>
      )}
      {response && (
        <div id='query-response-container'>
          <p className='text-muted pt-3'>{t('querySideTab.resultTitle')}</p>
          {response?.error_message && textWithLineBreak(response.error_message, 'pb-3 query-tab-error-message')}
          {response?.body?.probability && (
            <p className='pb-3'>
              {t('querySideTab.resultContent')}: {response?.body?.probability}
            </p>
          )}
          {response?.body?.histogram && <ApexHistogram histogram={response?.body?.histogram} />}
          {response?.body?.svg && parse(response?.body?.svg)}
        </div>
      )}
    </div>
  );
};
