import { useEffect, useMemo, useRef, useState } from 'react';
import {
  ModelCreatorDialog,
  ModelLoaderDialog,
  ModelRenameDialog,
  openModelCreatorDialog,
  openModelLoaderDialog,
  openModelRenameDialog,
} from 'components/ModelTabs/ModelLoader';
import { modelManager } from 'components/ModelTabs/ModelManager';
import { Navbar } from 'components/Navbar/Navbar';
import { MainTabs } from 'components/InterferenceTabs/MainTabs';
import { AddNodeDialog } from 'components/GraphView/AddNodeDialog';

import { ModelUploader, openUploadModelDialog } from 'components/ModelTabs/ModelUploader';
import { openExportModelGraphDialog } from 'components/ModelTabs/ModelExporter';
import { WorkflowContext, Workflow } from 'components/Utils/WorkflowContext';
import { SaveAlertDialog } from 'components/Utils/SaveAlertDialog';
import { ThemeProvider } from '@mui/material';
import { muiThemeEnglish, muiThemeGerman } from 'components/Utils/MuiTheme';
import { useTranslation } from 'react-i18next';

export const Creation = (): JSX.Element => {
  const { i18n } = useTranslation();
  const thisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onKeyDown = async (event: KeyboardEvent): Promise<void> => {
      if (event.ctrlKey && thisRef.current && !thisRef.current.hidden) {
        const model = modelManager.getActiveModel(Workflow.GRAPH_CREATION.getPage());
        const graph = modelManager.getActiveGraph(Workflow.GRAPH_CREATION.getPage());
        if (event.key === 'z') {
          event.preventDefault();
          if (model?.canUndo()) model?.undo();
          if (graph?.canUndo()) graph?.undo();
        } else if (event.key === 'y') {
          event.preventDefault();
          if (model?.canRedo()) model?.redo();
          if (graph?.canRedo()) graph?.redo();
        } else if (event.key === 's') {
          event.preventDefault();
          if (await graph?.save()) model?.save();
        } else if (event.key === 'i') {
          event.preventDefault();
          openUploadModelDialog();
        } else if (event.key === 'e') {
          event.preventDefault();
          openExportModelGraphDialog(model?.state(), graph?.state());
        } else if (event.key === 'q') {
          event.preventDefault();
          modelManager.removeModel(Workflow.GRAPH_CREATION.getPage(), model?.state().id);
          modelManager.removeGraph(Workflow.GRAPH_CREATION.getPage(), graph?.state().id);
        } else if (event.shiftKey && event.key.toLowerCase() === 'n') {
          event.preventDefault();
          openModelCreatorDialog();
        } else if (event.key === 'o') {
          event.preventDefault();
          openModelLoaderDialog();
        } else if (event.key === 'r') {
          event.preventDefault();
          openModelRenameDialog();
        }
      }
    };
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, []);

  const [currentWorkflow, setCurrentWorkflow] = useState(Workflow.GRAPH_CREATION);
  const workflowContext = useMemo(() => ({ currentWorkflow, setCurrentWorkflow }), [currentWorkflow]);

  return (
    <WorkflowContext.Provider value={workflowContext}>
      <ThemeProvider theme={i18n.resolvedLanguage === 'de' ? muiThemeGerman : muiThemeEnglish}>
        <div className='main-container' id='inference-container' ref={thisRef}>
          <SaveAlertDialog />
          <Navbar />
          <MainTabs />
          <AddNodeDialog id='addNodeDialog' isEditDialog={false} workflow={currentWorkflow} />
          <AddNodeDialog id='editNodeDialog' isEditDialog workflow={currentWorkflow} />
          <ModelLoaderDialog />
          <ModelCreatorDialog />
          <ModelRenameDialog />
          <ModelUploader />
        </div>
      </ThemeProvider>
    </WorkflowContext.Provider>
  );
};
