import { ApexOptions } from 'apexcharts';
import ReactApexChart from 'react-apexcharts';
import DownloadIcon from 'resources/assets/icons/icons8-download.svg';
import ZoomInIcon from 'resources/assets/icons/layout_zoom_in.svg';
import ZoomOutIcon from 'resources/assets/icons/layout_zoom_out.svg';
import { BaseAnswerBodyHistogram } from 'components/openapi';
import { useTranslation } from 'react-i18next';

const MAX_NUM_OF_XLABELS = 12;
const X_LABELS_PRECISION = 2;
const X_LABELS_IN_TOOLTIP_PREC = 4;
const Y_LABELS_PRECISION = 3;
const Y_LABELS_IN_TOOLTIP_PREC = 5;

type ChartData = {
  x: number;
  y: number;
};

type ChartType =
  | 'line'
  | 'area'
  | 'bar'
  | 'pie'
  | 'donut'
  | 'radialBar'
  | 'scatter'
  | 'bubble'
  | 'heatmap'
  | 'treemap'
  | 'boxPlot'
  | 'candlestick'
  | 'radar'
  | 'polarArea'
  | 'rangeBar';

type ApexChart = {
  type: ChartType;
  options: ApexOptions;
};

function createApexHistogram(histogram: BaseAnswerBodyHistogram): ApexChart {
  const { t } = useTranslation();

  function formatSeries(hist: number[], bin_edges: number[]): ChartData[] {
    const formatedSeries: ChartData[] = new Array(hist.length);
    for (let i = 0; i < hist.length; i++) {
      formatedSeries[i] = {
        x: (bin_edges[i] + bin_edges[i + 1]) / 2,
        y: hist[i],
      };
    }
    if (formatedSeries.length === 0) {
      // Avoids error when series is updated with empty array
      return [{ x: 0, y: Number.NaN }];
    }
    return formatedSeries;
  }

  const chart: ApexChart = {
    type: 'bar',
    options: {
      series: [
        {
          name: t('querySideTab.queryPlotYLabel'),
          data: formatSeries(histogram.hist || [], histogram.bin_edges || []),
        },
      ],
      chart: {
        toolbar: {
          tools: {
            download: `<img src=${DownloadIcon}>`,
            zoomin: `<img src=${ZoomInIcon}>`,
            zoomout: `<img src=${ZoomOutIcon}>`,
            selection: false,
            zoom: false,
            pan: false,
            reset: false,
            customIcons: [],
          },
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '100%',
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        title: {
          text: histogram.xlabel,
          style: {
            fontSize: '0.9rem',
            fontWeight: '500',
          },
        },
        tickPlacement: 'on',
        type: 'numeric',
        min: Math.min(...(histogram.bin_edges || [])),
        max: Math.max(...(histogram.bin_edges || [])),
        tickAmount: Math.min(histogram.hist?.length, MAX_NUM_OF_XLABELS),
        labels: { formatter: (x) => (Number.isInteger(Number(x)) ? x : Number(x).toFixed(X_LABELS_PRECISION)) },
      },
      yaxis: {
        title: {
          text: t('querySideTab.queryPlotYLabel'),
          style: {
            fontSize: '0.9rem',
            fontWeight: '500',
          },
        },
        labels: {
          formatter: (y) => (Number.isInteger(Number(y)) ? y.toString() : Number(y).toFixed(Y_LABELS_PRECISION)),
        },
      },
      tooltip: {
        x: {
          formatter: (_, chartObj) =>
            `${histogram.xlabel}: ${histogram.bin_edges?.[chartObj.dataPointIndex].toFixed(
              X_LABELS_IN_TOOLTIP_PREC,
            )} - ${histogram.bin_edges?.[chartObj.dataPointIndex + 1].toFixed(X_LABELS_IN_TOOLTIP_PREC)}`,
        },
        y: {
          formatter: (value) => `${value.toFixed(Y_LABELS_IN_TOOLTIP_PREC)}`,
        },
      },
    },
  };

  return chart;
}

export const ApexHistogram = ({ histogram }: { histogram: BaseAnswerBodyHistogram }): JSX.Element => {
  const chart = createApexHistogram(histogram);
  return <ReactApexChart options={chart?.options} series={chart.options?.series} type={chart?.type} height='500' />;
};
