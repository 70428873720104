export type HistoryStateProps<T> = {
  initState?: T;
  historyDepth?: number;
};

export class HistoryState<T> {
  private history: T[];

  private historyIndex: number;

  private readonly historyDepth: number;

  constructor({ initState, historyDepth = 666 }: HistoryStateProps<T>) {
    this.history = initState === undefined ? [] : [initState];
    this.historyIndex = 0;
    this.historyDepth = historyDepth;
  }

  state(): T | undefined {
    return this.history[this.historyIndex];
  }

  setState(state: T): void {
    this.history = [
      ...this.history.slice(Math.max(0, this.historyIndex - this.historyDepth + 2), this.historyIndex + 1),
      state,
    ];
    this.historyIndex = Math.min(this.historyDepth - 1, this.historyIndex + 1, this.history.length - 1);
  }

  clear(): void {
    this.history = [];
    this.historyIndex = 0;
  }

  undo(): void {
    this.historyIndex -= 1;
  }

  redo(): void {
    this.historyIndex += 1;
  }

  canUndo(): boolean {
    return this.historyIndex > 0;
  }

  canRedo(): boolean {
    return this.historyIndex + 1 < this.history.length;
  }
}
