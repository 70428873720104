import { Dispatch, SetStateAction, useContext, useEffect, useRef, useState } from 'react';
import {
  Background,
  Connection,
  ConnectionMode,
  Edge,
  EdgeChange,
  Node,
  NodeChange,
  Panel,
  ReactFlow,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import * as layout from 'components/GraphView/layout';
import {
  collect,
  equals,
  useRerender,
  updateDateAndVersion,
  getNodesElligibleForDistribution,
  getNodesElligibleForMechanism,
} from 'components/Utils/funcs';
import { Model } from 'components/ModelTabs/Model';
import { Graph } from 'components/ModelTabs/Graph';
import { Actions, ActionsPanel } from 'components/GraphView/ActionsPanel';
import { ControlsPanel } from 'components/GraphView/ControlsPanel';
import { MultiPortNode } from 'components/GraphView/MultiPortNode';
import { flowToHolmes, GraphToFlow } from 'components/GraphView/convert';
import { setSelectedNode } from 'components/GraphView/AddNodeDialog';
import { emptyNode } from 'components/Utils/emptyModelGraph';
import { openExportModelGraphDialog } from 'components/ModelTabs/ModelExporter';
import { ReactComponent as AddNodeIcon } from 'resources/assets/icons/add-node.svg';
import { WorkflowContext, Workflow } from 'components/Utils/WorkflowContext';
import { CausalGraph, CausalModel } from 'components/openapi';
import { createNameForExogeneParent } from 'components/Utils/Abbreviations';
import { useTranslation } from 'react-i18next';
import { CustomIcons, setDistributionIcons, setMechanismIcons } from './MechDistIcons';

const setPorts = (nodes: Node[], edges: Edge[]) => {
  const minVSpaceForHLine = 20;
  const nodeMap: Record<string, Node> = Object.fromEntries(nodes.map((node) => [node.id, node]));
  return edges.map((edge) => {
    const sourceNode = nodeMap[edge.source];
    const targetNode = nodeMap[edge.target];
    const maxVCenterDistForHLine = (sourceNode.height ?? 10) / 2 + (targetNode.height ?? 10) / 2 + minVSpaceForHLine;
    const drawHLine = Math.abs(sourceNode.position.y - targetNode.position.y) < maxVCenterDistForHLine;
    const horizontalPorts = () => (sourceNode.position.x < targetNode.position.x ? ['r', 'l'] : ['l', 'r']);
    const verticalPorts = () => (sourceNode.position.y < targetNode.position.y ? ['b', 't'] : ['t', 'b']);
    const [sourceHandle, targetHandle] = (drawHLine ? horizontalPorts : verticalPorts)();
    return { ...edge, sourceHandle, targetHandle };
  });
};

const nodeTypes = { MultiPortNode };

type GraphViewProps = {
  model: Model;
  graph: Graph;
  onNodeClick: (id?: string) => void;
  nodeIdSelectedOutside?: string;
  nodeIcons: Record<string, CustomIcons>;
  setNodeIcons: Dispatch<SetStateAction<Record<string, CustomIcons>>>;
  onMechButtonClick: (type: 'mech' | 'dist', model: Model) => void;
  highlightRelatedNodes: boolean;
};

const GraphViewImpl = ({
  model,
  graph,
  onNodeClick,
  nodeIdSelectedOutside,
  nodeIcons,
  setNodeIcons,
  onMechButtonClick,
  highlightRelatedNodes,
}: GraphViewProps): JSX.Element => {
  const { t } = useTranslation();
  const [rasterX, rasterY] = [25, 25];
  const { nodes: initNodes, edges: initEdges } = GraphToFlow(graph.state());
  const [nodes, setNodes, onNodesChange] = useNodesState(initNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initEdges);
  const [rasterize, setRasterize] = useState(false);
  const reactFlowInstance = useReactFlow();
  const isInitializedRef = useRef(false);
  const isReactFlowReadyRef = useRef(false);
  const modelUpdateRequiredRef = useRef(false);
  const mechanismsUpdateRequiredRef = useRef(true);
  const checkNodePositionsRef = useRef(false);
  const fitViewRef = useRef(false);
  const nodeWasDraggedRef = useRef(false);
  const prevNodePositionsRef = useRef(Object.fromEntries(initNodes.map((n) => [n.id, n.position])));
  const prevHiddenNodesMapRef = useRef(Object.fromEntries(nodes.map((node) => [node.id, node.hidden])));
  const actionsStateRef = useRef<Actions | undefined>(undefined);
  const rerender = useRerender();
  const { currentWorkflow } = useContext(WorkflowContext);

  const removeRedundantMechsDists = (currentModel: CausalModel, changedGraph: CausalGraph): CausalModel => {
    const nodesIdForDists = getNodesElligibleForDistribution(changedGraph).map((node) => node.id);
    const nodesIdForMechs = getNodesElligibleForMechanism(changedGraph).map((node) => node.id);
    let currentDists = Array.from(currentModel.distributions);
    let currentMechs = Array.from(currentModel.mechanisms);

    currentDists = currentDists.filter((dist) => nodesIdForDists.includes(dist.node));
    currentMechs = currentMechs.filter((mech) => nodesIdForMechs.includes(mech.node));
    return { ...currentModel, distributions: new Set(currentDists), mechanisms: new Set(currentMechs) };
  };

  const updateGraph = (newNodes = nodes, newEdges = edges) => {
    if (modelUpdateRequiredRef.current) {
      const newGraphState = { ...graph.state(), ...flowToHolmes({ nodes: newNodes, edges: newEdges }, graph.state()) };
      graph.setState(updateDateAndVersion(newGraphState), ['GraphView']);
      const newModelState = removeRedundantMechsDists({ ...model.state() }, newGraphState);
      model.setState(updateDateAndVersion(newModelState));
      modelUpdateRequiredRef.current = false;
    }

    if (mechanismsUpdateRequiredRef.current) {
      setMechanismIcons(model, graph, setNodeIcons);
      setDistributionIcons(model, graph, setNodeIcons);
      mechanismsUpdateRequiredRef.current = false;
    }
  };

  const onNodesChangeExtended = (nodeChanges: NodeChange[]) => {
    onNodesChange(nodeChanges);
    isReactFlowReadyRef.current = true;
    if (nodeChanges.find((c) => c.type === 'position')) {
      nodeWasDraggedRef.current = true;
    }
    if (nodeChanges.map((change) => change.type).includes('remove')) {
      modelUpdateRequiredRef.current = true;
      mechanismsUpdateRequiredRef.current = true;
    }
  };

  const onEdgesChangeExtended = (edgeChanges: EdgeChange[]) => {
    onEdgesChange(edgeChanges);
    if (edgeChanges.map((change) => change.type).includes('remove')) {
      modelUpdateRequiredRef.current = true;
      mechanismsUpdateRequiredRef.current = true;
    }
  };

  const isHiddenConfounder = (node: Node, edges_ = edges): boolean =>
    node.data.kind === 'exogene' && !node.data.data && edges_.filter((e) => e.source === node.id).length > 1;

  const isHidden = (node: Node): boolean => {
    return !(
      (actionsStateRef.current?.showBackgroundNodes &&
        !node.data.isHiddenConfounder &&
        node.data.kind === 'exogene' &&
        !node.data.data) ||
      (node.data.isParentOfSelected && highlightRelatedNodes) ||
      node.data.isHiddenConfounder ||
      node.selected ||
      node.data.kind === 'endogene'
    );
  };

  const updateHidden = (nodes_: Node[]) =>
    nodes_.map((node) => ({
      ...node,
      hidden: isHidden(node),
    }));
  const onActionsChanged = (actions: Actions) => {
    actionsStateRef.current = actions;
    setNodes(updateHidden(nodes));
  };

  const alignNodes = (prevNodes: Node[], _rasterize: boolean = rasterize) => {
    if (_rasterize) {
      return layout.alignToGrid({ nodes: prevNodes, raster: { x: rasterX, y: rasterY } });
    }
    return prevNodes;
  };

  const toggleRasterization = () => {
    setRasterize((prevRasterize) => {
      if (!prevRasterize) {
        modelUpdateRequiredRef.current = true;
        setNodes((prevNodes) => alignNodes(prevNodes, true));
      }
      return !prevRasterize;
    });
  };

  const autoLayoutELK = async () => {
    modelUpdateRequiredRef.current = true;
    checkNodePositionsRef.current = true;
    fitViewRef.current = true;
    setNodes(
      alignNodes(
        await layout.elkLayerDown({
          nodes,
          edges,
        }),
      ),
    );
  };

  const autoLayoutDagre = async (nodes_ = nodes) => {
    modelUpdateRequiredRef.current = true;
    checkNodePositionsRef.current = true;
    fitViewRef.current = true;
    setNodes(
      alignNodes(
        await layout.dagreLayerDown({
          nodes: nodes_,
          edges,
        }),
      ),
    );
  };

  const restoreUserLayout = () => {
    modelUpdateRequiredRef.current = true;
    fitViewRef.current = true;
    setNodes((prevNodes) => {
      const initNodePositions = Object.fromEntries(initNodes.map((node) => [node.id, node.position]));
      return alignNodes(prevNodes.map((node) => ({ ...node, position: initNodePositions[node.id] })));
    });
  };

  const deleteSelected = () => {
    const nodeToDelete = new Set(collect(nodes, (node) => (node.selected ? node.id : null)));
    const newNodes = nodes.filter((node) => !node.selected);
    const newEdges = edges.filter(
      (edge) => !(nodeToDelete.has(edge.source) || nodeToDelete.has(edge.target) || edge.selected),
    );
    if (newNodes.length < nodes.length) {
      modelUpdateRequiredRef.current = true;
      mechanismsUpdateRequiredRef.current = true;
      setNodes(newNodes);
    }
    if (newEdges.length < edges.length) {
      modelUpdateRequiredRef.current = true;
      mechanismsUpdateRequiredRef.current = true;
      setEdges(newEdges);
    }
  };

  useEffect(() => {
    if (!isReactFlowReadyRef.current) {
      return;
    }
    if (!isInitializedRef.current) {
      const atLeastOnNodeHasASize = nodes.reduce((aggregator, node) => aggregator || node.width != null, false);
      if (!atLeastOnNodeHasASize) {
        return;
      }
      isInitializedRef.current = true;
      if (!graph.state().info?.frontend?.layout) {
        graph.resetHistOnNextState();
        autoLayoutDagre(updateHidden(nodes));
        return;
      }
      setNodes((prevNodes) => updateHidden(prevNodes));
      nodeWasDraggedRef.current = true;
      fitViewRef.current = true;
    }

    let newEdges = edges;
    if (checkNodePositionsRef.current || nodeWasDraggedRef.current) {
      newEdges = setPorts(nodes, newEdges);
      nodeWasDraggedRef.current = false;
    }

    const hiddenNodesMap = Object.fromEntries(nodes.map((node) => [node.id, node.hidden]));
    if (!equals(hiddenNodesMap, prevHiddenNodesMapRef.current)) {
      prevHiddenNodesMapRef.current = hiddenNodesMap;
      newEdges = newEdges.map((edge) => ({
        ...edge,
        hidden: hiddenNodesMap[edge.source],
      }));
    }
    if (!Object.is(newEdges, edges)) {
      setEdges(newEdges);
    }

    if (checkNodePositionsRef.current) {
      checkNodePositionsRef.current = false;
      const nodePositions = Object.fromEntries(nodes.map((n) => [n.id, n.position]));
      if (!equals(nodePositions, prevNodePositionsRef.current)) {
        modelUpdateRequiredRef.current = true;
        prevNodePositionsRef.current = nodePositions;
      }
    }

    updateGraph(nodes, newEdges);

    if (fitViewRef.current) {
      fitViewRef.current = false;
      reactFlowInstance.fitView({ padding: 0.3 });
    }
  }, [nodes]);

  useEffect(() => {
    if (!isInitializedRef.current) return;
    updateGraph();

    let updateNodes = false;
    const newNodes = nodes.map((n) => {
      const node = { ...n };
      node.data.isHiddenConfounder = isHiddenConfounder(node);
      node.hidden = isHidden(node);
      updateNodes ||= node.data.isHiddenConfounder !== n.data.isHiddenConfounder || node.hidden !== n.hidden;
      return node;
    });
    if (updateNodes) setNodes(newNodes);
  }, [edges]);

  const markRelatedNodes = (nodes_: Node[], edges_: Edge[]): Node[] => {
    const selectedNodeId = collect(nodes_, (node) => (node.selected ? node.id : null));
    const ancestorNodes = [
      ...new Set(collect(edges_, (edge) => (selectedNodeId.includes(edge.target) ? edge.source : null))),
    ];
    const parentNodes = ancestorNodes.filter((id) =>
      nodes_.find((node) => node.id === id && node.data.kind === 'endogene'),
    );
    const backgroundNodes = ancestorNodes.filter((id) => !parentNodes.includes(id));
    const childNodes = [
      ...new Set(collect(edges_, (edge) => (selectedNodeId.includes(edge.source) ? edge.target : null))),
    ];
    return nodes_.map((node) => {
      const originClassName = node.className?.replace(' parent', '').replace(' child', '').replace(' background', '');
      const newNode = node;
      let subStyle = '';
      if (highlightRelatedNodes) {
        if (parentNodes.includes(node.id)) {
          subStyle = ' parent';
        } else if (childNodes.includes(node.id)) {
          subStyle = ' child';
        } else if (backgroundNodes.includes(node.id)) {
          subStyle = ' background';
        }
      }
      newNode.className = `${originClassName}${subStyle}`;
      newNode.data.isParentOfSelected = ancestorNodes.includes(newNode.id);
      newNode.hidden = isHidden(newNode);
      return newNode;
    });
  };

  useEffect(() => {
    setNodes(markRelatedNodes(nodes, edges));
  }, [highlightRelatedNodes]);

  const selectNode = (id: string) => {
    setNodes(
      markRelatedNodes(
        nodes.map((node) => ({
          ...node,
          selected: node.id === id,
        })),
        edges,
      ),
    );
    setEdges((prevEdges) =>
      prevEdges.map((edge) => ({
        ...edge,
        selected: false,
      })),
    );
  };

  const resetNodeSelection = (newNodes = nodes, newEdges = edges) => {
    setNodes(
      markRelatedNodes(
        newNodes.map((node) => ({
          ...node,
          selected: false,
        })),
        newEdges,
      ),
    );
  };
  const resetEdgeSelection = (newEdges = edges) => {
    setEdges(
      newEdges.map((edge) => ({
        ...edge,
        selected: false,
      })),
    );
  };
  const resetSelection = (newNodes = nodes, newEdges = edges) => {
    resetNodeSelection(newNodes, newEdges);
    resetEdgeSelection(newEdges);
  };

  const refreshIcons = (newNodes: Node[] = nodes) => {
    if (!equals(nodeIcons, {})) {
      if (currentWorkflow !== Workflow.MECHANISM_CREATION) {
        setNodeIcons({});
      }
      setNodes(() => {
        return newNodes.map((node) => {
          const newNode = node;
          const originalClassName =
            node.className
              ?.replace(' icon-mech-displayed', '')
              .replace(' icon-dist-displayed', '')
              .replace(' icon-missing-displayed', '')
              .replace(' icon-invalid-displayed', '') ?? '';

          const nodeIcon = nodeIcons[node.id];
          let substyle = '';
          if (nodeIcon !== undefined) substyle = ` icon-${nodeIcon}-displayed`;
          newNode.className = originalClassName + substyle;
          return newNode;
        });
      });
    }
  };

  useEffect(() => {
    refreshIcons();
  }, [nodeIcons]);

  useEffect(() => {
    if (nodeIdSelectedOutside !== undefined) {
      resetNodeSelection();
      selectNode(nodeIdSelectedOutside);
      const selectedNode = nodes.find((node) => node.id === nodeIdSelectedOutside);
      if (selectedNode) setSelectedNode(selectedNode);
    }
  }, [nodeIdSelectedOutside]);

  const onConnect = (connection: Connection) => {
    if (!connection.source || !connection.target || connection.source === connection.target) return;
    if (edges.filter((edge) => edge.source === connection.source && edge.target === connection.target).length > 0)
      return;
    const targetNode = nodes.find((node) => node.id === connection.target);
    if (targetNode?.data.kind === 'exogene') return;

    modelUpdateRequiredRef.current = true;
    mechanismsUpdateRequiredRef.current = true;
    const sourceNode = nodes.find((node) => node.id === connection.source);

    const edgeId = edges.length === 0 ? 1 : Math.max(...edges.map((edge) => Number(edge.id))) + 1;

    setEdges(
      setPorts(nodes, [
        ...edges,
        {
          source: connection.source,
          target: connection.target,
          id: edgeId.toString(),
          className: sourceNode?.data.data ? '' : 'nodata',
          hidden: sourceNode?.hidden,
        },
      ]),
    );
  };

  const getNewNodePos = () => {
    if (nodes.length === 0) return { x: 0, y: 0 };
    const visibleNodes = nodes.filter((n) => !n.hidden);
    let xMax = Math.max(...visibleNodes.map((n) => n.position.x));
    let xMin = Math.min(...visibleNodes.map((n) => n.position.x));
    let yMax = Math.max(...visibleNodes.map((n) => n.position.y));
    let yMin = Math.min(...visibleNodes.map((n) => n.position.y));
    const usedRange = 0.7;
    [xMin, xMax] = [
      (xMin + xMax) / 2 - 0.5 * usedRange * (xMax - xMin),
      (xMin + xMax) / 2 + 0.5 * usedRange * (xMax - xMin),
    ];
    [yMin, yMax] = [
      (yMin + yMax) / 2 - 0.5 * usedRange * (yMax - yMin),
      (yMin + yMax) / 2 + 0.5 * usedRange * (yMax - yMin),
    ];
    return { x: xMin + Math.random() * (xMax - xMin), y: yMin + Math.random() * (yMax - yMin) };
  };

  const onAddNode = (nodeToAdd: Node) => {
    const newNode = {
      ...nodeToAdd,
      position: getNewNodePos(),
      id: nodes.length ? (Math.max(...nodes.map((node) => Number(node.id))) + 1).toString() : '0',
    };
    newNode.selected = true;
    newNode.hidden = isHidden(newNode);
    modelUpdateRequiredRef.current = true;
    mechanismsUpdateRequiredRef.current = true;

    const [newNodes, newEdges] = [[...nodes, newNode], [...edges]];
    if (newNode.data.kind === 'endogene') {
      const newBackgroundNodeData = emptyNode;
      // const newBackgroundNodeName =  `U_${(newNode.data.name.match(/\b(\w){1,2}/g) ?? []).join('').toUpperCase()}`;
      const newBackgroundNodeName = createNameForExogeneParent(newNode, graph.state());
      const newBackgroundNode: Node = {
        id: (Number(newNode.id) + 1).toString(),
        className: 'nodata',
        type: 'MultiPortNode',
        position: { x: newNode.position.x, y: newNode.position.y - 80 },
        data: {
          ...newBackgroundNodeData,
          label: newBackgroundNodeName,
          name: newBackgroundNodeName,
          kind: 'exogene',
          isHiddenConfounder: false,
          isParentOfSelected: false,
          data: false,
        },
        selectable: false,
      };
      newBackgroundNode.hidden = isHidden(newBackgroundNode);
      newNodes.push(newBackgroundNode);

      const edgeId = edges.length === 0 ? 1 : Math.max(...edges.map((edge) => Number(edge.id))) + 1;
      const newEdge: Edge = {
        id: edgeId.toString(),
        className: 'nodata',
        source: newBackgroundNode.id.toString(),
        sourceHandle: 'b',
        target: newNode.id.toString(),
        targetHandle: 't',
        hidden: newBackgroundNode.hidden,
      };
      newEdges.push(newEdge);
    }
    setNodes(markRelatedNodes(newNodes, newEdges));
    setEdges(newEdges);
    onNodeClick(newNode.id);
  };

  const onEditNode = (nodeToEdit: Node) => {
    setNodes(
      nodes.map((node) =>
        node.id === nodeToEdit.id
          ? {
              ...nodeToEdit,
              width: node.width,
              height: node.height,
              position: node.position,
              selected: true,
              data: { ...nodeToEdit.data, isHiddenConfounder: isHiddenConfounder(nodeToEdit) },
            }
          : node,
      ),
    );
    setEdges(
      edges.map((edge) =>
        edge.source === nodeToEdit.id ? { ...edge, className: nodeToEdit.data.data ? '' : 'nodata' } : edge,
      ),
    );
    modelUpdateRequiredRef.current = true;
    mechanismsUpdateRequiredRef.current = true;
  };

  graph.setGraphChangedListener('GraphView', () => {
    const newData = GraphToFlow(graph.state());
    prevNodePositionsRef.current = Object.fromEntries(newData.nodes.map((n) => [n.id, n.position]));
    const updatedNodes = updateHidden(newData.nodes);
    prevHiddenNodesMapRef.current = Object.fromEntries(updatedNodes.map((node) => [node.id, node.hidden]));
    const updatedEdges = newData.edges.map((edge) => ({
      ...edge,
      hidden: prevHiddenNodesMapRef.current[edge.source],
    }));

    resetSelection(updatedNodes, setPorts(updatedNodes, updatedEdges));
    onNodeClick();

    // Causes blinking of nodes after undo/redo
    // Can use refreshIcons(updatedNodes); however, this will not update mechs
    // and destroys the layout of edges
    mechanismsUpdateRequiredRef.current = true;
  });

  const nodeSelected = nodes.find((x) => x.selected) !== undefined;
  const edgeSelected = edges.find((x) => x.selected) !== undefined;

  return (
    <ReactFlow
      nodes={nodes}
      nodeTypes={nodeTypes}
      edges={edges}
      nodeOrigin={[0.5, 0.5]}
      snapGrid={[rasterX, rasterY]}
      snapToGrid={rasterize}
      fitView
      minZoom={0.51}
      fitViewOptions={{ padding: 0.3 }}
      onNodesChange={onNodesChangeExtended}
      onEdgesChange={onEdgesChangeExtended}
      deleteKeyCode='Delete'
      connectionMode={ConnectionMode.Loose}
      onConnect={onConnect}
      onNodeClick={(_, node) => {
        selectNode(node.id);
        onNodeClick(node.id);
        setSelectedNode(node);
      }}
      onPaneClick={() => {
        resetSelection();
        onNodeClick();
      }}
      onEdgeClick={() => {
        resetNodeSelection();
        onNodeClick();
      }}
      onAuxClick={() => {
        resetSelection();
        onNodeClick();
      }}
      onNodeDrag={(_, node) => {
        if (nodes.filter((n) => n.selected && n.id !== node.id)) {
          setNodes(nodes.map((n) => (n.id === node.id ? node : { ...n, selected: false })));
        }
      }}
      onNodeDragStop={() => {
        checkNodePositionsRef.current = true;
      }}
    >
      <ControlsPanel
        modelId={model.state().id}
        rasterize={rasterize}
        toggleRasterization={toggleRasterization}
        // restoreUserLayout={restoreUserLayout}
        autoLayoutELK={() => autoLayoutELK()}
        autoLayoutDagre={() => autoLayoutDagre()}
        fitView={() => reactFlowInstance.fitView({ padding: 0.3 })}
        deleteSelected={nodeSelected || edgeSelected ? deleteSelected : undefined}
        zoomIn={() => reactFlowInstance.zoomIn()}
        zoomOut={() => reactFlowInstance.zoomOut()}
        undo={
          graph.canUndo() && model.canUndo()
            ? () => {
                graph.undo();
                model.undo();
              }
            : undefined
        }
        redo={
          graph.canRedo() && model.canRedo()
            ? () => {
                graph.redo();
                model.redo();
              }
            : undefined
        }
        onAddNode={onAddNode}
        onAddNodeDialogOpened={() => {
          resetSelection();
          rerender();
        }}
        onEditNode={nodeSelected ? onEditNode : undefined}
        onExportModel={() => {
          openExportModelGraphDialog(model.state(), graph.state());
        }}
        onSaveModel={async () => {
          if (await graph.save()) model.save();
        }}
      />
      {nodes.length === 0 && (
        <Panel className='graphView-panel-centered' position='top-center'>
          <button type='button' data-bs-toggle='modal' data-bs-target='#addNodeDialog' className='graphView-add-button'>
            <AddNodeIcon />
            {t('addNode.title')}
          </button>
        </Panel>
      )}
      <ActionsPanel onActionsChanged={onActionsChanged} />
      {rasterize && <Background gap={[rasterX, rasterY]} />}
      {currentWorkflow !== Workflow.CAUSAL_INFERENCE && (
        <Panel className='graphView-actions-panel' position='bottom-right'>
          <button
            type='button'
            onClick={() => onMechButtonClick('dist', model)}
            disabled={getNodesElligibleForDistribution(graph.state()).length === 0}
            hidden={currentWorkflow === Workflow.MECHANISM_CREATION}
            className={
              getNodesElligibleForDistribution(graph.state()).length > 0
                ? 'addNodeDialog__add-button'
                : 'addNodeDialog__add-button disabled text-muted'
            }
          >
            {t('graphView.enterDistributionsButton')}
          </button>
        </Panel>
      )}
    </ReactFlow>
  );
};

export const GraphView = (props: GraphViewProps): JSX.Element => {
  return (
    <ReactFlowProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <GraphViewImpl {...props} />
    </ReactFlowProvider>
  );
};
