import axios, { AxiosError } from 'axios';
import { getKeycloakToken } from '../../keycloak';

import { openAlertDialog } from './funcs';
import { BaseApi, Configuration, CornerCasesApi, IdentifiabilityApi, ModellingApi } from '../openapi';

export const realServerURL = process.env.REACT_APP_API_BASE_URL;

const realServer = axios.create({
  timeout: 180000,
  baseURL: realServerURL,
});

const showErrorMessage = (error: unknown): void => {
  const code: string = error instanceof AxiosError ? error.code ?? '' : '';
  let message: string =
    error instanceof AxiosError ? error.response?.data.error_message ?? String(error) : String(error);
  if (['ERR_NETWORK', 'ECONNABORTED'].includes(code)) message = 'no connection to backend server';
  openAlertDialog(message);
};

const networkErrorInterceptor = [
  (response) => response,
  (error) => {
    if (error instanceof AxiosError && ['ERR_NETWORK', 'ECONNABORTED'].includes(error.code ?? '')) {
      showErrorMessage(error);
      return error.response ?? undefined;
    }
    if (error.response.status === 401) {
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
];

realServer.interceptors.response.use(...networkErrorInterceptor);

const requestOptions = () => {
  return {
    headers: {
      'Authorization': `Bearer ${getKeycloakToken()}`,
      'Content-Type': 'application/json',
    },
  };
};

const getConfiguration = () => {
  return new Configuration({
    baseOptions: requestOptions(),
  });
};

export const getBaseApi = () => new BaseApi(getConfiguration(), realServer.getUri(), realServer);
export const getIdfyApi = () => new IdentifiabilityApi(getConfiguration(), realServer.getUri(), realServer);
export const getCocaApi = () => new CornerCasesApi(getConfiguration(), realServer.getUri(), realServer);
export const getModellingApi = () => new ModellingApi(getConfiguration(), realServer.getUri(), realServer);
