import { modelManager } from 'components/ModelTabs/ModelManager';
import { useRerender } from 'components/Utils/funcs';
import { openModelCreatorDialog, openModelLoaderDialog, openModelRenameDialog } from 'components/ModelTabs/ModelLoader';
import { NavbarElementInfo, NavbarMenu } from 'components/Navbar/NavbarMenu';
import { openExportModelGraphDialog } from 'components/ModelTabs/ModelExporter';
import { openUploadModelDialog } from 'components/ModelTabs/ModelUploader';
import { useContext } from 'react';
import { Workflow, WorkflowContext } from 'components/Utils/WorkflowContext';
import { useTranslation } from 'react-i18next';

export const Navbar = (): JSX.Element => {
  const { t } = useTranslation();
  const rerender = useRerender();
  const { currentWorkflow } = useContext(WorkflowContext);

  modelManager.setListener('Navbar', undefined, () => {
    rerender();
    modelManager.getActiveModel(currentWorkflow.getPage())?.setModelChangedListener('Navbar', rerender);
    modelManager.getActiveGraph(currentWorkflow.getPage())?.setGraphChangedListener('Navbar', rerender);
  });
  const ifActiveModel = (f?: () => void, and = true) =>
    modelManager.getActiveModel(currentWorkflow.getPage()) === undefined || !and ? undefined : f;

  /* const ifActiveGraph = (f?: () => void, and = true) =>
    modelManager.getActiveGraph(currentWorkflow.getPage()) === undefined || !and ? undefined : f;
  */
  const navElements: NavbarElementInfo[] = [
    {
      name: t('navbar.file'),
      items: [
        {
          name: t('navbar.new'),
          shortcut: t('navbar.newShortcut'),
          startsNewSection: false,
          onClick: openModelCreatorDialog,
        },
        {
          name: t('navbar.open'),
          shortcut: t('navbar.openShortcut'),
          startsNewSection: false,
          onClick: openModelLoaderDialog,
        },
        {
          name: t('navbar.save'),
          shortcut: t('navbar.saveShortcut'),
          startsNewSection: true,
          onClick: ifActiveModel(async () => {
            if (await modelManager.getActiveGraph(currentWorkflow.getPage())?.save())
              modelManager.getActiveModel(currentWorkflow.getPage())?.save();
          }),
        },
        {
          name: t('navbar.rename'),
          shortcut: t('navbar.renameShortcut'),
          startsNewSection: false,
          onClick: ifActiveModel(openModelRenameDialog),
        },
        {
          name: t('navbar.import'),
          shortcut: t('navbar.importShortcut'),
          startsNewSection: false,
          onClick: () => {
            openUploadModelDialog();
          },
        },
        {
          name: t('navbar.export'),
          shortcut: t('navbar.exportShortcut'),
          startsNewSection: false,
          onClick: ifActiveModel(() =>
            openExportModelGraphDialog(
              modelManager.getActiveModel(currentWorkflow.getPage())?.state(),
              modelManager.getActiveGraph(currentWorkflow.getPage())?.state(),
            ),
          ),
        },
        {
          name: t('navbar.close'),
          shortcut: t('navbar.closeShortcut'),
          startsNewSection: true,
          onClick: ifActiveModel(() => {
            modelManager.removeModel(
              currentWorkflow.getPage(),
              modelManager.getActiveModel(currentWorkflow.getPage())?.state().id,
            );
            modelManager.removeGraph(
              currentWorkflow.getPage(),
              modelManager.getActiveGraph(currentWorkflow.getPage())?.state().id,
            );
          }),
        },
      ],
    },
    {
      name: t('navbar.edit'),
      items: [
        {
          name: t('navbar.undo'),
          shortcut: t('navbar.undoShortcut'),
          startsNewSection: false,
          onClick: ifActiveModel(() => {
            modelManager.getActiveGraph(currentWorkflow.getPage())?.undo();
            modelManager.getActiveModel(currentWorkflow.getPage())?.undo();
          }, modelManager.getActiveGraph(currentWorkflow.getPage())?.canUndo() && modelManager.getActiveModel(currentWorkflow.getPage())?.canUndo()),
        },
        {
          name: t('navbar.redo'),
          shortcut: t('navbar.redoShortcut'),
          startsNewSection: false,
          onClick: ifActiveModel(() => {
            modelManager.getActiveGraph(currentWorkflow.getPage())?.redo();
            modelManager.getActiveModel(currentWorkflow.getPage())?.redo();
          }, modelManager.getActiveGraph(currentWorkflow.getPage())?.canRedo() && modelManager.getActiveModel(currentWorkflow.getPage())?.canRedo()),
        },
        {
          name: t('navbar.select'),
          shortcut: t('navbar.selectShortcut'),
          startsNewSection: true,
          onClick: undefined,
        },
        {
          name: t('navbar.unselect'),
          shortcut: t('navbar.unselectShortcut'),
          startsNewSection: false,
          onClick: undefined,
        },
        {
          name: t('navbar.settings'),
          shortcut: t('navbar.settingsShortcut'),
          startsNewSection: true,
          onClick: undefined,
        },
      ],
    },
    {
      name: t('navbar.help'),
      items: [{ name: t('navbar.help'), shortcut: '', startsNewSection: false, onClick: undefined }],
    },
  ];

  return (
    <nav className='navbar--navbar'>
      <div className='navbar--container'>
        <NavbarMenu navElements={navElements} />
        <span className='navbar--headline'>
          {(currentWorkflow === Workflow.GRAPH_CREATION || currentWorkflow === Workflow.MECHANISM_CREATION) &&
            t('sidebar.creation')}
          {currentWorkflow === Workflow.CAUSAL_INFERENCE && t('sidebar.inference')}
        </span>
        <form className='navbar--search' role='search'>
          <input type='search' placeholder={t('navbar.search')} />
        </form>
      </div>
    </nav>
  );
};
