import { Node, CausalGraph } from 'components/openapi';
import { equals, getNodeParents } from '../Utils/funcs';
import { getNodeAbbr } from '../Utils/Abbreviations';

export const findParamsInHeader = (header: string): string[] => {
  let trimmedHeader = header
    .replace('def f(', '')
    .replace(/\)(\s*->\s*[^:]+)?:\s*/, '')
    .replace(/lambda\s*/, '')
    .replaceAll(/:[^,]*/g, '')
    .replaceAll(/\s/g, '')
    .trim();
  while (trimmedHeader.charAt(trimmedHeader.length - 1) === ',')
    trimmedHeader = trimmedHeader.substring(0, trimmedHeader.length - 1);
  return trimmedHeader.split(',');
};

export const getFunctionHeader = (code: string): string | undefined => {
  const headerRegex = /(def [a-zA-Z]+\((([^,)])*,?)*\)(\s*->\s*[^:]+)?:)|(lambda( ([^,:]*,?))?:)/g;
  return code.match(headerRegex)?.[0];
};

export const hasCorrectParams = (header: string, node: Node, graph: CausalGraph): boolean => {
  const parentAbbrs = getNodeParents(node, graph).map((parentNode) => getNodeAbbr(parentNode));
  const codeParams = findParamsInHeader(header).filter((par) => par !== '' && par !== undefined);
  return equals(parentAbbrs.sort(), codeParams.sort());
};

export const checkMechanismCode = (code: string, node: Node, graph: CausalGraph): boolean => {
  const functionHeader = getFunctionHeader(code);
  return functionHeader !== undefined && hasCorrectParams(functionHeader, node, graph);
};

export const createDefaultMechanismCode = (node: Node, graph: CausalGraph): string => {
  const parentNodesAbbrs = [
    ...getNodeParents(node, graph).filter((foundNode) => foundNode.kind === 'endogene'),
    ...getNodeParents(node, graph).filter((foundNode) => foundNode.kind === 'exogene'),
  ].map((foundNode) => getNodeAbbr(foundNode));

  return `def f(${parentNodesAbbrs
    .map((abbr) => `${abbr}: npt.NDArray`)
    .join(', ')}) -> npt.NDArray:\n    return ${parentNodesAbbrs.join(' + ')}\n    `;
};

export const isDefaultMech = (code: string | undefined, node: Node, graph: CausalGraph): boolean => {
  const symbolsWithoutMeaning = [' ', '\n', '\t', ':npt.NDArray', '->npt.NDArray'];
  if (code === undefined) return false;
  let actualCode = code;
  let defaultCode = createDefaultMechanismCode(node, graph);
  symbolsWithoutMeaning.forEach((symbol) => {
    actualCode = actualCode.replaceAll(symbol, '');
    defaultCode = defaultCode.replaceAll(symbol, '');
  });

  return actualCode === defaultCode;
};
