import { HistoryState } from 'components/Utils/HistoryState';
import { EventHandler } from 'components/Utils/EventHandler';
import { addMissingCausalGraphData, makeEmptyCausalGraph } from 'components/Utils/emptyModelGraph';
import { CausalGraph } from 'components/openapi';
import { WorkflowPage } from 'components/Utils/WorkflowContext';
import { AxiosError } from 'axios';
import { openAlertDialog } from 'components/Utils/funcs';
import { getModellingApi } from '../Utils/ServerHolder';

export class Graph {
  private history: HistoryState<CausalGraph>;

  private eventHandler: EventHandler<'onGraphChanged'>;

  private resetHist: boolean;

  private workflows: WorkflowPage[];

  constructor(data: CausalGraph, workflow: WorkflowPage) {
    this.history = new HistoryState({ initState: addMissingCausalGraphData({ ...data }) });
    this.eventHandler = new EventHandler();
    this.resetHist = false;
    this.workflows = [workflow];
  }

  setGraphChangedListener(id: string, callback: () => void): void {
    this.eventHandler.setListener(id, 'onGraphChanged', callback);
  }

  state(): CausalGraph {
    return this.history.state() ?? makeEmptyCausalGraph();
  }

  setState(state: CausalGraph, skipListeners: string[] = []): void {
    if (this.resetHist) {
      this.history.clear();
      this.resetHist = false;
    }
    this.history.setState(state);
    this.eventHandler.callEventListeners('onGraphChanged', skipListeners);
  }

  resetHistOnNextState(): void {
    this.resetHist = true;
  }

  undo(skipListeners: string[] = []): void {
    this.history.undo();
    this.eventHandler.callEventListeners('onGraphChanged', skipListeners);
  }

  redo(skipListeners: string[] = []): void {
    this.history.redo();
    this.eventHandler.callEventListeners('onGraphChanged', skipListeners);
  }

  canUndo(): boolean {
    return this.history.canUndo();
  }

  canRedo(): boolean {
    return this.history.canRedo();
  }

  async save(): Promise<boolean> {
    if (!this.state().id) {
      const response = await getModellingApi().apiPostCausalGraphs(this.state());
      this.state().id = response.data.id;
      return true;
    }

    try {
      const saveResponse = await getModellingApi().apiPutCausalGraphs(this.state().id, this.state());
      return saveResponse !== undefined;
    } catch (error) {
      openAlertDialog(
        `Graph could not be saved\n${
          error instanceof AxiosError ? error.response?.data?.error_message : String(error)
        }`,
      );
      return false;
    }
  }

  isVisibleInWorkflow(workflow: WorkflowPage): boolean {
    return this.workflows.includes(workflow);
  }

  makeVisibleInWorkflow(workflow: WorkflowPage): void {
    if (!this.isVisibleInWorkflow(workflow)) {
      this.workflows.push(workflow);
    }
  }

  makeUnvisibleInWorkflow(workflow: WorkflowPage): void {
    if (this.isVisibleInWorkflow(workflow)) {
      this.workflows = this.workflows.filter((wflow) => wflow !== workflow);
    }
  }

  getWorkflows(): WorkflowPage[] {
    return this.workflows;
  }
}
