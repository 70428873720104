import { AxiosResponse } from 'axios';
import { BaseQuery, BaseAnswer, IdfyQuery, IdfyAnswer, CoCaQuery, CoCaAnswer } from 'components/openapi';
import { getBaseApi, getCocaApi, getIdfyApi } from './ServerHolder';

/* import { QueryResponse } from 'components/QueryTabs/QueryResponse';
import { CCAnswer, CCQuery } from 'components/CCTabs/CCResponse';
import { IdentifiabilityResponse } from 'components/IdentifiabilityTabs/IdentifiabilityResponse'; 
import { openAlertDialog } from 'components/Utils/funcs';
import { CausalGraph, CausalModel } from 'components/openapi'; 

export type ModelInformation = {
  id: string;
  graph_id: string;
  name: string;
  lastModified: string;
}[]; */

const WRONG_BACKEND_ERROR = 'Wrong backend started.';
const WRONG_QUERY_ERROR = 'Incorrect query format';
const WRONG_DISTRIBUTION_PARAMS_ERROR = 'Angegebene Dichtefunktion konnte nicht berechnet werden.';

const waitUntilQueryCompleted = async (
  getQuery: () => Promise<AxiosResponse<CoCaQuery | IdfyQuery | BaseQuery>>,
  setProgress: (progress: number) => void,
  intervalMs = 700,
  timeout = 1200000,
) => {
  /* Periodically updates progress until query solved, invalid or timeout reached */
  const currentQueryStatus = await getQuery();
  const solved =
    currentQueryStatus?.data?.progress === 100 ||
    currentQueryStatus?.data?.status === 'solved' ||
    currentQueryStatus?.data?.status === 'invalid';
  setProgress(solved ? 100 : currentQueryStatus?.data?.progress ?? 0);
  if (!solved && timeout > 0)
    setTimeout(() => waitUntilQueryCompleted(getQuery, setProgress, intervalMs, timeout - intervalMs), intervalMs);
};

export const postBaseQueryGetAnswer = async (
  query: BaseQuery,
  setProgress?: (progress: number) => void,
): Promise<BaseAnswer | undefined> => {
  const addDensityFunctionError = (answer: BaseAnswer): BaseAnswer => {
    if (!answer.body?.histogram && !answer.error_message && new Set(answer.body?.scatter?.x).size <= 1) {
      return { ...answer, error_message: WRONG_DISTRIBUTION_PARAMS_ERROR };
    }
    return answer;
  };

  let postedQuery;
  try {
    postedQuery = await getBaseApi().apiPostBaseQueries(query);
  } catch (error) {
    return { error_message: WRONG_BACKEND_ERROR, id: '', query: query.body };
  }
  if (!postedQuery?.data?.id) return { error_message: WRONG_BACKEND_ERROR, id: '', query: query.body };

  const returnedQuery = await getBaseApi().apiGetBaseQueries(postedQuery.data.id);
  const answerId = returnedQuery?.data?.answer ?? '';

  if (setProgress)
    await waitUntilQueryCompleted(async () => {
      return getBaseApi().apiGetBaseQueries(postedQuery.data.id);
    }, setProgress);

  if (!answerId) return { error_message: 'Answer not found', id: answerId, query: query.body };
  try {
    const baseAnswer = (await getBaseApi().apiGetBaseAnswers(answerId)).data;
    return addDensityFunctionError(baseAnswer);
  } catch (error) {
    return { error_message: WRONG_QUERY_ERROR, id: answerId, query: query.body };
  }
};

export const postIdfyQueryGetAnswer = async (
  query: IdfyQuery,
  setProgress?: (progress: number) => void,
): Promise<IdfyAnswer | undefined> => {
  let postedQuery;
  try {
    postedQuery = await getIdfyApi().apiPostIdfyQueries(query);
  } catch (error) {
    return { error_message: WRONG_BACKEND_ERROR, id: '', query: query.body };
  }
  if (!postedQuery?.data?.id) return { error_message: WRONG_BACKEND_ERROR, id: '', query: query.body };

  const returnedQuery = await getIdfyApi().apiGetIdfyQueries(postedQuery.data.id);
  const answerId = returnedQuery?.data?.answer ?? '';

  if (setProgress)
    await waitUntilQueryCompleted(async () => {
      return getIdfyApi().apiGetIdfyQueries(postedQuery.data.id);
    }, setProgress);

  if (!answerId) return { error_message: 'Answer not found', id: answerId, query: query.body };

  try {
    const baseAnswer = (await getIdfyApi().apiGetIdfyAnswers(answerId)).data;
    return baseAnswer;
  } catch (error) {
    return { error_message: WRONG_QUERY_ERROR, id: answerId, query: query.body };
  }
};

export const postCoCaQuery = async (
  query: CoCaQuery,
  setProgress?: (progress: number) => void,
): Promise<CoCaAnswer> => {
  let postedQuery;
  try {
    postedQuery = await getCocaApi().apiPostCocaQueries(query);
  } catch (error) {
    return { error_message: WRONG_BACKEND_ERROR, id: '', query: '' };
  }

  if (!postedQuery?.data?.id) {
    return { error_message: WRONG_BACKEND_ERROR, id: '', query: '' };
  }

  const returnedQuery = await getCocaApi().apiGetCocaQueries(postedQuery.data.id);
  const answerId = returnedQuery?.data?.answer ?? '';

  if (setProgress)
    await waitUntilQueryCompleted(async () => {
      return getCocaApi().apiGetCocaQueries(postedQuery.data.id);
    }, setProgress);
  return { error_message: '', id: answerId, query: '' };
};

export const getCoCaAnswer = async (answerId: string): Promise<CoCaAnswer | undefined> => {
  if (!answerId) return { error_message: 'Answer not found', id: '', query: '' };

  try {
    const baseAnswer = (await getCocaApi().apiGetCocaAnswers(answerId)).data;
    if (baseAnswer.error_message && !Number.isNaN(baseAnswer.error_message))
      baseAnswer.error_message = 'Backend does not support this model for Corner Cases.';
    return baseAnswer;
  } catch (error) {
    return {
      error_message: WRONG_BACKEND_ERROR,
      id: answerId,
      query: '',
    };
  }
};

/*
let finalInterceptorIndex = realServer.interceptors.response.use(...networkErrorInterceptor);

export const addResponseInterceptor = (newInterceptor: typeof networkErrorInterceptor): void => {
  realServer.interceptors.response.eject(finalInterceptorIndex);
  realServer.interceptors.response.use(...newInterceptor);
  finalInterceptorIndex = realServer.interceptors.response.use(...networkErrorInterceptor);
};


const mockServer = axios.create({
  timeout: 3000,
  baseURL: 'http://127.0.0.1:5001/v1/',
});



export const availableModels = async (): Promise<ModelInformation> => {
  try {
    const response = await mockServer.get('causal-models');
    return response.data.models.map((model: { model_id: string; model_name: string; last_modified: string }) => ({
      id: model.model_id,
      name: model.model_name,
      graph_id: '',
      lastModified: model.last_modified,
    }));
  } catch (error) {
    showErrorMessage(error);
    return [];
  }
};

export const saveModel = async (causalModel: CausalModel): Promise<string> => {
  try {
    if (causalModel.id === undefined) {
      const response = await mockServer.post('causal-models', {
        model: causalModel,
      });
      return response.data.model_id;
    }
    mockServer.put(`causal-models/${causalModel.id}`, {
      model: causalModel,
    });
  } catch (error) {
    showErrorMessage(error);
  }
  return causalModel.id ?? '';
};

export const saveGraph = async (causalGraph: CausalGraph): Promise<string> => {
  try {
    if (causalGraph.id === undefined) {
      const response = await mockServer.post('causal-graphs', {
        model: causalGraph,
        layout: causalGraph.info?.frontend?.layout,
      });
      return response.data.model_id;
    }
    mockServer.put(`causal-graphs/${causalGraph.id}`, {
      model: causalGraph,
      layout: causalGraph.info?.frontend?.layout,
    });
  } catch (error) {
    showErrorMessage(error);
  }
  return causalGraph.id ?? '';
};

export const getModel = async (id: string): Promise<CausalModel | undefined> => {
  try {
    const response = await mockServer.get(`causal-models/${id}`);
    const { model } = response.data as { model: CausalModel };
    model.id = id;
    return model;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const getGraph = async (id: string): Promise<CausalGraph | undefined> => {
  try {
    const response = await mockServer.get(`causal-graphs/${id}`);
    const { graph } = response.data as { graph: CausalGraph };
    graph.id = id;
    return graph;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const sendQuery = async (id: string, query: string): Promise<string | undefined> => {
  try {
    const response = await realServer.post('base-queries', {
      body: query,
      object: {
        id,
        type: 'model',
      },
      type: 'default',
    });
    return response.data.id;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const sendCCQuery = async (causal_model: string, user_input: CCQuery): Promise<string | undefined> => {
  try {
    const response = await realServer.post('cc-queries', user_input);
    // {
    //  causal_model: `causal_model`,
    //  target_node: `user_input.target_node`,
    //  cc_nodes: `user_input.cc_nodes`,
    //  count: `user_input.count`,
    //  target_range: { range: `user_input.target_range` },
    // });
    return response.data.id;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const getCCQuery = async (id: string): Promise<CCQuery | undefined> => {
  try {
    const response = await realServer.get(`cc-queries/${id}`);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const getQueryAnswer = async (id: string): Promise<QueryResponse | undefined> => {
  try {
    const response = await realServer.get(`base-answers/${id}`);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const getCCAnswer = async (id: string): Promise<CCAnswer | undefined> => {
  try {
    const response = await realServer.get(`cc-answers/${id}`);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const sendIdentifiabilityQuery = async (id: string, query: string): Promise<string | undefined> => {
  try {
    const response = await realServer.post('queries', {
      body: query,
      object: {
        id,
        type: 'model',
      },
      type: 'identifiability',
    });
    return response.data.id;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};

export const getIdentifiabilityQueryAnswer = async (id: string): Promise<IdentifiabilityResponse | undefined> => {
  try {
    const response = await realServer.get(`answers/${id}`);
    return response.data;
  } catch (error) {
    showErrorMessage(error);
  }
  return undefined;
};
*/
