import { Edge as FlowEdge, Node as FlowNode } from 'reactflow';
/* import {
  Link as HolmesEdge,
  Node as HolmesNode,
  NodeLayoutInfo as HolmesLayout,
} from 'components/Utils/emptyModelGraph';
*/
import { CausalGraphLinksInner, Node as CausalGraphNode, CausalGraph, CausalGraphInfo } from 'components/openapi';

type Flow = { nodes: FlowNode[]; edges: FlowEdge[] };
type Holmes = {
  links: Set<CausalGraphLinksInner>;
  nodes: Set<CausalGraphNode>;
  info: CausalGraphInfo;
};

export const GraphToFlow = (causalGraph: CausalGraph): Flow => {
  const layout = causalGraph.info?.frontend?.layout ?? [];
  const layoutInfoMap = Object.fromEntries(layout.map((nodeInfo) => [nodeInfo.id, nodeInfo]));
  const defaultLayoutInfo = { x: 0, y: 0, width: null, height: null };
  const nodes: FlowNode[] = Array.from(causalGraph.nodes).map((node) => {
    const layoutInfo = layoutInfoMap[node.id] ?? defaultLayoutInfo;
    return {
      id: node.id.toString(),
      className: node.data ? '' : 'nodata',
      type: 'MultiPortNode',
      data: {
        label: node.name + (node.abbr !== '' && node.abbr !== undefined ? ` (${node.abbr})` : ''),
        isParentOfSelected: false,
        isHiddenConfounder:
          node.kind === 'exogene' &&
          !node.data &&
          Array.from(causalGraph.links).filter((l) => l.from === node.id).length > 1,
        ...node,
      },
      position: { x: layoutInfo.x, y: layoutInfo.y },
      width: layoutInfo.width,
      height: layoutInfo.height,
      selectable: false,
      hidden: false,
    };
  });

  const hasDataMap = Object.fromEntries(nodes.map((node) => [node.id, node.data.data]));
  const edges: FlowEdge[] = Array.from(causalGraph.links).map((link) => ({
    id: link.id.toString(),
    className: hasDataMap[link.from] ? '' : 'nodata',
    source: link.from.toString(),
    sourceHandle: 'b',
    target: link.to.toString(),
    targetHandle: 't',
    hidden: false,
  }));

  return { nodes, edges };
};

export const flowToHolmes = (flow: Flow, oldGraphState: CausalGraph): Holmes => {
  const nodes = flow.nodes.map((node) => {
    // Destructured id is empty
    const { label, isHiddenConfounder, isParentOfSelected, icon, name, id, ...nodeData } = node.data;
    return {
      id: Number(node.id),
      name,
      ...nodeData,
    };
  });

  const links = flow.edges.map((edge) => ({
    from: Number(edge.source),
    id: Number(edge.id),
    to: Number(edge.target),
  }));

  const layout = flow.nodes.map((node) => ({
    id: Number(node.id),
    width: node.width ?? 0,
    height: node.height ?? 0,
    ...node.position,
  }));

  const info = { ...oldGraphState.info, frontend: { layout } };

  return { nodes: new Set(nodes), links: new Set(links), info };
};
