import PropTypes from 'prop-types';

export const FormInput = (props) => {
  const { id, name, placeholder, value, onChange, required, label, noLabel, type, min, max } = props;
  return (
    <>
      {!noLabel && (
        <label htmlFor={id} className='form-label'>
          {label}
        </label>
      )}

      <input
        type={type || 'text'}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        className='form-control'
      />
    </>
  );
};

FormInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.string,
  label: PropTypes.string,
  noLabel: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.string,
  max: PropTypes.string,
};
