import { useRerender, formatLatex } from 'components/Utils/funcs';
import { postIdfyQueryGetAnswer } from 'components/Utils/BackendApi';
import Latex from 'react-latex';
/* import 
  getQueryAnswer,
  sendIdentifiabilityQuery,
  getIdentifiabilityQueryAnswer,
} from 'components/Utils/BackendApi'; */
import { IdfyAnswer, IdfyQuery } from 'components/openapi';
import { textWithLineBreak } from 'components/QueryTabs/QueryTab';
import { useTranslation } from 'react-i18next';

export const codeWithLineBreak = (text: string, className?: string): JSX.Element[] => {
  return text.split('\n').map((line) => (
    <p className={className} key={line}>
      <pre> {line} </pre>
    </p>
  ));
};
const queries: Record<string, { input: string; query: IdfyQuery; estimandId: string; response?: IdfyAnswer }> = {};
const queries2: Record<string, { query: IdfyQuery; response?: IdfyAnswer }> = {};

export const IdentifiabilityTab = ({ modelId }: { modelId: string }): JSX.Element => {
  const { t } = useTranslation();

  const rerender = useRerender();

  // if (queries[modelId] === undefined) queries[modelId] = { query: '' };
  // const { query, response } = queries[modelId];
  if (queries[modelId] === undefined) {
    queries[modelId] = {
      input: '',
      query: {
        object: {
          id: modelId,
          type: 'model',
        },
        body: '',
        type: 'estimand',
      },
      estimandId: '',
    };
  }
  const { input, query, response } = queries[modelId];

  if (queries2[modelId] === undefined) {
    queries2[modelId] = {
      query: {
        object: {
          id: modelId,
          type: 'model',
        },
        body: '',
        type: 'estimate',
      },
    };
  }
  const { query: query2, response: response2 } = queries2[modelId];

  const handleSubmitEstimand = async (event) => {
    event.preventDefault();
    query.body = input;

    const idfyAnswer = await postIdfyQueryGetAnswer(query);
    if (idfyAnswer) {
      queries[modelId].response = idfyAnswer;
      queries[modelId].estimandId = response?.id || '';
      queries2[modelId].response = undefined;
      rerender();
    }
  };

  const handleSubmitEstimate = async (event) => {
    event.preventDefault();
    query.body = input;

    queries2[modelId].query.body = response?.body?.text || '';
    const estimateAnswer = await postIdfyQueryGetAnswer(query2);
    if (estimateAnswer) {
      queries2[modelId].response = estimateAnswer;
      rerender();
    }
  };

  return (
    <>
      <div className='identifiability-tab-container'>
        <form onSubmit={handleSubmitEstimand}>
          <p className='text-muted'>{t('identifiabilitySideTab.hint')}</p>
          <input
            placeholder={t('identifiabilitySideTab.placeholder')}
            value={input}
            onChange={(e) => {
              queries[modelId].input = e.target.value;
              queries[modelId].query.type = 'estimand';
              rerender();
            }}
          />
          <button type='submit' className={input ? '' : 'disabled text-muted'} disabled={!input}>
            {t('identifiabilitySideTab.button')}
          </button>
        </form>
        {response && (
          <>
            <p className='text-muted pt-3'>{t('identifiabilitySideTab.resultTitle')}</p>
            {response?.error_message &&
              textWithLineBreak(response.error_message, 'pb-3 identifiability-tab-error-message')}
            {response?.body?.text &&
              !response?.body?.latex &&
              textWithLineBreak(response.body.text, 'pb-3 identifiability-tab-text')}
            {response?.body?.latex && (
              <Latex className='pb-3 identifiability-tab-text' displayMode>
                {formatLatex(response.body.latex, true)}
              </Latex>
            )}
          </>
        )}
      </div>
      <div className='identifiability-tab-container'>
        <form onSubmit={handleSubmitEstimate}>
          <p className='text-muted'>{t('identifiabilitySideTab.hint2')}</p>
          <button type='submit' className={response?.body ? '' : 'disabled text-muted'} disabled={!response?.body}>
            {t('identifiabilitySideTab.button')}
          </button>
        </form>
        {response2 && (
          <>
            <p className='text-muted pt-3'>{t('identifiabilitySideTab.resultTitle2')}</p>
            {response2?.error_message &&
              textWithLineBreak(response2.error_message, 'pb-3 identifiability-tab-error-message')}
            {response2?.body?.text && textWithLineBreak(response2.body.text, 'pb-3 identifiability-tab-text')}
          </>
        )}
      </div>
    </>
  );
};
