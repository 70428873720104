import { useEffect, useRef, useState } from 'react';
import { Tab, TabMenuPosition, TabView } from 'components/Utils/TabView';
import { SplitScreen } from 'components/Utils/SplitScreen';
import { CollapsableArea, DisplayMode } from 'components/Utils/CollapsableArea';
import { useTranslation } from 'react-i18next';
import { useRerender } from './funcs';

export const Workspace = ({
  mainElement,
  sideTabs,
  forceOpenTab,
  activeTabChanged,
}: {
  mainElement: JSX.Element;
  sideTabs: Tab[];
  forceOpenTab?: [string | undefined, number];
  activeTabChanged: (id: string) => void;
}): JSX.Element => {
  const { t } = useTranslation();
  const [sideViewDisplayMode, setSideViewDisplayMode] = useState(DisplayMode.DEFAULT);
  const lastActiveTabIdRef = useRef(sideTabs[0].id);
  const rerender = useRerender();

  useEffect(() => {
    if (forceOpenTab && forceOpenTab[0] !== undefined) {
      [lastActiveTabIdRef.current] = forceOpenTab;
      rerender();
      if (sideViewDisplayMode === DisplayMode.MINIMIZED) {
        setSideViewDisplayMode(DisplayMode.DEFAULT);
      }
    }
  }, [forceOpenTab]);
  const sideView: JSX.Element = (
    <TabView
      tabs={sideTabs.map((tab) => ({
        ...tab,
        content:
          sideViewDisplayMode === DisplayMode.MINIMIZED ? (
            <div className='px-2' style={{ width: '0.3125rem' }} />
          ) : (
            <div className='workspace--side-view'>
              <CollapsableArea
                title={t(tab.title)}
                content={tab.content}
                displayMode={sideViewDisplayMode}
                onDisplayModeChanged={(newDisplayMode) => setSideViewDisplayMode(newDisplayMode)}
              />
            </div>
          ),
      }))}
      initialActiveTabId={sideViewDisplayMode === DisplayMode.MINIMIZED ? '' : lastActiveTabIdRef.current}
      menuPosition={TabMenuPosition.RIGHT}
      callbacks={{
        onActiveTabChanged: (id) => {
          if (sideViewDisplayMode === DisplayMode.MINIMIZED) {
            setSideViewDisplayMode(DisplayMode.DEFAULT);
          }
          activeTabChanged(id);
          lastActiveTabIdRef.current = id;
        },
      }}
    />
  );

  const mainView: JSX.Element = <div className='workspace--main-view'>{mainElement}</div>;

  if (sideViewDisplayMode === DisplayMode.MAXIMIZED) return sideView;
  if (sideViewDisplayMode === DisplayMode.MINIMIZED)
    return (
      <div className='d-flex h-100 w-100'>
        {mainView}
        {sideView}
      </div>
    );
  return <SplitScreen leftPaneContent={mainView} rightPaneContent={sideView} />;
};
