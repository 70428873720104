import { useContext, useEffect, useState } from 'react';
import { modelManager } from 'components/ModelTabs/ModelManager';
import { Model } from 'components/ModelTabs/Model';
import {
  addMissingCausalGraphData,
  addMissingCausalModelData,
  makeEmptyCausalGraph,
  makeEmptyCausalModel,
} from 'components/Utils/emptyModelGraph';
import { openUploadModelDialog } from 'components/ModelTabs/ModelUploader';
import { Graph } from 'components/ModelTabs/Graph';
import { CausalModel } from 'components/openapi';
import { formatISOTime, sortISOStrings, useRerender } from 'components/Utils/funcs';
import { ReactComponent as TrashIcon } from 'resources/assets/icons/icons8-trash.svg';
import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { Workflow, WorkflowContext, WorkflowPage } from '../Utils/WorkflowContext';
import { getModellingApi } from '../Utils/ServerHolder';

const MODEL_LOADER_ID = 'MODEL_LOADER';
export const openModelLoaderDialog = () => {
  document.getElementById(MODEL_LOADER_ID)?.click();
};

const MODEL_CREATOR_ID = 'MODEL_CREATOR';
export const openModelCreatorDialog = () => {
  document.getElementById(MODEL_CREATOR_ID)?.click();
};

export const loadModel = async (modelId: string, workflow: WorkflowPage) => {
  const newCausalModel = (await getModellingApi().apiGetCausalModels(modelId)).data;
  if (newCausalModel) {
    newCausalModel.distributions = new Set(newCausalModel.distributions);
    newCausalModel.mechanisms = new Set(newCausalModel.mechanisms);

    const newCausalGraph = (await getModellingApi().apiGetCausalGraphs(newCausalModel.causal_graph)).data;
    if (newCausalGraph) {
      newCausalGraph.nodes = new Set(newCausalGraph.nodes);
      newCausalGraph.links = new Set(newCausalGraph.links);

      const newGraph = new Graph(newCausalGraph, workflow);
      modelManager.addGraph(newGraph);
      modelManager.setGraphAsActive(workflow, newGraph.state().id);
    }

    const newModel = new Model(newCausalModel, workflow);
    modelManager.addModel(newModel);
    modelManager.setModelAsActive(workflow, newModel.state().id);
  }
};

export const createModel = async (name: string) => {
  const newCausalGraph = addMissingCausalGraphData(makeEmptyCausalGraph());
  newCausalGraph.name = name;

  const newCausalModel = addMissingCausalModelData(makeEmptyCausalModel());
  newCausalModel.causal_graph = newCausalGraph.id;
  newCausalModel.name = name;

  const newGraph = new Graph(newCausalGraph, Workflow.GRAPH_CREATION.getPage());

  const newModel = new Model(newCausalModel, Workflow.GRAPH_CREATION.getPage());
  if (await newGraph.save()) newModel.save();
  modelManager.addModel(newModel);
  modelManager.addGraph(newGraph);
  modelManager.setModelAsActive(Workflow.GRAPH_CREATION.getPage(), newModel.state().id);
  modelManager.setGraphAsActive(Workflow.GRAPH_CREATION.getPage(), newGraph.state().id);
};

export const ModelCreatorDialog = () => {
  const { t } = useTranslation();

  const [modelName, setModelName] = useState('');

  useEffect(() => {
    const modelCreatorDialog = document.getElementById('modelCreator');
    const showModelCreator = () => {
      setModelName('');
    };
    modelCreatorDialog?.addEventListener('show.bs.modal', showModelCreator);
    return () => {
      modelCreatorDialog?.removeEventListener('show.bs.modal', showModelCreator);
    };
  }, []);

  const { setCurrentWorkflow } = useContext(WorkflowContext);

  return (
    <>
      {/* eslint-disable-next-line */}
      <button
        type='button'
        id={MODEL_CREATOR_ID}
        data-bs-toggle='modal'
        data-bs-target='#modelCreator'
        style={{ display: 'none' }}
      />
      <div className='modal fade' id='modelCreator' data-bs-backdrop='static' data-bs-keyboard='true' tabIndex={-1}>
        <div className='modal-dialog'>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (modelName === '') return;
              setCurrentWorkflow(Workflow.GRAPH_CREATION);
              createModel(modelName);
            }}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{t('model.creatorDialogTitle')}</h5>
                <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' />
              </div>
              <div className='modal-body'>
                <div className='addNodeDialog__attribute-wrapper'>
                  <input
                    className='addNodeDialog__edit-text'
                    type='text'
                    placeholder={t('model.creatorDialogInputPlaceholder')}
                    value={modelName}
                    onChange={(event) => {
                      setModelName(event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='abort-btn' data-bs-dismiss='modal'>
                  {t('model.dialogAbort')}
                </button>
                <button type='submit' data-bs-dismiss='modal' disabled={modelName === ''}>
                  {t('model.creatorDialogCreate')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export const deleteModel = async (modelId: string, graphId: string) => {
  await getModellingApi().apiDelCausalModels(modelId);
  await getModellingApi().apiDelCausalGraphs(graphId);
};

export const ModelLoaderPage = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className='model-loader-page'>
      <p className='text-muted'>{t('model.uploadWelcomeText')}</p>
      <button type='button' onClick={openModelLoaderDialog}>
        {t('model.openButton')}
      </button>
      <button type='button' onClick={openUploadModelDialog}>
        {t('model.uploadButton')}
      </button>
      <button
        type='button'
        onClick={openModelCreatorDialog}
        hidden={useContext(WorkflowContext).currentWorkflow === Workflow.CAUSAL_INFERENCE}
      >
        {t('model.createButton')}
      </button>
    </div>
  );
};

const CLOSE_MODAL_LOADER_ID = 'CLOSE_MODAL_LOADER';
export const ModelLoaderDialog = (): JSX.Element => {
  const { t } = useTranslation();
  const [modelList, setModelList] = useState<CausalModel[]>([]);
  const [selectedModel, setSelectedModel] = useState<string>();
  const [modelToDelete, setModelToDelete] = useState<CausalModel | undefined>(undefined);
  const rerender = useRerender();

  useEffect(() => {
    const modelDeleteConfirmDialog = document.getElementById('modelDeleteConfirm');

    const hideDeleteConfirmDialog = () => {
      setModelToDelete(undefined);
      openModelLoaderDialog();
    };

    modelDeleteConfirmDialog?.addEventListener('hide.bs.modal', hideDeleteConfirmDialog);
    return () => {
      modelDeleteConfirmDialog?.removeEventListener('hide.bs.modal', hideDeleteConfirmDialog);
    };
  }, []);

  useEffect(() => {
    const modelLoaderDialog = document.getElementById('modelLoader');
    const showModelLoader = async () => {
      setModelList((await getModellingApi().apiSearchCausalModels())?.data ?? []);
    };

    const hideModelLoader = () => {
      setSelectedModel(undefined);
    };

    modelLoaderDialog?.addEventListener('show.bs.modal', showModelLoader);
    modelLoaderDialog?.addEventListener('hide.bs.modal', hideModelLoader);
    return () => {
      modelLoaderDialog?.removeEventListener('show.bs.modal', showModelLoader);
      modelLoaderDialog?.removeEventListener('hide.bs.modal', hideModelLoader);
    };
  }, []);

  const { currentWorkflow, setCurrentWorkflow } = useContext(WorkflowContext);

  return (
    <>
      {/* eslint-disable-next-line */}
      <button
        type='button'
        id={MODEL_LOADER_ID}
        data-bs-toggle='modal'
        data-bs-target='#modelLoader'
        style={{ display: 'none' }}
      />
      <div className='modal fade' id='modelDeleteConfirm' data-bs-keyboard='true' tabIndex={-2}>
        <div className='modal-dialog modal-xs'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{t('model.loaderConfirmDeleteDialogTitle')}</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' />
            </div>
            <div className='modal-body'>
              <p style={{ whiteSpace: 'pre-wrap' }}>
                {t('model.loaderConfirmDeleteDialogBody1')}
                <b>{modelToDelete?.name}</b>
                {t('model.loaderConfirmDeleteDialogBody2')}
              </p>
            </div>
            <div className='modal-footer'>
              <button type='button' className='abort-btn' data-bs-dismiss='modal'>
                {t('model.dialogAbort')}
              </button>
              <button
                type='button'
                onClick={async () => {
                  if (modelToDelete !== undefined) {
                    await deleteModel(modelToDelete.id, modelToDelete.causal_graph);
                    if (selectedModel === modelToDelete.id) setSelectedModel(undefined);

                    setModelList(modelList.filter((causalModel) => causalModel.id !== modelToDelete.id));
                    setModelToDelete(undefined);
                  }
                  rerender();
                }}
                data-bs-dismiss='modal'
              >
                {t('model.loaderConfirmDeleteDialogDelete')}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className='modal fade' id='modelLoader' data-bs-backdrop='static' data-bs-keyboard='true' tabIndex={-1}>
        <div className='modal-dialog modal-xl'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>{t('model.loaderDialogTitle')}</h5>
              <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' />
            </div>
            <div className='modal-body'>
              {modelList.length === 0 ? (
                <span className='text-muted'>{t('model.loaderDialogPlaceholderHint')}</span>
              ) : (
                <div className='table-container'>
                  <table className='table table-hover'>
                    <thead>
                      <tr>
                        <th scope='col'>{t('model.loaderDialogTableColName')}</th>
                        <th scope='col'>{t('model.loaderDialogTableColDescription')}</th>
                        <th scope='col'>{t('model.loaderDialogTableColLastChanged')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {modelList
                        .sort((a, b) =>
                          sortISOStrings(a.info?.frontend?.last_modified, b.info?.frontend?.last_modified, false),
                        )
                        .map((model) => (
                          <tr
                            key={model.id}
                            onClick={() => {
                              setSelectedModel(model.id);
                            }}
                            onDoubleClick={() => {
                              if (modelToDelete === undefined) {
                                setSelectedModel(model.id);
                                loadModel(model.id, currentWorkflow.getPage());
                                if (currentWorkflow.getPage() === 'CREATION') {
                                  setCurrentWorkflow(Workflow.GRAPH_CREATION);
                                }
                                document.getElementById(CLOSE_MODAL_LOADER_ID)?.click();
                              }
                            }}
                          >
                            <Tooltip title={model.name} disableInteractive>
                              <td className={model.id === selectedModel ? 'selected' : ''}>{model.name}</td>
                            </Tooltip>
                            <Tooltip title={model.description} disableInteractive>
                              <td
                                className={model.id === selectedModel ? 'selected' : ''}
                                style={{
                                  width: '45%',
                                  maxWidth: '125px',
                                }}
                              >
                                {model.description}
                              </td>
                            </Tooltip>
                            <Tooltip title={formatISOTime(model.info?.frontend?.last_modified)} disableInteractive>
                              <td className={model.id === selectedModel ? 'selected' : ''}>
                                {formatISOTime(model.info?.frontend?.last_modified)}
                              </td>
                            </Tooltip>

                            <td className={model.id === selectedModel ? 'selected' : ''}>
                              <Tooltip title={t('model.loaderDialogTableColDelete')}>
                                <button
                                  className='graphView-panel-button'
                                  type='button'
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    setModelToDelete(model);
                                  }}
                                  data-bs-target='#modelDeleteConfirm'
                                  data-bs-toggle='modal'
                                >
                                  <TrashIcon />
                                </button>
                              </Tooltip>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div className='modal-footer'>
              <button type='button' className='abort-btn' data-bs-dismiss='modal' id={CLOSE_MODAL_LOADER_ID}>
                {t('model.dialogAbort')}
              </button>
              <button
                type='submit'
                onClick={() => {
                  if (selectedModel) loadModel(selectedModel, currentWorkflow.getPage());
                  if (currentWorkflow.getPage() === 'CREATION') {
                    setCurrentWorkflow(Workflow.GRAPH_CREATION);
                  }
                }}
                data-bs-dismiss='modal'
                disabled={selectedModel === undefined}
              >
                {t('model.loaderDialogOpen')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const MODEL_RENAMER_ID = 'MODEL_RENAMER';
export const openModelRenameDialog = () => {
  document.getElementById(MODEL_RENAMER_ID)?.click();
};
export const ModelRenameDialog = () => {
  const { t } = useTranslation();
  const { currentWorkflow } = useContext(WorkflowContext);
  const [modelName, setModelName] = useState(
    modelManager.getActiveModel(currentWorkflow.getPage())?.state().name ?? '',
  );

  useEffect(() => {
    const modelRenamerDialog = document.getElementById('modelRenamer');
    const showModelRenamer = () => {
      setModelName(modelManager.getActiveModel(currentWorkflow.getPage())?.state().name ?? '');
    };
    modelRenamerDialog?.addEventListener('show.bs.modal', showModelRenamer);
    return () => {
      modelRenamerDialog?.removeEventListener('show.bs.modal', showModelRenamer);
    };
  }, []);

  return (
    <>
      {/* eslint-disable-next-line */}
      <button
        type='button'
        id={MODEL_RENAMER_ID}
        data-bs-toggle='modal'
        data-bs-target='#modelRenamer'
        style={{ display: 'none' }}
      />
      <div className='modal fade' id='modelRenamer' data-bs-backdrop='static' data-bs-keyboard='true' tabIndex={-1}>
        <div className='modal-dialog'>
          <form
            onSubmit={(event) => {
              event.preventDefault();
              if (modelName === '') return;
              const activeModel = modelManager.getActiveModel(currentWorkflow.getPage());
              const activeGraph = modelManager.getActiveGraph(currentWorkflow.getPage());
              if (activeModel) {
                const activeModelState = activeModel.state();
                activeModelState.name = modelName;
                activeModel.setState(activeModelState);

                const activeGraphState = activeGraph?.state();
                if (activeGraphState) {
                  activeGraphState.name = modelName;
                  activeGraph?.setState(activeGraphState);
                }
              }
            }}
          >
            <div className='modal-content'>
              <div className='modal-header'>
                <h5 className='modal-title'>{t('model.renamerDialogTitle')}</h5>
                <button type='button' className='btn-close' data-bs-dismiss='modal' aria-label='Close' />
              </div>
              <div className='modal-body'>
                <div className='addNodeDialog__attribute-wrapper'>
                  <input
                    className='addNodeDialog__edit-text'
                    type='text'
                    placeholder={t('model.creatorDialogInputPlaceholder')}
                    value={modelName}
                    onChange={(event) => {
                      setModelName(event.currentTarget.value);
                    }}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button type='button' className='abort-btn' data-bs-dismiss='modal'>
                  {t('model.dialogAbort')}
                </button>
                <button type='submit' data-bs-dismiss='modal' disabled={modelName === ''}>
                  {t('model.renamerDialogRename')}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};
